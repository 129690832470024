import { FjdTabBar } from 'fjd-react-components';
import { FjdTabBarTab } from 'fjd-react-components/build/components/TabBar/TabBar';
import { useElementNavigation } from '../../../hooks/useElementNavigation';
import useTabs from '../../../hooks/useTabs';
import './SchreibtischTabbar.css';

export function SchreibtischTabbar() {
  const { navigateToElement } = useElementNavigation();
  const {
    closeAllTabs,
    closeInactiveTabs,
    closeTab,
    setTabs,
    tabs,
    toggleTabPin
  } = useTabs();

  const openElement = (tab: FjdTabBarTab) => {
    if (tab.id === 'empty') {
      return;
    }
    navigateToElement(tab.data.element);
  };

  return (
    <FjdTabBar
      items={tabs.map((tab) => ({ ...tab, onTogglePin: toggleTabPin }))}
      onAllTabsClose={closeAllTabs}
      onInactiveTabsClose={closeInactiveTabs}
      onSort={setTabs}
      onTabClick={openElement}
      onTabClose={(tab) => tab && closeTab(tab.id)}
    />
  );
}
