import {
  Button,
  ExtendedFile,
  FileDropField,
  FileList,
  Spinner,
  Stack
} from '@efast_public/fjd-component-library';
import { ReactElement, useEffect, useState } from 'react';
import useAlerts from '../../../../hooks/useAlerts';
import useLogo, { IUseLogo } from '../../../../hooks/useLogo';
import { ErrorComponentBehoerdenServer } from '../../../shared/Error/ErrorComponent';

export default function LogoSettings(): ReactElement {
  const [droppedFile, setDroppedFile] = useState<File | undefined>(undefined);
  const [isInChangeLogoMode, setIsInChangeLogoMode] = useState<boolean>(false);
  const [showedFile, setShowedFile] = useState<ExtendedFile | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    logoVO,
    getError,
    setLogo,
    isValidating,
    convertingError,
    putError,
    deleteLogo,
    deleteError,
    mutate
  }: IUseLogo = useLogo();

  const { alert } = useAlerts();

  const isLogoUploaded: boolean = logoVO !== undefined;

  useEffect(() => {
    if (isLogoUploaded && !isInChangeLogoMode) {
      const file: ExtendedFile = { name: logoVO!.fileName, size: logoVO!.size };
      setShowedFile(file);
    } else if (droppedFile) {
      const file: ExtendedFile = {
        name: droppedFile.name,
        size: droppedFile.size
      };
      setShowedFile(file);
    } else {
      setShowedFile(undefined);
    }
  }, [logoVO, isInChangeLogoMode, droppedFile, isLogoUploaded, alert]);

  const changeImage = async (files: Array<File>) => {
    if (files.length > 1) {
      alert('error', 'Nur ein einziges Logo ist zulässig.', 10000, true);

      return;
    }

    const file: File = files[0];

    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/svg+xml'
    ) {
      alert(
        'error',
        'Ungültiges Dateiformat. Es sind nur Dateien im Format PNG, JPG oder SVG zulässig.',
        10000,
        true
      );

      return;
    }

    if (file.size > 20000000) {
      alert(
        'error',
        'Es sind nur Bilder bis maximal 20 MB zulässig.',
        10000,
        true
      );

      return;
    }

    setDroppedFile(file);
  };

  const removeItem = () => {
    if (droppedFile) {
      setDroppedFile(undefined);
    } else {
      setIsLoading(true);

      deleteLogo()
        .then(() => {
          mutate()
            .then(() => {
              setDroppedFile(undefined);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
              alert(
                'error',
                'Beim erneuten Laden des Bildes ist ein Fehler aufgetreten',
                10000,
                true
              );
            });
        })
        .catch(() => {
          setIsLoading(false);
          alert(
            'error',
            'Fehler: Bild konnte nicht gelöscht werden',
            10000,
            true
          );
        });
    }
  };

  const PrimaryButton: () => ReactElement | null = () => {
    if (isLoading || isValidating) {
      return <Spinner />;
    } else if (!isLogoUploaded) {
      return (
        <Button
          variant="primary"
          data-testid="logoSettingsPrimaryButtonSaveLogo"
          onClick={() => {
            setIsLoading(true);
            setLogo(droppedFile!)
              .then(() => {
                mutate()
                  .then(() => {
                    setDroppedFile(undefined);
                    setIsLoading(false);
                  })
                  .catch(() => {
                    setIsLoading(false);
                    alert(
                      'error',
                      'Beim erneuten Laden des Bildes ist ein Fehler aufgetreten',
                      10000,
                      true
                    );
                  });
              })
              .catch(() => {
                alert(
                  'error',
                  'Beim Hochladen des Bildes ist ein Fehler aufgetreten',
                  10000,
                  true
                );
                setIsLoading(false);
              });
          }}
          disabled={!droppedFile}
        >
          Logo speichern
        </Button>
      );
    } else if (isLogoUploaded && !isInChangeLogoMode) {
      return (
        <Button
          data-testid="logoSettingsPrimaryButtonChangeLogo"
          variant="primary"
          onClick={() => {
            setIsInChangeLogoMode(true);
          }}
        >
          Logo ändern
        </Button>
      );
    } else if (isInChangeLogoMode) {
      return (
        <Button
          data-testid="logoSettingsPrimaryButtonSaveNewLogo"
          variant="primary"
          onClick={() => {
            setIsLoading(true);

            setLogo(droppedFile!)
              .then(() => {
                mutate()
                  .then(() => {
                    setIsLoading(false);
                    setDroppedFile(undefined);
                    setIsInChangeLogoMode(false);
                  })
                  .catch(() => {
                    setIsLoading(false);
                    alert(
                      'error',
                      'Beim erneuten Laden des Bildes ist ein Fehler aufgetreten',
                      10000,
                      true
                    );
                  });
              })
              .catch(() => {
                setIsLoading(false);
                alert(
                  'error',
                  'Beim Hochladen des Bildes ist ein Fehler aufgetreten',
                  10000,
                  true
                );
              });
          }}
          disabled={isLogoUploaded && !droppedFile}
        >
          Neues Logo speichern
        </Button>
      );
    }

    return null;
  };

  if (getError) {
    return <ErrorComponentBehoerdenServer errorCode={getError.traceId} />;
  }

  return (
    <div style={{ width: '50rem' }}>
      <Stack direction="column" spacing="l">
        <div style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
          Benutzer Laden Sie ein individuelles Logo oder Wappen für Ihren
          Behörden-Client hoch
        </div>
        <div className="descriptionText">
          Sobald Sie eine Datei ausgewählt und gespeichert, haben wird sie im
          Header neben dem Behörden-Client-Logo angezeigt.
          <br /> Sie können das Logo jederzeit wieder entfernen oder ändern.
          Erlaubte Formate: JPG, PNG,SVG.
          <br />
          Empfehlung der Dateigröße: &lt; 1MB
        </div>

        <Stack alignItems="stretch" spacing="xs">
          {!droppedFile && (!isLogoUploaded || isInChangeLogoMode) && (
            <FileDropField
              data-testid={'logoSettingsFileDrop'}
              label="Logo auswählen"
              dropHelperText="oder in dieses Feld ziehen"
              onAdd={(files) => changeImage(files)}
            />
          )}

          {isLogoUploaded && !isInChangeLogoMode && (
            <span data-testid="logoSettingsCurrentlyUsedExtraText">
              Aktuell verwendet:
            </span>
          )}

          {(droppedFile || isLogoUploaded) && showedFile && (
            <FileList data-testid="logoSettingsFileList">
              {convertingError || putError || deleteError ? (
                <FileList.FileItemUpload
                  data-testid="logoSettingsFileItemError"
                  file={{
                    size: showedFile!.size,
                    lastModified: showedFile!.lastModified,
                    name: showedFile!.name,
                    error: deleteError
                      ? 'Fehler: Bild konnte nicht gelöscht werden'
                      : convertingError
                        ? 'Fehler: Ungültiges Bild. Datei konnte nicht verarbeitet werden'
                        : 'Fehler beim Hochladen des Bildes'
                  }}
                  onDelete={() => removeItem()}
                />
              ) : (
                <FileList.FileItem
                  data-testid="logoSettingsFileItem"
                  file={showedFile!}
                  onDelete={() => removeItem()}
                />
              )}
            </FileList>
          )}
        </Stack>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack direction="row" spacing="s">
            {isInChangeLogoMode && (
              <Button
                variant="secondary"
                data-testid="logoSettingsSecondaryButton"
                onClick={() => {
                  setIsInChangeLogoMode(false);
                  setDroppedFile(undefined);
                }}
              >
                Bisheriges Logo behalten
              </Button>
            )}
            <PrimaryButton />
          </Stack>
        </div>
      </Stack>
    </div>
  );
}
