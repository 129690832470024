import IConfigAssistantStep from '../IConfigAssistantStep';
import {
  Alert,
  Button,
  Checkbox,
  FileDropField,
  IconButton,
  Spacer,
  Tooltip
} from '@efast_public/fjd-component-library';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import useDestinations, {
  ClientDestinationsModel,
  Destination,
  OrganizationalUnit,
  UploadedKey,
  isDecryptionKey,
  isSigningKey
} from '../../../../hooks/useDestinations';
import OrgUnitCollapsibleCard from './OrgUnitCollapsibleCard/OrgUnitCollapsibleCard';
import KeyUploadState from './KeyUploadState/KeyUploadState';
import './Destinations.css';
import useAlerts from '../../../../hooks/useAlerts';
import KeyConfirmationModal from './KeyConfirmationModal/KeyConfirmationModal';
import OrgaUnitModal from './OrgUnitComponent/OrgaUnitModal';
import { equals } from '../../../../utils/compareUtil';

interface DestinationsProps extends IConfigAssistantStep {}

interface ConfirmationModel {
  destinationId: string;
  key: UploadedKey;
}

type KeyHandleResult = 'ERROR' | 'SUCCESS' | 'ADDED_TO_CONFIRMATION';

function Destinations(props: DestinationsProps): ReactElement {
  const [uploadForAll, setUploadForAll] = useState<boolean>(false);
  const [orgUnitNameOpen, setOrgUnitNameOpen] = useState<string>('');
  const [toConfirm, setToConfirm] = useState<Array<ConfirmationModel>>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [openOrgaUnitModal, setOpenOrgaUnitModal] = useState<boolean>(false);
  const [updatedOrgaUnitsName, setUpdatedOrgaUnitsName] = useState<
    Map<string, string>
  >(new Map<string, string>());

  const [initialState, setInitialState] = useState<ClientDestinationsModel>();

  const [orgUnits, setOrgUnits] = useState<ClientDestinationsModel>();
  const {
    error,
    destinationsFromFitConnect,
    destinationsDataFromDb,
    postDestination
  } = useDestinations();
  const hasUpdateMode: boolean =
    orgUnits?.organizationalUnitDtos.every((orga) => orga.ouId !== null) ??
    false;
  const { alert } = useAlerts();

  const errorAlert = [
    'error',
    'Ein Private Key konnte nicht zugeordnet werden.',
    10000,
    true
  ] as const;

  const successAlert = [
    'success',
    'Sie haben erfolgreich einen Private Key hinterlegt',
    10000,
    true
  ] as const;

  const { addPrecondition, setButtonLabel } = props;

  useEffect(() => {
    if (initialState) {
      props.setInputChanged(!equals(initialState, orgUnits));
    }
  }, [orgUnits, initialState, props]);

  useEffect(() => {
    if (toConfirm.length > 0) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [toConfirm, setModalOpen]);

  useEffect(() => {
    if (
      destinationsDataFromDb &&
      destinationsDataFromDb.organizationalUnitDtos.length > 0
    ) {
      setOrgUnits(destinationsDataFromDb);
      setOpenOrgaUnitModal(false);
      setInitialState(destinationsDataFromDb);
    } else {
      setOrgUnits(destinationsFromFitConnect);
      setOpenOrgaUnitModal(true);
      setInitialState(destinationsFromFitConnect);
    }
  }, [destinationsFromFitConnect, destinationsDataFromDb]);

  function isNotEmpty(input: string | undefined) {
    return input && input.length > 0;
  }

  useEffect(() => {
    if (updatedOrgaUnitsName.size > 0) {
      orgUnits?.organizationalUnitDtos.forEach((orga) => {
        if (
          updatedOrgaUnitsName.has(orga.name) &&
          isNotEmpty(updatedOrgaUnitsName.get(orga.name))
        ) {
          orga.name = updatedOrgaUnitsName.get(orga.name) ?? '';
        }
      });
      setOpenOrgaUnitModal(false);
      updatedOrgaUnitsName.clear();
    }
  }, [updatedOrgaUnitsName, openOrgaUnitModal, orgUnits]);

  const onConfirmation = () => {
    if (toConfirm.length <= 0) {
      setModalOpen(false);
      return;
    }

    let result: Array<KeyHandleResult> = [];

    toConfirm.forEach((confirmObj: ConfirmationModel) => {
      setOrgUnits((prevState: ClientDestinationsModel | undefined) => {
        if (!prevState) {
          return prevState;
        }
        const newState: ClientDestinationsModel = {
          ...prevState,
          organizationalUnitDtos: prevState.organizationalUnitDtos.map(
            (orgUnit: OrganizationalUnit) => {
              return {
                ...orgUnit,
                destinationDtos: orgUnit.destinationDtos.map(
                  (value: Destination) => {
                    if (value.destinationId !== confirmObj.destinationId) {
                      return value;
                    }

                    if (isDecryptionKey(confirmObj.key)) {
                      result.push('SUCCESS');
                      return { ...value, privateDecryptionKey: confirmObj.key };
                    } else if (isSigningKey(confirmObj.key)) {
                      result.push('SUCCESS');
                      return { ...value, privateSigningKey: confirmObj.key };
                    } else {
                      result.push('ERROR');
                      return value;
                    }
                  }
                )
              };
            }
          )
        };
        return newState;
      });
    });
    setToConfirm([]);
    setModalOpen(false);

    if (result.includes('SUCCESS')) {
      alert(...successAlert);
    } else {
      alert(...errorAlert);
    }
  };

  const onDecline = (): void => {
    setToConfirm([]);
  };

  const handleKey = (
    key: UploadedKey,
    destination: Destination | undefined = undefined
  ): Array<KeyHandleResult> => {
    const result: Array<KeyHandleResult> = [];
    setOrgUnits((prevState: ClientDestinationsModel | undefined) => {
      if (!prevState) {
        return prevState;
      }

      const newState = {
        ...prevState,
        organizationalUnitDtos: prevState.organizationalUnitDtos.map(
          (orgUnit) => {
            return {
              ...orgUnit,
              destinationDtos: orgUnit.destinationDtos.map((value) => {
                if (
                  destination &&
                  destination.destinationId !== value.destinationId
                ) {
                  return value;
                }

                if (isDecryptionKey(key)) {
                  if (value.encryptionKid !== key.kid) {
                    result.push('ERROR');
                    return value;
                  }
                  if (value.privateDecryptionKey !== null) {
                    setToConfirm((toConfirmValues) => {
                      return [
                        ...toConfirmValues,
                        { key: key, destinationId: value.destinationId }
                      ];
                    });
                    result.push('ADDED_TO_CONFIRMATION');
                    return value;
                  } else {
                    result.push('SUCCESS');
                    return { ...value, privateDecryptionKey: key };
                  }
                } else if (isSigningKey(key)) {
                  if (value.privateSigningKey !== null) {
                    setToConfirm((toConfirmValues) => {
                      return [
                        ...toConfirmValues,
                        { key: key, destinationId: value.destinationId }
                      ];
                    });
                    result.push('ADDED_TO_CONFIRMATION');
                    return value;
                  } else {
                    result.push('SUCCESS');
                    return { ...value, privateSigningKey: key };
                  }
                } else {
                  alert(...errorAlert);
                  return value;
                }
              })
            };
          }
        )
      };
      return newState;
    });

    return result;
  };

  function handleKeyUpload(
    files: Array<File>,
    destination: Destination | undefined = undefined
  ) {
    files.map((file: File) => readFileOnUpload(file, destination));
  }

  const readFileOnUpload = (
    uploadedFile: File,
    destination: Destination | undefined = undefined
  ) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      try {
        let key: UploadedKey | undefined = undefined;

        if (typeof fileReader.result === 'string') {
          key = JSON.parse(fileReader.result);

          if (!key) {
            return;
          }
          const resultHandleKey: Array<KeyHandleResult> = handleKey(
            key,
            destination
          );

          if (resultHandleKey === undefined) {
            return;
          }

          if (resultHandleKey.includes('SUCCESS')) {
            alert(...successAlert);
          } else if (resultHandleKey.includes('ERROR')) {
            alert(...errorAlert);
          }
        }
      } catch {
        alert(...errorAlert);
      }
    };

    if (uploadedFile !== undefined) {
      fileReader.readAsText(uploadedFile);
    } else {
      alert(...errorAlert);
    }
  };

  let destinationsSameKey: boolean = false;

  if (orgUnits && orgUnits.organizationalUnitDtos) {
    if (
      orgUnits.organizationalUnitDtos.length > 0 &&
      orgUnits.organizationalUnitDtos[0].destinationDtos.length > 0
    ) {
      const keyId: string =
        orgUnits.organizationalUnitDtos[0].destinationDtos[0].encryptionKid;
      destinationsSameKey = true;
      orgUnits.organizationalUnitDtos.forEach((value) => {
        value.destinationDtos.forEach((orgUnits) => {
          destinationsSameKey =
            destinationsSameKey && keyId === orgUnits.encryptionKid;
        });
      });
    }
  }
  const allDecryptionKeysAvailable =
    orgUnits && orgUnits.organizationalUnitDtos
      ? orgUnits?.organizationalUnitDtos
          .map((orgUnit) => orgUnit.destinationDtos[0])
          .filter((destination) => destination.privateDecryptionKey === null)
          .length === 0
      : false;

  const allSigningKeysAvailable =
    orgUnits && orgUnits.organizationalUnitDtos
      ? orgUnits?.organizationalUnitDtos
          .map((orgUnit) => orgUnit.destinationDtos[0])
          .filter((destination) => destination.privateSigningKey === null)
          .length === 0
      : false;

  useEffect(() => {
    props.nextStepAvailable(
      (allDecryptionKeysAvailable && allSigningKeysAvailable) || hasUpdateMode
    );
  }, [
    orgUnits,
    allDecryptionKeysAvailable,
    allSigningKeysAvailable,
    props,
    hasUpdateMode
  ]);

  useEffect(() => {
    if (error) {
      setButtonLabel('Erneut versuchen');
      props.setTryAgainButton(true);
    } else {
      setButtonLabel('Weiter');
    }
  }, [error, props, setButtonLabel]);

  const getPrecondition = useCallback((): Promise<any> => {
    const canNotBeUpdated: boolean =
      orgUnits?.organizationalUnitDtos.some(
        (orga) =>
          orga.ouId == null &&
          orga.destinationDtos.some((d) => d.privateDecryptionKey == null)
      ) ?? false;
    if (canNotBeUpdated) {
      // TODO: HIER MUESSTE REJECTED WERDEN
      return new Promise<void>((resolve) => {
        resolve();
      });
    } else {
      return postDestination(orgUnits);
    }
  }, [orgUnits, postDestination]);

  useEffect(() => {
    addPrecondition({
      condition: getPrecondition
    });
  }, [getPrecondition, addPrecondition]);

  if (error) {
    return (
      <div>
        <Alert
          variant={'error'}
          message={
            'FIT-Connect ist momentan nicht erreichbar, daher können die Zustellpunkte nicht abgerufen werden. Bitte versuchen Sie es später erneut.'
          }
        />
      </div>
    );
  } else {
    return (
      <div>
        <OrgaUnitModal
          orgaUnitsFromFitConnect={destinationsFromFitConnect}
          orgaUnits={orgUnits}
          setUpdatedOrgaUnitsName={setUpdatedOrgaUnitsName}
          isOpenModal={openOrgaUnitModal}
          setOpenModal={setOpenOrgaUnitModal}
        />
        <div className="contentHeading">
          {orgUnits?.organizationalUnitDtos.length} Zustellpunkte aus
          FIT-Connect importiert (Schritt 3 von 7)
          <Tooltip tooltip="Die korrekten Verwaltungsleistungen pro Zustellpunkt müssen bei FIT-Connect eingestellt werden.">
            <IconButton
              icon="information-filled"
              label="More information"
              variant="tertiary"
            />
          </Tooltip>
        </div>
        <div className="descriptionText">
          Laden Sie Ihre Private Keys für jeden Zustellpunkt hoch:
        </div>
        <Spacer size="m" />
        {destinationsSameKey && (
          <div className="checkboxContainer">
            <Checkbox
              data-testid="checkBoxUploadAll"
              checked={uploadForAll}
              onChange={(event) => {
                setUploadForAll(event.currentTarget.checked);
              }}
              id={'singleKeySet'}
              label="Ein Set Private Keys für alle Zustellpunkte verwenden (optional)"
            />
          </div>
        )}
        {uploadForAll && (
          <div>
            <Spacer size="s" />
            <KeyUploadState
              hasUpdateMode={hasUpdateMode}
              hasDecryptionKey={allDecryptionKeysAvailable}
              hasSigningKey={allSigningKeysAvailable}
              additionalKey="All"
            />
            <Spacer size="s" />
            <FileDropField
              className="uploadField"
              data-testid="uploadFieldAll"
              label="Neue Dateien auswählen"
              dropHelperText={'oder in dieses Feld ziehen'}
              onAdd={(files) => {
                handleKeyUpload(files);
              }}
            />
          </div>
        )}

        {orgUnits && orgUnits.organizationalUnitDtos
          ? orgUnits.organizationalUnitDtos.map((orgUnit, index) => {
              const nextOrgUnit: OrganizationalUnit | undefined =
                orgUnits.organizationalUnitDtos.length >= index + 1
                  ? orgUnits.organizationalUnitDtos[index + 1]
                  : undefined;

              return (
                <OrgUnitCollapsibleCard
                  labelNextCard={nextOrgUnit?.name}
                  isExpanded={orgUnitNameOpen === orgUnit.name}
                  key={orgUnit.name}
                  orgUnit={orgUnit}
                  isDisabled={uploadForAll}
                  openNextOrgUnit={() => {
                    setOrgUnitNameOpen(nextOrgUnit?.name ?? '');
                  }}
                  onAdd={(files: Array<File>, destination: Destination) => {
                    handleKeyUpload(files, destination);
                  }}
                />
              );
            })
          : null}
        {hasUpdateMode ? (
          <div className={'editBtn'}>
            <Button
              variant="tertiary"
              id={'zustellpunkte_bearbeiten_btn'}
              onClick={() => setOpenOrgaUnitModal(true)}
              beforeIcon={'edit'}
            >
              Bezeichnungen der Zustellpunkte bearbeiten
            </Button>
          </div>
        ) : null}

        <KeyConfirmationModal
          onConfirmation={onConfirmation}
          onClosed={onDecline}
          isOpen={modalOpen}
        />
      </div>
    );
  }
}

export default Destinations;
