import { useState } from 'react';
import DatamodelUpload from '../../../ConfigAssistant/ConfigAssistantSteps/DataModel/DataModelUpload';
import useDataModel from '../../../../hooks/useDataModel';
import { DataModelDto } from '../../../ConfigAssistant/ConfigAssistantSteps/DataModel/DataModelDto';

export default function DatamodelSettings() {
  const [open, setOpen] = useState<boolean>(false);
  const { orgUnitDataModelDtos } = useDataModel();

  return (
    <DatamodelUpload
      isOpen={open}
      setOpenModal={setOpen}
      datamodels={
        orgUnitDataModelDtos
          ? orgUnitDataModelDtos.flatMap((datamodel) =>
              datamodel.dataModelDtos.map((data: DataModelDto) => {
                return {
                  identifier: data.identifier,
                  isAvailable: true
                };
              })
            )
          : []
      }
      isComponent
    />
  );
}
