import { useCallback, useEffect } from 'react';

import { Pagination } from '../pages/shared/Pagination';
import { useStorageState } from 'react-storage-hooks';
import { useLocalStorageTable } from './useLocalStorageTable';

interface UsePaginationConfig {
  totalItems: number;
  paginationStyle: PaginationStyle;
  initPageSize?: number;
  queryString: string;
}

export type PaginationStyle = 'default' | 'simple';

export function usePagination({
  totalItems,
  paginationStyle = 'default',
  initPageSize = 25,
  queryString
}: UsePaginationConfig) {
  const { currentPageKey, pageSizeKey } = useLocalStorageTable();

  const [currentPage, setCurrentPage] = useStorageState(
    localStorage,
    currentPageKey,
    0
  );

  const [pageSize, setPageSize] = useStorageState(
    localStorage,
    pageSizeKey,
    initPageSize
  );

  const pages = totalItems / pageSize;
  const apiQueryString = `page=${currentPage}&size=${pageSize}`;
  const nextPageEnabled = currentPage + 1 < pages;
  const prevPageEnabled = currentPage - 1 >= 0;

  const setPage = useCallback(
    (page: number) => {
      if (page < pages && page >= 0) {
        setCurrentPage(page);
      }
    },
    [pages, setCurrentPage]
  );

  const nextPage = () => setPage(currentPage + 1);

  const prevPage = () => setPage(currentPage - 1);

  useEffect(() => {
    const storagePage =
      localStorage.getItem(currentPageKey) !== null
        ? Number(localStorage.getItem(currentPageKey))
        : 0;
    setPage(storagePage);
  }, [queryString, currentPageKey, setPage]);

  const pagination = {
    apiQueryString,
    currentPage,
    nextPageEnabled,
    pages,
    pageSize,
    prevPageEnabled,
    nextPage,
    prevPage,
    totalItems,
    setPage,
    setPageSize
  };

  const PaginationComponent =
    pagination.pages > 0 ? (
      <Pagination pagination={pagination} paginationStyle={paginationStyle} />
    ) : null;

  return {
    ...pagination,
    PaginationComponent
  };
}
