import { FjdStack, FjdTextInput } from 'fjd-react-components';
import IConfigAssistantStep from '../IConfigAssistantStep';
import { Alert, Button } from '@efast_public/fjd-component-library';
import './FitConnect.css';
import { ReactElement, useEffect, useState } from 'react';
import useFitconnectClientInformation, {
  ClientInformationDto
} from '../../../../hooks/useFitconnectClientInformation';
import { equals } from '../../../../utils/compareUtil';

interface FitConnectProps extends IConfigAssistantStep {}

function FitConnect(props: FitConnectProps): ReactElement {
  const { updateFitConnectClientInformation, clientInformationDto } =
    useFitconnectClientInformation();
  const [clientId, setClientId] = useState<string>('');
  const [clientSecret, setClientSecret] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [initialState, setInitialState] = useState<
    ClientInformationDto | undefined
  >({
    clientId: '',
    clientSecret: ''
  });

  useEffect(() => {
    if (clientInformationDto && clientId === '' && clientSecret === '') {
      setClientId(clientInformationDto.clientId);
      setClientSecret(clientInformationDto.clientSecret);
      setInitialState(clientInformationDto);
    }
  }, [clientInformationDto, clientId, clientSecret]);

  useEffect(() => {
    props.setButtonLabel('Zustellpunkte einrichten');
  }, [props]);

  useEffect(() => {
    if (initialState) {
      props.setInputChanged(
        !equals(initialState, {
          ...initialState,
          clientId: clientId,
          clientSecret: clientSecret
        })
      );
    }
  }, [clientId, clientSecret, initialState, props]);

  return (
    <div>
      <div className="contentHeading">
        FIT-Connect verbinden (Schritt 2 von 7)
      </div>
      <div className="descriptionText">
        Bitte geben Sie Ihre Zugangsdaten ein:
      </div>
      <FjdStack className="input" orientation="vertical">
        <FjdStack orientation="vertical" spacing="xs">
          <div>Client-ID</div>
          <FjdTextInput
            id="clientId"
            label="Client-ID"
            type="text"
            placeholder={'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'}
            value={clientId}
            onChange={(event) => {
              event.preventDefault();
              setClientId(event.target.value);
            }}
            disabled={success}
          />
        </FjdStack>
        <FjdStack orientation="vertical" spacing="xs">
          <div>Client-Secret</div>
          <FjdTextInput
            label="Client-Secret"
            id="clientSecret"
            type="text"
            placeholder={'XXXXXXXXXXXXXX-XXXXXXXXXXXXXXXXXXXXXXXX-XXX'}
            value={clientSecret}
            onChange={(event) => setClientSecret(event.target.value)}
            disabled={success}
          />
        </FjdStack>
        <FjdStack orientation="vertical" spacing="xs">
          {error && (
            <Alert
              variant={'error'}
              message={
                'FIT-Connect konnte nicht verbunden werden. Bitte überprüfen Sie Ihre Eingaben oder versuchen Sie es später erneut.'
              }
            />
          )}
          {success && (
            <Alert
              variant={'success'}
              message={'FIT-Connect erfolgreich verbunden.'}
            />
          )}
        </FjdStack>

        {!success && (
          <Button
            id={'fitconnectverbinden'}
            variant="primary"
            disabled={clientId === '' || clientSecret === ''}
            onClick={() => {
              updateFitConnectClientInformation({
                clientId: clientId,
                clientSecret: clientSecret
              })
                .then(() => {
                  props.addProgress(18);
                  setSuccess(true);
                  setError(false);
                  props.nextStepAvailable(true);
                })
                .catch(() => {
                  setError(true);
                  setSuccess(false);
                  props.nextStepAvailable(false);
                });
            }}
          >
            FIT-Connect verbinden
          </Button>
        )}
      </FjdStack>
    </div>
  );
}

export default FitConnect;
