import useSWR from 'swr';
import { CaseCountModel } from '../models/Case/CaseDetailsModel';

export function useCaseCount() {
  const {
    data: caseCountData,
    error,
    mutate
  } = useSWR<CaseCountModel>(['/api/v1/cases/count'], {
    suspense: false
  });

  const caseCount = caseCountData?.count;

  return { caseCount, error, mutate };
}
