import {
  FjdContentTabBar,
  FjdContentTabBarItem,
  FjdStack
} from 'fjd-react-components';
import { useState } from 'react';
import Notifications from './Notifications/Notifications';
import UsersAndRoles from './UsersAndRoles/UsersAndRoles';
import FooterlinksSettings from './Footerlinks/FooterlinksSettings';
import LogoSettings from './Logo/LogoSettings';
import DatamodelSettings from './Datamodels/DatamodelsSettings';
import BundIdSettings from './BundId/BundId';

interface SettingsTab {
  tabLabel: string;
  tabContent: JSX.Element;
}

const tabsContent: Array<SettingsTab> = [
  {
    tabLabel: 'Benachrichtigungen',
    tabContent: <Notifications />
  },
  {
    tabLabel: 'Benutzende und Rollen',
    tabContent: <UsersAndRoles />
  },
  {
    tabLabel: 'Datenmodelle',
    tabContent: <DatamodelSettings />
  },
  {
    tabLabel: 'BundID-Postfach',
    tabContent: <BundIdSettings />
  },
  {
    tabLabel: 'Logo / Wappen',
    tabContent: <LogoSettings />
  },
  {
    tabLabel: 'Footerlinks',
    tabContent: <FooterlinksSettings />
  }
];

export default function SettingsTabs() {
  const [content, setContent] = useState<SettingsTab>(tabsContent[0]);

  return (
    <FjdStack spacing="xl">
      <FjdContentTabBar>
        {tabsContent.map((tab: SettingsTab) => {
          return (
            <FjdContentTabBarItem
              key={tab.tabLabel.replace(' ', '_').toLowerCase()}
              active={content.tabLabel === tab.tabLabel}
              label={tab.tabLabel}
              onClick={() => setContent(tab)}
            />
          );
        })}
      </FjdContentTabBar>
      {content.tabContent}
    </FjdStack>
  );
}
