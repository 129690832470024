import useSWR from 'swr';
import { BearbeiterDto } from '../models/Antrag/AntragsModel';

interface UseKeycloakUserConfig {
  orgUnitName: Array<string>;
}

export function useKeycloakUser({ orgUnitName }: UseKeycloakUserConfig) {
  const {
    data: users,
    error,
    isValidating,
    mutate
  } = useSWR<Array<BearbeiterDto>>(`/api/v1/users?orgUnitName=${orgUnitName}`, {
    suspense: false
  });

  return { users, isValidating, error, mutate };
}
