import { FjdAlert } from 'fjd-react-components';
import { ConfirmAction } from '../../../../models/UploadFiles/ConfirmAction';

interface UploadFilesConfirmationAlertProps {
  readonly confirmAction: ConfirmAction;
}

export default function UploadFilesConfirmationAlert({
  confirmAction
}: UploadFilesConfirmationAlertProps) {
  return (
    <FjdAlert
      testId="confirmationAlert"
      appearance="muted"
      intent="warning"
      closable={false}
      title={confirmAction.message}
    ></FjdAlert>
  );
}
