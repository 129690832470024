import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { RouteKey, routes } from './utils/router';
import { AppContainer } from './pages/AppContainer';
import { MutableSnapshot, RecoilRoot } from 'recoil';
import GuardedRoute from './utils/Router/GuardedRoute';
import { useCallback } from 'react';
import { tabsAtom, TabsState, tabsStateLocalStorageKey } from './hooks/useTabs';
import { useStorageState } from 'react-storage-hooks';
import ScrollToTop from './utils/ScrollToTop';
import { BehErrorBoundary } from './pages/shared/BehErrorBoundary';
import './global.css';
import { getMandant } from './utils/mandant';

function App() {
  const { initialized } = useKeycloak();

  const [tabsState] = useStorageState<TabsState>(
    localStorage,
    tabsStateLocalStorageKey
  );
  const initializeRecoilState = useCallback<
    (mutableSnapshot: MutableSnapshot) => void
  >(
    ({ set }) => {
      if (tabsState) {
        set(tabsAtom, tabsState);
      }
    },
    [tabsState]
  );

  return initialized ? (
    <RecoilRoot initializeState={initializeRecoilState}>
      <BrowserRouter basename={getMandant()}>
        <ScrollToTop />
        <AppContainer>
          <BehErrorBoundary>
            <Routes>
              <Route
                element={
                  <GuardedRoute
                    element={routes[RouteKey.HOME].element}
                    path={routes[RouteKey.HOME].path}
                  />
                }
                path={routes[RouteKey.HOME].path}
              >
                <Route
                  element={routes[RouteKey.HOME].element}
                  path={routes[RouteKey.HOME].path}
                />
              </Route>
              <Route
                element={
                  <GuardedRoute
                    element={routes[RouteKey.ANTRAEGE].element}
                    path={routes[RouteKey.ANTRAEGE].path}
                  />
                }
                path={routes[RouteKey.ANTRAEGE].path}
              >
                <Route
                  element={routes[RouteKey.ANTRAEGE].element}
                  path={routes[RouteKey.ANTRAEGE].path}
                />
              </Route>
              <Route
                element={
                  <GuardedRoute
                    element={routes[RouteKey.SCHREIBTISCH].element}
                    path={routes[RouteKey.SCHREIBTISCH].path}
                  />
                }
                path={routes[RouteKey.SCHREIBTISCH].path}
              >
                <Route
                  element={routes[RouteKey.SCHREIBTISCH].element}
                  path={routes[RouteKey.SCHREIBTISCH].path}
                />
              </Route>
              <Route
                element={
                  <GuardedRoute
                    element={routes[RouteKey.SCHREIBTISCH_CASE].element}
                    path={routes[RouteKey.SCHREIBTISCH_CASE].path}
                  />
                }
                path={routes[RouteKey.SCHREIBTISCH_CASE].path}
              >
                <Route
                  element={routes[RouteKey.SCHREIBTISCH_CASE].element}
                  path={routes[RouteKey.SCHREIBTISCH_CASE].path}
                />
              </Route>
              <Route
                element={
                  <GuardedRoute
                    element={routes[RouteKey.EINSTELLUNGEN].element}
                    path={routes[RouteKey.EINSTELLUNGEN].path}
                  />
                }
                path={routes[RouteKey.EINSTELLUNGEN].path}
              >
                <Route
                  element={routes[RouteKey.EINSTELLUNGEN].element}
                  path={routes[RouteKey.EINSTELLUNGEN].path}
                />
              </Route>
              <Route
                element={
                  <GuardedRoute
                    element={routes[RouteKey.CONFIGASSISTANT].element}
                    path={routes[RouteKey.CONFIGASSISTANT].path}
                  />
                }
                path={routes[RouteKey.CONFIGASSISTANT].path}
              >
                <Route
                  element={routes[RouteKey.CONFIGASSISTANT].element}
                  path={routes[RouteKey.CONFIGASSISTANT].path}
                />
              </Route>
            </Routes>
          </BehErrorBoundary>
        </AppContainer>
      </BrowserRouter>
    </RecoilRoot>
  ) : null;
}

export default App;
