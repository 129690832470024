export enum Bearbeitungsstand {
  OFFEN = 'OFFEN',
  INBEARBEITUNG = 'INBEARBEITUNG',
  ABGESCHLOSSEN = 'ABGESCHLOSSEN',
  UNKNOWN = 'UNKNOWN'
}

export function bearbeitungsstandToString(value: Bearbeitungsstand) {
  switch (value) {
    case Bearbeitungsstand.OFFEN:
      return 'offen';
    case Bearbeitungsstand.INBEARBEITUNG:
      return 'in Bearbeitung';
    case Bearbeitungsstand.ABGESCHLOSSEN:
      return 'abgeschlossen';
    case Bearbeitungsstand.UNKNOWN:
      return 'Unbekannt';
    default:
      return 'Unbekannt';
  }
}
