import useSWR from 'swr';
import { useApi } from './useApi';
import { useCallback } from 'react';
import { AxiosResponse } from 'axios';

export interface ClientInformationDto {
  clientId: string;
  clientSecret: string;
}

export default function useFitconnectClientInformation() {
  const { makeRequest } = useApi();

  const url: string = `/api/v1/configuration/fitConnectClientInformation`;
  const {
    data: clientInformationDto,
    error,
    mutate
  } = useSWR<ClientInformationDto>(url, {
    suspense: false
  });

  const updateFitConnectClientInformation = useCallback(
    async (clientInformationDto: ClientInformationDto) => {
      return makeRequest<AxiosResponse>(
        `/api/v1/configuration/fitConnectClientInformation`,
        'PUT',
        undefined,
        undefined,
        undefined,
        clientInformationDto
      );
    },
    [makeRequest]
  );

  return {
    updateFitConnectClientInformation,
    error,
    mutate,
    clientInformationDto
  };
}
