import useSWR from 'swr';
import { OrgUnitDataModelDto } from '../pages/ConfigAssistant/ConfigAssistantSteps/DataModel/DataModelDto';
import { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useApi } from './useApi';

export interface ImportDatamodelDto {
  identifier: string;
  importModel: string;
  mimeType: string;
  hasIdentifierInFile: boolean;
}

export default function useDataModel() {
  const { makeRequest } = useApi();
  const [putError, setPutError] = useState<boolean>(false);

  const url: string = `/api/v1/configuration/datamodels`;

  const putDatamodels = useCallback(
    async (importModel: Array<ImportDatamodelDto>) => {
      try {
        return makeRequest<AxiosResponse>(
          url,
          'PUT',
          undefined,
          undefined,
          undefined,
          importModel
        );
      } catch (error) {
        setPutError(true);
      }
    },
    [makeRequest, url]
  );

  const {
    data: orgUnitDataModelDtos,
    error,
    mutate
  } = useSWR<Array<OrgUnitDataModelDto>>(url, {
    suspense: false
  });

  return { orgUnitDataModelDtos, error, mutate, putDatamodels, putError };
}
