import { createPath } from 'history';
import { MouseEventHandler, ReactElement } from 'react';
import {
  LinkProps,
  useHref,
  useLocation,
  useNavigate,
  useResolvedPath
} from 'react-router-dom';

function isModifiedEvent(event: React.MouseEvent) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

interface RouterLinkProps extends LinkProps {
  component: (props: {
    href: string;
    onClick: MouseEventHandler<HTMLAnchorElement>;
  }) => ReactElement;
}

export function RouterLink({
  component,
  replace: replaceProp = false,
  state,
  target,
  to
}: RouterLinkProps) {
  const href = useHref(to);
  const location = useLocation();
  const navigate = useNavigate();
  const path = useResolvedPath(to);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (
      !event.defaultPrevented &&
      event.button === 0 &&
      (!target || target === '_self') &&
      !isModifiedEvent(event)
    ) {
      event.preventDefault();

      const replace =
        !!replaceProp || createPath(location) === createPath(path);

      navigate(to, { replace, state });
    }
  };

  return component({
    href,
    onClick: handleClick
  });
}
