import { caseDetailsDataContext } from '../Context';
import { ReactNode, useEffect, useState } from 'react';
import {
  CaseDetailsModel,
  SubmissionDetailsDto
} from '../../models/Case/CaseDetailsModel';
import { Bescheidstatus } from '../../models/Status/Bescheidstatus';
import { Bearbeitungsstand } from '../../models/Status/Bearbeitungsstand';
import { useCaseDetails } from '../../hooks/useCaseDetails';
import { BEHError } from '../../utils/Error/ErrorCode';
import { getCaseDetailsErrorData } from '../../utils/Error/CaseDetailsErrorDataProvider';
import { VermerkDto } from '../../models/Vermerk/VermerkDto';

interface PropsChildren {
  readonly children: ReactNode | Array<ReactNode>;
}

export const initialCaseDetailsData: CaseDetailsModel = {
  caseId: '',
  name: '',
  auftragsnummer: '',
  abgeschlossenAm: null,
  initialSubmission: {} as SubmissionDetailsDto,
  bescheidstatus: {} as Bescheidstatus,
  bearbeitungsstand: {} as Bearbeitungsstand,
  version: '',
  createdOn: new Date(),
  vermerkDto: [] as Array<VermerkDto>,
  hasError: false,
  canCommunicate: false,
  isBeschieden: false,
  isPostalisch: false,
  bearbeiterDto: null,
  organizationalUnitName: ''
};

const CaseDetailsDataProvider = ({ children }: PropsChildren) => {
  const { data, error, paramCaseId, refreshCaseDetailsData, isValidating } =
    useCaseDetails();

  const [caseDetailsData, setCaseDetailsData] = useState<CaseDetailsModel>(
    initialCaseDetailsData
  );

  useEffect(() => {
    if (paramCaseId !== undefined) {
      const fetchData = async () => {
        if (error) {
          const caseDetailsError = error as BEHError;
          const caseDetailsErrorData = getCaseDetailsErrorData(
            paramCaseId,
            caseDetailsError?.traceId
          );
          setCaseDetailsData(caseDetailsErrorData);
        } else if (data) {
          const caseDetails = { ...data };
          setCaseDetailsData(caseDetails);
        }
      };
      fetchData();
    }
  }, [data, paramCaseId, error, refreshCaseDetailsData]);

  const { Provider } = caseDetailsDataContext;
  const value = {
    data: caseDetailsData,
    refresh: refreshCaseDetailsData,
    isValidating: isValidating
  };
  return <Provider value={value}>{children}</Provider>;
};
export default CaseDetailsDataProvider;
