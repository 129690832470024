import classes from '../../../Schreibtisch.module.css';
import { useState } from 'react';
import { FjdCollapsibleCard, FjdStack } from 'fjd-react-components';

const Ausweisdaten = () => {
  const [openCard, setOpenCard] = useState(false);

  return (
    <FjdStack orientation="vertical">
      <FjdCollapsibleCard
        iconExpanded="chevron-up"
        iconCollapsed="chevron-down"
        headingColor="background-alternate"
        onToggle={(expanded) => setOpenCard(expanded)}
        expanded={openCard}
        heading={
          <FjdStack orientation="horizontal">
            <h2 style={{ marginTop: '3px', marginLeft: '10px' }}>
              Ausweisdaten
            </h2>
            <h2
              style={{
                marginLeft: '-5px',
                marginTop: '3px',
                fontWeight: 'normal'
              }}
            >
              (liegen nicht vor)
            </h2>
          </FjdStack>
        }
      >
        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
          <div id={'ausweisdaten'} className={classes.collapsibleCardBody}>
            <dt id={'fullname_label'}>Vorname Nachname</dt>
            <dd id={'fullname_value'} className={classes.data}>
              - Keine Angabe -
            </dd>
            <dt id={'strasse_label'}>Straße, Haus-Nr.</dt>
            <dd id={'strasse_value'} className={classes.data}>
              - Keine Angabe -
            </dd>
            <dt id={'plz_ort_label'}>PLZ, Ort</dt>
            <dd id={'plz_ort_value'} className={classes.data}>
              - Keine Angabe -
            </dd>
            <dt id={'geburtsdatum_label'}>Geburtsdatum</dt>
            <dd id={'geburtsdatum_value'} className={classes.data}>
              - Keine Angabe -
            </dd>
            <dt id={'geburtsort_label'}>Geburtsort</dt>
            <dd id={'geburtsort_value'} className={classes.data}>
              - Keine Angabe -
            </dd>
            <dt id={'ausweisnummer_label'}>Ausweisnummer</dt>
            <dd id={'ausweisnummer_value'} className={classes.data}>
              - Keine Angabe -
            </dd>
            <dt id={'behoerde_label'}>Ausstellende Behörde</dt>
            <dd id={'behoerde_value'} className={classes.data}>
              - Keine Angabe -
            </dd>
            <dt id={'ausstellungsdatum_label'}>Ausstellungsdatum</dt>
            <dd id={'ausstellungsdatum_value'} className={classes.data}>
              - Keine Angabe -
            </dd>
            <dt id={'gueltigkeitsdatum_label'}>Gültigkeitsdatum</dt>
            <dd id={'gueltigkeitsdatum_value'} className={classes.data}>
              - Keine Angabe -
            </dd>
          </div>
        </div>
      </FjdCollapsibleCard>
    </FjdStack>
  );
};
export default Ausweisdaten;
