export function fileToString(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target && event.target.result) {
        resolve(event.target.result as string);
      } else {
        reject(new Error('FileReader onload event triggered without result'));
      }
    };

    reader.onerror = (error) => {
      reject(new Error(`FileReader encountered an error: ${error}`));
    };

    reader.readAsText(file);
  });
}

/**
 * Converts the contents of a given `File` object into a text string.
 *
 * @param {File} [file] - The file to convert. Can be `undefined`.
 * @returns {Promise<string | undefined>} A promise that resolves to the text content of the file,
 * or `undefined` if the file is not provided or empty.
 */
export const convertBlobToTextString = async (
  file?: File
): Promise<string | undefined> => {
  if (!file) {
    console.warn('No file provided');
    return undefined;
  }

  if (file.size === 0) {
    console.warn('File is empty');
    return undefined;
  }

  return await file.text();
};
