import { FjdTextInput, FjdModal } from 'fjd-react-components';
import { useEffect, useState } from 'react';
import { VermerkDto } from '../../../../../models/Vermerk/VermerkDto';
import { useProfile } from '../../../../../hooks/useProfile';

interface VermerkModalModalProps {
  readonly onClose: () => void;
  readonly onSave: (vermerk: VermerkDto) => Promise<void>;
  readonly onStateChanged: () => void;
  readonly disableInput: boolean;
  readonly vermerk?: VermerkDto;
  readonly isOpen: boolean;
}

export function VermerkModal({
  onClose,
  onSave,
  onStateChanged,
  disableInput,
  vermerk,
  isOpen
}: VermerkModalModalProps) {
  const [text, setText] = useState('');

  const { profile } = useProfile();
  const bearbeiterId = profile?.id !== undefined ? profile?.id : '';
  const firstName = profile?.firstName !== undefined ? profile?.firstName : '';
  const lastName = profile?.lastName !== undefined ? profile?.lastName : '';

  useEffect(() => {
    if (vermerk !== undefined && vermerk !== null) {
      setText(vermerk.description);
    } else {
      setText('');
    }
  }, [vermerk, isOpen]);

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={'Vermerk'}
      id="vermerk-modal"
      onClose={() => {
        onClose();
      }}
      onPrimaryButtonClick={() =>
        onSave({
          description: text,
          vermerkId: vermerk ? vermerk.vermerkId : undefined,
          version: vermerk?.version,
          bearbeiterDto: {
            id: bearbeiterId,
            firstName: firstName,
            lastName: lastName,
            fullName: `${firstName} ${lastName}`
          }
        }).finally(() => {
          onClose();
          onStateChanged();
        })
      }
      onSecondaryButtonClick={() => {
        onClose();
      }}
      open={isOpen}
      primaryButtonDisabled={disableInput || !text}
      primaryButtonLabel={'Vermerk speichern'}
      secondaryButtonLabel={'Abbrechen'}
      secondaryButtonDisabled={disableInput}
    >
      <FjdTextInput
        id="vermerkText"
        type="text"
        minLength={1}
        maxLength={500}
        onChange={(event) => setText(event.target.value)}
        value={text}
        rows={6}
        placeholder="Hier können Sie individuelle Notizen vermerken."
        disabled={disableInput}
      />
    </FjdModal>
  );
}

export default VermerkModal;
