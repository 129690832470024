import { FachdatenNode } from '../../../../../models/Case/CaseDetailsModel';
import { FjdSection, FjdStack } from 'fjd-react-components';
import FachdatenLabelValue from './FachdatenLabelValue';
import styles from './Fachdaten.module.css';

interface FachdatenNodeProps {
  readonly node: FachdatenNode;
  readonly hasSectionSeperator?: boolean;
  readonly depthLevel: number;
  readonly count?: number;
}

export function FachdatenNodeComponent({
  node,
  depthLevel,
  hasSectionSeperator
}: FachdatenNodeProps) {
  return node.isGroup() ? (
    <FjdSection key={Math.random()} showSeparator={hasSectionSeperator}>
      {!node.isRootNode() && <div style={{ marginTop: '32px' }}> </div>}
      <div
        style={{
          paddingLeft: `${(depthLevel - 1) * 16}px`
        }}
      >
        <FjdStack
          spacing={'s'}
          key={Math.random()}
          id={
            node.isRootNode() ? '' : `section_header_antragsdaten_${depthLevel}`
          }
        >
          <div className={styles.headerStyle} key={Math.random()}>
            {node.getLabel()}
          </div>
          {node.children.map((value) => (
            <FachdatenNodeComponent
              node={value}
              depthLevel={depthLevel + 1}
              hasSectionSeperator={node.isRootNode()}
              key={Math.random()}
            ></FachdatenNodeComponent>
          ))}
        </FjdStack>
      </div>
    </FjdSection>
  ) : (
    <FachdatenLabelValue
      labelValue={node.labelValue}
      showInSection={hasSectionSeperator}
      key={Math.random()}
      id={`labelValue_antragsdaten_${depthLevel}`}
    />
  );
}

export default FachdatenNodeComponent;
