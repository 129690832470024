import saveAs from 'file-saver';
import { useState } from 'react';
import useAlerts from './useAlerts';
import { useApi } from './useApi';
import { CaseDetailsModel } from '../models/Case/CaseDetailsModel';
import {
  BEHError,
  conflictErrorMsgWithReloadHint,
  ErrorType,
  useAlertErrorMsg
} from '../utils/Error/ErrorCode';

export function useUpdateCase() {
  const { alert } = useAlerts();
  const { makeRequest, makeRequestWithFullResponse } = useApi();
  const [loadingUpdateCase, setLoadingUpdateCase] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [abgeschlossen, setAbgeschlossen] = useState(false);
  const [heruntergeladen, setHeruntergeladen] = useState(false);

  const updateCase = async (
    caseId: string,
    updatedData: Map<string, string | null>
  ) => {
    setLoadingUpdateCase(true);

    try {
      const content = Object.fromEntries(updatedData);
      const updateCaseResponse = await makeRequest<CaseDetailsModel>(
        `/api/v1/cases/${caseId}`,
        'PATCH',
        undefined,
        undefined,
        undefined,
        content
      );
      alert('success', 'Antrag wurde erfolgreich aktualisiert!');
      setLoadingUpdateCase(false);
      setAbgeschlossen(true);
      return updateCaseResponse;
    } catch (e) {
      const antragError = e as BEHError;

      switch (antragError.type) {
        case ErrorType.BEHOERDENCLIENT_PROBLEMS_UPDATE_NOT_ALLOWED:
          alert('error', conflictErrorMsgWithReloadHint, 10000, true);
          break;
        case ErrorType.BEHOERDENCLIENT_PROBLEMS_USER_LOGGED_IN:
          alert(
            'error',
            'Die Benutzer:in ist aktuell eingeloggt, es konnte keine Zuweisung vorgenommen werden',
            3000,
            false
          );
          break;
        default:
          if (!antragError.traceId) {
            antragError.traceId = 'Server nicht erreichbar oder fehlerhaft';
          }

          alert(
            'error',
            useAlertErrorMsg.concat(` (${antragError?.traceId})`),
            10000,
            true
          );
      }

      setLoadingUpdateCase(false);
    }
  };
  const downloadSummary = async (
    caseId: string,
    filename: string,
    attachmentIds?: string | undefined
  ) => {
    setLoadingSummary(true);
    const url = attachmentIds
      ? `/api/v1/cases/${caseId}/summary?attachmentIds=${attachmentIds}`
      : `/api/v1/cases/${caseId}/summary`;
    try {
      const file = await makeRequestWithFullResponse(
        url,
        'GET',
        undefined,
        'application/zip',
        undefined,
        undefined,
        {
          responseType: 'blob'
        }
      );
      saveAs(file.data as Blob, filename, { autoBom: false });
      alert('success', 'Der Antrag wurde erfolgreich heruntergeladen!');
      setHeruntergeladen(true);
      setLoadingSummary(false);
    } catch (e) {
      setLoadingSummary(false);
      const downloadError = e as BEHError;
      alert(
        'error',
        useAlertErrorMsg.concat(` (${downloadError?.traceId})`),
        10000,
        true
      );
    }
  };

  return {
    updateCase,
    downloadSummary,
    loadingUpdateCase,
    abgeschlossen,
    heruntergeladen,
    loadingSummary
  };
}
