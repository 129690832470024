import {
  Button,
  Modal,
  Spinner,
  Stack
} from '@efast_public/fjd-component-library';
import { ReactElement, ReactNode } from 'react';

interface DataModelContentWrapperProps {
  children: ReactNode;
  isPrimaryButtonDisabled: boolean;
  onSave: () => void;
  isComponent: boolean;
  isLoading: boolean;
  onClose: () => void;
  isOpen: boolean;
}

export default function DataModelContentWrapper(
  props: DataModelContentWrapperProps
) {
  const componentContent = (): ReactElement => {
    return (
      <Stack alignItems={'flex-start'} style={{ maxWidth: '55rem' }}>
        {props.children}
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          style={{ width: '100%', maxWidth: '65rem', paddingTop: '1.5rem' }}
        >
          {props.isLoading ? (
            <Spinner></Spinner>
          ) : (
            <Button
              data-testid="save-button"
              disabled={props.isPrimaryButtonDisabled || props.isLoading}
              onClick={props.onSave}
            >
              Datenmodelle speichern
            </Button>
          )}
        </Stack>
      </Stack>
    );
  };

  const modalContent = (): ReactElement => {
    return (
      <Modal
        id="orgaUnit-modal"
        onClose={props.onClose}
        open={props.isOpen}
        className="datamodel-modal"
        closeOnBackdropClick={false}
      >
        <Modal.Header
          id="datamodelUploadModal_header"
          data-testid="datamodelUploadModal_header"
        >
          Optional: Manuell Datenmodelle hochladen
        </Modal.Header>
        <Modal.Content>{props.children}</Modal.Content>
        <Modal.Footer auto direction="rtl">
          <Button onClick={props.onClose}>Abbrechen</Button>

          {props.isLoading ? (
            <Spinner></Spinner>
          ) : (
            <Button
              id={'close_datamodelUploadModal'}
              data-testid="save-button-modal"
              onClick={props.onSave}
              disabled={props.isPrimaryButtonDisabled}
            >
              Datenmodelle speichern
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  return props.isComponent ? componentContent() : modalContent();
}
