import classes from './Kommunikation.module.css';

import {
  FjdAlert,
  FjdButton,
  FjdFlexSpacer,
  FjdHeading,
  FjdStack,
  FjdSpinner
} from 'fjd-react-components';
import { useContext, useEffect, useState } from 'react';
import NachrichtItem from './Nachrichten/NachrichtItem';
import { NachrichtModel } from '../../../../models/Nachrichten/NachrichtModel';
import { useSubmissionsAsNachrichten } from '../../../../hooks/useSubmissionsAsNachrichten';
import useTabs from '../../../../hooks/useTabs';
import NachrichtContent from './Nachrichten/NachrichtContent';
import { ContentTab } from '../../Schreibtisch';
import NachrichtSendenModal from './NachrichtSenden/NachrichtSendenModal';
import { useProfile } from '../../../../hooks/useProfile';
import { caseDetailsDataContext } from '../../../../context/Context';
import { useMessageRead } from '../../../../hooks/useMessageRead';
import { Bearbeitungsstand } from '../../../../models/Status/Bearbeitungsstand';
import { ErrorComponentBehoerdenServer } from '../../../shared/Error/ErrorComponent';

interface KommunikationProps {
  readonly setContentTabState: (val: ContentTab) => void;
}

const Kommunikation = (props: KommunikationProps) => {
  const { activeTab } = useTabs();
  const caseDetails = useContext(caseDetailsDataContext);
  const {
    nachrichten,
    error: useSubmissionsAsNachrichtenError,
    isValidating,
    mutate
  } = useSubmissionsAsNachrichten({ caseId: caseDetails.data.caseId });
  const { markMessageAsRead } = useMessageRead();
  const [selectedNachricht, setSelectedNachricht] = useState<NachrichtModel>();
  const [openSendenModal, setOpenSendenModal] = useState(false);
  const canCommunicate = activeTab?.data.element.canCommunicate;
  const canInteract =
    activeTab?.data.element.bearbeitungsstand !==
    Bearbeitungsstand.ABGESCHLOSSEN;
  const alertTitle =
    canCommunicate && canInteract
      ? 'Wählen Sie auf der linken Seite eine Nachricht aus oder verfassen Sie eine.'
      : 'Wählen Sie auf der linken Seite eine Nachricht aus.';
  const initialSubmissionAbsender =
    activeTab?.data.element.initialSubmission.absenderName;
  const { profile, error: profileError } = useProfile();
  const bearbeitendeNutzer = caseDetails.data.bearbeiterDto?.id === profile?.id;
  const isLoggedInUserBearbeiter =
    caseDetails.data.bearbeiterDto?.id === profile?.id;

  const onClickNachricht = (nachricht: NachrichtModel) => {
    if (nachricht.submissionId === selectedNachricht?.submissionId) {
      setSelectedNachricht(undefined);
    } else {
      setSelectedNachricht(nachricht);
    }
    const currentBearbeiterId = caseDetails.data.bearbeiterDto?.id;
    if (isLoggedInUserBearbeiter && !nachricht.hasBeenRead) {
      markMessageAsRead(nachricht.submissionId, currentBearbeiterId).then(() =>
        mutate((submissions) => {
          return submissions?.map((msg) => {
            if (msg.submissionId === nachricht.submissionId) {
              return { ...msg, hasBeenRead: true };
            }
            return msg;
          });
        }, false)
      );
    }
  };

  useEffect(() => {
    setSelectedNachricht(undefined);
  }, [caseDetails.data.caseId]);

  if (useSubmissionsAsNachrichtenError || profileError) {
    return <ErrorComponentBehoerdenServer />;
  } else if (isValidating) {
    return <FjdSpinner />;
  } else {
    return (
      <div id={'container'} className={classes.container}>
        <div id={'left'} className={classes.left}>
          <div
            id={'nachrichten_schreiben_container'}
            className={classes.nachrichtenContainer}
          >
            <FjdStack
              className={classes.caseNameTitle}
              id={'nachrichten_liste'}
              orientation={'horizontal'}
            >
              <FjdHeading visualLevel={2} text={'Nachrichten'}></FjdHeading>
              <FjdFlexSpacer />
              <NachrichtSendenModal
                onClose={() => {
                  setOpenSendenModal(false);
                  caseDetails?.refresh();
                }}
                caseId={caseDetails.data.caseId}
                initialSubmissionAbsender={initialSubmissionAbsender}
                caseVersion={caseDetails.data.version}
                useSubmissionsAsNachrichtenError={
                  useSubmissionsAsNachrichtenError
                }
                isOpen={openSendenModal}
              />
              {canInteract && (
                <FjdButton
                  disabled={!canCommunicate || !bearbeitendeNutzer}
                  appearance={'default'}
                  size={'m'}
                  label={'Nachricht schreiben'}
                  iconLeft={'add'}
                  key={Math.random()}
                  onClick={() => setOpenSendenModal(!openSendenModal)}
                  id={'nachrichten_schreiben_btn'}
                />
              )}
            </FjdStack>
          </div>
          <div style={{ marginTop: '80px' }} />
          <FjdStack id={'nachrichten_liste'} orientation={'vertical'}>
            {nachrichten?.map((value) => (
              <NachrichtItem
                selected={
                  value.submissionId === selectedNachricht?.submissionId
                }
                key={Math.random()}
                nachricht={value}
                selectNachrichtHandler={onClickNachricht}
              />
            ))}
          </FjdStack>
        </div>
        <div id={'right'} className={classes.right}>
          {selectedNachricht ? (
            <NachrichtContent
              nachricht={selectedNachricht}
              setContentTabState={props.setContentTabState}
              antragssteller={initialSubmissionAbsender}
              bescheidstatus={caseDetails.data.bescheidstatus}
            />
          ) : (
            <div id={'nachricht_verfassen_hinweis'}>
              <FjdAlert
                actionLabel={
                  canCommunicate && canInteract && bearbeitendeNutzer
                    ? 'neue Nachricht'
                    : undefined
                }
                appearance="muted"
                closable={false}
                intent="info"
                title={alertTitle}
                onActionClick={() => setOpenSendenModal(!openSendenModal)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default Kommunikation;
