import { NachrichtModel } from '../models/Nachrichten/NachrichtModel';
import useSWR from 'swr';

interface UseSubmissionsAsNachrichtenConfig {
  caseId: string;
}

export function useSubmissionsAsNachrichten({
  caseId
}: UseSubmissionsAsNachrichtenConfig) {
  const nachrichtenUrl: string | null =
    caseId !== '' ? `/api/v1/cases/${caseId}/submissions` : null;

  const {
    data: nachrichten,
    error,
    isValidating,
    mutate
  } = useSWR<Array<NachrichtModel>>(
    [nachrichtenUrl, undefined, 'application/vnd.fjd.beh.message+json'],
    { suspense: false }
  );

  return { nachrichten, error, isValidating, mutate };
}
