import {
  FjdButton,
  FjdCard,
  FjdCheckbox,
  FjdFlexSpacer,
  FjdStack,
  FjdTruncatedText
} from 'fjd-react-components';
import { forwardRef, useEffect, useState } from 'react';
import { ReactSortable, SortableEvent } from 'react-sortablejs';

import { TableColumn } from '../../hooks/useTable';

interface ColumnConfigurationProps {
  readonly columns: Array<TableColumn>;
  readonly setColumns: (columns: Array<TableColumn>) => void;
  readonly toggleColumn: (columnId: string) => void;
}

const ColumnsStack = forwardRef(({ children }: any, ref: any) => {
  return (
    <FjdStack ref={ref} spacing="s">
      {children}
    </FjdStack>
  );
});

export function ColumnConfiguration({
  columns,
  setColumns,
  toggleColumn
}: ColumnConfigurationProps) {
  const [columnsList, setColumnsList] = useState<Array<TableColumn>>([]);

  useEffect(() => {
    setColumnsList(columns.filter((column) => !column.fixed));
  }, [columns]);

  return (
    <FjdCard solid>
      <ReactSortable
        animation={200}
        handle="[data-handle]"
        list={columnsList.map((tableColumn: TableColumn) => ({
          ...tableColumn,
          chosen: true
        }))}
        onEnd={(evt: SortableEvent) => {
          [columnsList[evt.oldIndex], columnsList[evt.newIndex]] = [
            columnsList[evt.newIndex],
            columnsList[evt.oldIndex]
          ];

          setColumns(
            columns.map((column, index) => {
              if (column.fixed) {
                return column;
              }
              const prevFixedColumns = columns.filter(
                (c, i) => c.fixed && i < index
              ).length;

              return columnsList[index - prevFixedColumns];
            })
          );
        }}
        setList={setColumnsList}
        tag={ColumnsStack}
      >
        {columnsList.map((column) => (
          <div key={column.id}>
            <FjdStack orientation="horizontal" spacing="s">
              <FjdButton
                appearance="primary-link-invert"
                cursor="grab"
                data-handle
                hideLabel
                iconLeft="draggable"
                label="Spalte verschieben"
                size="xs"
              />
              <FjdTruncatedText text={column.header} />
              <FjdFlexSpacer />
              <FjdCheckbox
                checked={column.active}
                id={`switch-${column.id}`}
                onChange={() => {
                  toggleColumn(column.id);
                }}
                size="s"
              />
            </FjdStack>
          </div>
        ))}
      </ReactSortable>
    </FjdCard>
  );
}
