import { FjdStack } from 'fjd-react-components';
import { Icon } from '@efast_public/fjd-component-library';
import './KeyUploadState.css';
import {
  IconAppearance,
  IconGlyph
} from '@efast_public/fjd-component-library/build/components/Icon/Icon.types';
import { ReactElement } from 'react';

interface KeyUploadStateProps {
  readonly hasUpdateMode: boolean;
  readonly hasDecryptionKey: boolean;
  readonly hasSigningKey: boolean;
  readonly additionalKey?: string;
}

function KeyUploadState(props: KeyUploadStateProps): ReactElement {
  const successIconProperties = {
    name: 'checkmark-filled' as IconGlyph,
    variant: 'success' as IconAppearance
  };

  const defaultIconProperties = {
    name: 'circle-dash' as IconGlyph
  };

  return (
    <FjdStack orientation="horizontal" className="containerKeys">
      <FjdStack orientation={'vertical'}>
        <FjdStack orientation={'horizontal'}>
          <Icon {...successIconProperties} />
          <span className="descriptionText">PublicEncryption</span>
        </FjdStack>
        <FjdStack orientation={'horizontal'}>
          <Icon
            data-testid={
              props.hasDecryptionKey || props.hasUpdateMode
                ? `privateDecryptionSuccess${props.additionalKey}`
                : `privateDecryptionEmpty${props.additionalKey}`
            }
            {...(props.hasDecryptionKey || props.hasUpdateMode
              ? { ...successIconProperties }
              : { ...defaultIconProperties })}
          />
          <span className="descriptionText">PrivateDecryption</span>
        </FjdStack>
      </FjdStack>
      <FjdStack orientation="vertical">
        <FjdStack orientation={'horizontal'}>
          <Icon {...successIconProperties} />
          <span className="descriptionText">PublicSignature</span>
        </FjdStack>
        <FjdStack orientation={'horizontal'}>
          <Icon
            data-testid={
              props.hasSigningKey || props.hasUpdateMode
                ? `privateSigningSuccess${props.additionalKey}`
                : `privateSigningEmpty${props.additionalKey}`
            }
            {...(props.hasSigningKey || props.hasUpdateMode
              ? { ...successIconProperties }
              : { ...defaultIconProperties })}
          />
          <span className="descriptionText">PrivateSigning</span>
        </FjdStack>
      </FjdStack>
    </FjdStack>
  );
}

export default KeyUploadState;
