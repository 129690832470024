import { AttachmentExtendedModel } from '../models/Case/CaseDetailsModel';
import useSWR from 'swr';

interface UseAttachmentsDokumenteTabConfig {
  caseId: string;
}

export function useAttachmentsDokumenteTab({
  caseId
}: UseAttachmentsDokumenteTabConfig) {
  const {
    data: attachments,
    error,
    isValidating,
    mutate
  } = useSWR<Array<AttachmentExtendedModel>>(
    `/api/v1/cases/${caseId}/attachments`,
    {
      suspense: false
    }
  );

  return { attachments, isValidating, error, mutate };
}
