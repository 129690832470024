import { useProfile } from './useProfile';

export function useLocalStorageTable() {
  const { profile } = useProfile();

  const userId: string =
    profile?.id !== undefined ? profile.id : 'unknownUserId';
  const currentPageKey: string = `${userId}_currentPage`;
  const pageSizeKey: string = `${userId}_pageSize`;
  const tableColumnsKey: string = `${userId}_tableColumns`;

  const setLocalStorageItem = (itemKey: string, itemValue: string) => {
    localStorage.setItem(itemKey, itemValue);
  };
  return { tableColumnsKey, currentPageKey, pageSizeKey, setLocalStorageItem };
}
