import useSWR from 'swr';
import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { useApi } from './useApi';
import { useKeycloak } from '@react-keycloak/web';

export interface ConfigurationStepDto {
  configurationStep: ConfigurationStep;
}

export const enum ConfigurationStep {
  CHECKLIST = 'CHECKLIST',
  FITCONNECTCLIENT = 'FITCONNECTCLIENT',
  DESTINATIONS = 'DESTINATIONS',
  DATAMODELS = 'DATAMODELS',
  BUNDID = 'BUNDID',
  LOGO = 'LOGO',
  FOOTER = 'FOOTER',
  FINISH = 'FINISH',
  SUMMARY = 'SUMMARY'
}

export default function useConfigurationStep() {
  const { keycloak } = useKeycloak();
  const { makeRequest } = useApi();

  const hasGroupAdministration =
    keycloak.tokenParsed?.['groups'].includes('/Administration');
  const url: string | null = hasGroupAdministration
    ? `/api/v1/configuration/configurationStep`
    : null;
  const {
    data: configurationStep,
    error,
    mutate
  } = useSWR<ConfigurationStepDto>(url, {
    suspense: false
  });

  const updateConfiguratonStep = useCallback(
    (configurationDto: ConfigurationStepDto) => {
      return makeRequest<AxiosResponse>(
        `/api/v1/configuration/configurationStep`,
        'PATCH',
        undefined,
        undefined,
        undefined,
        configurationDto
      );
    },
    [makeRequest]
  );

  return { updateConfiguratonStep, configurationStep, error, mutate };
}

export function getNextStep(step: ConfigurationStep) {
  switch (step) {
    case ConfigurationStep.CHECKLIST:
      return ConfigurationStep.FITCONNECTCLIENT;
    case ConfigurationStep.FITCONNECTCLIENT:
      return ConfigurationStep.DESTINATIONS;
    case ConfigurationStep.DESTINATIONS:
      return ConfigurationStep.DATAMODELS;
    case ConfigurationStep.DATAMODELS:
      return ConfigurationStep.BUNDID;
    case ConfigurationStep.BUNDID:
      return ConfigurationStep.LOGO;
    case ConfigurationStep.LOGO:
      return ConfigurationStep.FOOTER;
    case ConfigurationStep.FOOTER:
      return ConfigurationStep.SUMMARY;
    case ConfigurationStep.SUMMARY:
      return ConfigurationStep.FINISH;
    case ConfigurationStep.FINISH:
      return ConfigurationStep.FINISH;
  }
}
export function getPrevStep(step: ConfigurationStep) {
  switch (step) {
    case ConfigurationStep.FINISH:
      return ConfigurationStep.SUMMARY;
    case ConfigurationStep.SUMMARY:
      return ConfigurationStep.FOOTER;
    case ConfigurationStep.FOOTER:
      return ConfigurationStep.LOGO;
    case ConfigurationStep.LOGO:
      return ConfigurationStep.BUNDID;
    case ConfigurationStep.BUNDID:
      return ConfigurationStep.DATAMODELS;
    case ConfigurationStep.DATAMODELS:
      return ConfigurationStep.DESTINATIONS;
    case ConfigurationStep.DESTINATIONS:
      return ConfigurationStep.FITCONNECTCLIENT;
    case ConfigurationStep.FITCONNECTCLIENT:
      return ConfigurationStep.CHECKLIST;
    case ConfigurationStep.CHECKLIST:
      return ConfigurationStep.CHECKLIST;
  }
}
