import IConfigAssistantStep from '../IConfigAssistantStep';
import {
  IconButton,
  List,
  Card,
  Tooltip,
  Button,
  Section,
  Stack,
  ListBox,
  Alert,
  Text
} from '@efast_public/fjd-component-library';
import './DataModel.css';

import '../Destinations/Destinations.css';
import useDataModel from '../../../../hooks/useDataModel';
import { DataModelDto, OrgUnitDataModelDto } from './DataModelDto';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import DatamodelUpload from './DataModelUpload';

interface DestinationsProps extends IConfigAssistantStep {}

function DataModel(props: DestinationsProps): ReactElement {
  const { orgUnitDataModelDtos } = useDataModel();
  const [openDataModelUploadModal, setOpenDataModelUploadModal] =
    useState<boolean>(false);
  const { setButtonLabel, nextStepAvailable } = props;
  useEffect(() => {
    setButtonLabel('Weiter');
    nextStepAvailable(true);
  }, [setButtonLabel, nextStepAvailable]);

  function getDataModelName(data: DataModelDto): ReactElement {
    const text: String = verifyName(data)
      ? data.name
        ? `${data.identifier}: ${data.name}`
        : data.identifier
      : `${data.identifier}: Datenmodell konnte nicht importiert werden.`;
    return (
      <Text
        style={{
          fontFamily: `'OpenSans-Bold', 'Open Sans Bold', 'Open Sans', 'sans-serif'`,
          fontSize: '0.87rem',
          fontStyle: 'normal'
        }}
        variant={verifyName(data) ? undefined : 'error'}
      >
        {text}
      </Text>
    );
  }

  function verifyName(data: DataModelDto): boolean {
    return data.name !== undefined && data.name !== null && data.name !== '';
  }

  const allDataModelsImported: boolean | undefined = useMemo(
    () =>
      orgUnitDataModelDtos
        ?.flatMap((v) => v.dataModelDtos)
        .every((v) => verifyName(v)),
    [orgUnitDataModelDtos]
  );

  useEffect(() => {
    if (!allDataModelsImported) {
      nextStepAvailable(false);
    } else {
      nextStepAvailable(true);
    }
  }, [allDataModelsImported, nextStepAvailable]);

  useEffect(() => {
    props.setInputChanged(false);
  });

  const dataModelInfos = orgUnitDataModelDtos ? (
    <ListBox className="listTest">
      {orgUnitDataModelDtos.map(
        (dataModel: OrgUnitDataModelDto, index: number) => {
          return (
            <div
              className={index >= 1 ? 'listOfPublicServiceTypes' : ''}
              key={dataModel.orgUnitName}
            >
              <div className="contentHeading">
                {`Zustellpunkt ${index + 1}:  ${dataModel.orgUnitName}`}
              </div>
              {dataModel.dataModelDtos.map((data, index) => {
                return (
                  <List.ListItem
                    key={`${data.identifier}_${index}`}
                    className="descriptionText datamodel-orgunit-item"
                  >
                    {getDataModelName(data)}
                  </List.ListItem>
                );
              })}
            </div>
          );
        }
      )}
    </ListBox>
  ) : null;

  return (
    <div>
      <Stack direction="row">
        <div className="contentHeading">
          Übersicht über Ihre Verwaltungsleistungen (Schritt 4 von 7)
        </div>
        <Tooltip tooltip="Die korrekten Verwaltungsleistungen pro Zustellpunkt müssen bei FIT-Connect eingestellt werden.">
          <IconButton
            className="tooltip-button-adjust-top"
            icon="information-filled"
            label="More information"
            variant="tertiary"
          />
        </Tooltip>
      </Stack>
      <DatamodelUpload
        isOpen={openDataModelUploadModal}
        setOpenModal={setOpenDataModelUploadModal}
        datamodels={
          orgUnitDataModelDtos
            ? orgUnitDataModelDtos.flatMap((datamodel) =>
                datamodel.dataModelDtos.map((data: DataModelDto) => {
                  return {
                    identifier: data.identifier,
                    isAvailable: verifyName(data)
                  };
                })
              )
            : []
        }
      />
      <div className="descriptionText">
        Folgende Verwaltungsleistungen und Datenmodelle liegen vor:
        {dataModelInfos ? (
          <Card className={'datamodel_card'}>
            <Card.Content>{dataModelInfos}</Card.Content>
          </Card>
        ) : null}
      </div>
      {!allDataModelsImported ? (
        <Alert
          id="dataModelUploadAlert"
          data-testid="dataModelUploadAlert"
          variant="error"
          message="Es konnten nicht alle Datenmodelle importiert werden, daher müssen die Fehlenden manuell hochgeladen werden."
        >
          <Button
            id="uploadButtonAlert"
            data-testid="uploadButtonAlert"
            beforeIcon={'upload'}
            onClick={() => setOpenDataModelUploadModal(true)}
            size="sm"
            key={'openDataModelUploadModal'}
          >
            Datenmodelle hochladen
          </Button>
        </Alert>
      ) : (
        <div className="modalSuccessContent">
          <Alert
            variant="success"
            message="Alle Datenmodelle liegen vor."
            key={'dataModelUploadAlertSuccess'}
          ></Alert>
          <div>
            <Section className={'dataModel_upload_section_info'}>
              Laden Sie andere Datenmodelle zu Ihren Verwaltungsleistungen jetzt
              oder später in den Einstellungen auch manuell hoch:
            </Section>
            <div className={'datenmodelle_upload'}>
              <Button
                variant={'tertiary'}
                id={'dataModel_upload_btn'}
                data-testid={'dataModel_upload_btn'}
                onClick={() => setOpenDataModelUploadModal(true)}
                beforeIcon={'upload'}
              >
                Andere Datenmodelle hochladen
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DataModel;
