import classes from '../../../Schreibtisch.module.css';
import _moment from 'moment';
import { FjdLink, FjdStack, FjdCollapsibleCard } from 'fjd-react-components';
import { useContext, useState } from 'react';
import useTabs from '../../../../../hooks/useTabs';
import {
  PaymentInformationKey,
  paymentInformationMapper
} from '../PaymentModal/PaymentInformation';
import { caseDetailsMapper } from '../../../../../models/Case/CaseDetailsModel';
import PaymentModal from '../PaymentModal/PaymentModal';
import BearbeitungsstandBadge from '../../../../shared/BearbeitungsstandBadge/BearbeitungsstandBadge';
import BescheidstatusBadge from '../../../../shared/BescheidstatusBadge/BescheidstatusBadge';
import { caseDetailsDataContext } from '../../../../../context/Context';

function Metadaten(): JSX.Element {
  const { activeTab } = useTabs();

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const caseDetailsData = useContext(caseDetailsDataContext);
  const [openCard, setOpenCard] = useState(true);

  let paymentInfo =
    caseDetailsData?.data && caseDetailsData?.data?.caseId !== ''
      ? paymentInformationMapper(caseDetailsMapper(caseDetailsData?.data))
      : null;

  return (
    <FjdStack orientation="vertical">
      <PaymentModal
        onClose={() => setOpenPaymentModal(false)}
        paymentInformation={paymentInfo}
        isOpen={openPaymentModal}
      />
      <FjdCollapsibleCard
        iconExpanded="chevron-up"
        iconCollapsed="chevron-down"
        headingColor="background-alternate"
        onToggle={(expanded) => setOpenCard(expanded)}
        expanded={openCard}
        heading={
          <FjdStack orientation="horizontal">
            <h2 style={{ marginTop: '3px', marginLeft: '10px' }}>Metadaten</h2>
          </FjdStack>
        }
      >
        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
          <div className={classes.collapsibleCardBody}>
            <dl>
              <dt>Empfänger:in</dt>
              <dd className={classes.data} id={'metadaten_empfaenger'}>
                {caseDetailsData?.data.organizationalUnitName}
              </dd>
              <dt>Eingang</dt>
              <dd id={'eingang'}>
                {_moment
                  .utc(activeTab?.data.element.createdOn)
                  .local()
                  .format('DD.MM.YYYY, HH:mm')}
              </dd>
              <dt>Bearbeitungsstand</dt>
              <dd>
                <BearbeitungsstandBadge
                  bearbeitungsstand={caseDetailsData?.data.bearbeitungsstand}
                  fjdBadgeSize={'xs'}
                />
              </dd>
              <dt>Bescheidstatus</dt>
              <dd id={'bescheidstatus'}>
                <BescheidstatusBadge
                  bescheidstatus={caseDetailsData?.data.bescheidstatus}
                  bearbeitungsstand={caseDetailsData?.data.bearbeitungsstand}
                  fjdBadgeSize={'xs'}
                />
              </dd>
              {caseDetailsData?.data.isBeschieden && (
                <>
                  <dt id={'versandweg'}>Versandweg Bescheid</dt>
                  <dd id={'versandweg_value'}>
                    {caseDetailsData?.data.isPostalisch
                      ? 'postalisch'
                      : 'digital'}
                  </dd>
                </>
              )}
              {paymentInfo?.available() && (
                <>
                  <dt>Zahlung</dt>
                  <FjdLink
                    key={Math.random()}
                    onClick={() => setOpenPaymentModal(true)}
                    test-id={'payment-information-link'}
                  >
                    <dd>
                      <span className={classes.zahlung} id={'zahlung'}>
                        {`${paymentInfo.getValueOf(
                          PaymentInformationKey.STATUS
                        )} `}
                      </span>
                      (Information)
                    </dd>
                  </FjdLink>
                </>
              )}
            </dl>
          </div>
        </div>
      </FjdCollapsibleCard>
    </FjdStack>
  );
}
export default Metadaten;
