import { FjdNewSelect, FjdSpinner } from 'fjd-react-components';
import { useState } from 'react';
import { AntragObj } from '../../../models/Antrag/AntragsModel';
import { useKeycloakUser } from '../../../hooks/useKeycloakUser';
import { useKeycloak } from '@react-keycloak/web';
import { Bearbeitungsstand } from '../../../models/Status/Bearbeitungsstand';
import { CaseDetailsModel } from '../../../models/Case/CaseDetailsModel';
import useAssignment from '../../../hooks/useAssignment';
import { SelectOption } from '../../../utils/antragsuebersicht';
import { Property } from 'csstype';
import { ErrorComponentBehoerdenServer } from '../Error/ErrorComponent';

interface ZuweisungSachbearbeiterProps {
  readonly antragObj: AntragObj | CaseDetailsModel;
  readonly reloadData: (caseDetails: CaseDetailsModel) => void;
  readonly position?: Property.Position;
  readonly marginBottom?: string | undefined;
}

function ZuweisungTeamleiter(props: ZuweisungSachbearbeiterProps): JSX.Element {
  const dropdownPosition =
    props.position !== undefined ? props.position : 'static';
  const dropdownMarginBottom = props.marginBottom ? props.marginBottom : '0';
  const [zuweisungUpdating, setZuweisungUpdating] = useState<boolean>(false);
  const assignedBearbeiterId = props.antragObj.bearbeiterDto?.id;
  const caseId = props.antragObj.caseId;

  const { assignCase, revokeCase } = useAssignment({
    reloadData: props.reloadData,
    antragObj: props.antragObj,
    setZuweisungUpdating: setZuweisungUpdating
  });
  const { keycloak } = useKeycloak();
  const filteredOrgUnitName = keycloak.tokenParsed?.['groups'].filter(
    (group: Array<string>) =>
      group.includes(
        `${props.antragObj.organizationalUnitName}/Sachbearbeitung`
      )
  );
  const caseAlreadyEdited =
    props.antragObj.firstInteractionTimestamp !== null &&
    props.antragObj.firstInteractionTimestamp !== undefined;

  const { users, error: useKeycloakUserError } = useKeycloakUser({
    orgUnitName: filteredOrgUnitName
  });

  const assignBearbeiterToCase = (newBearbeiterId: string) => {
    if (newBearbeiterId !== assignedBearbeiterId) {
      assignCase(newBearbeiterId, props.antragObj.version);
    }
  };

  const revokeBearbeiterFromCase = () => {
    if (props.antragObj.bearbeiterDto !== null) {
      revokeCase(props.antragObj.version);
    }
  };

  const updateBearbeiterAssignment = (newBearbeiterId: string) => {
    if (newBearbeiterId === 'null') {
      revokeBearbeiterFromCase();
    } else {
      assignBearbeiterToCase(newBearbeiterId);
    }
  };

  const toEmpty = (input: string | undefined) => {
    return input ? input : '';
  };
  if (props.antragObj.bearbeitungsstand === Bearbeitungsstand.ABGESCHLOSSEN) {
    return (
      <span id={`bearbeiter_${caseId}_${assignedBearbeiterId}`}>
        {props.antragObj.bearbeiterDto?.fullName ?? 'Keine Bearbeiter:in'}
      </span>
    );
  }

  function onChangeAssignment(value: string) {
    const newBearbeiterId = value;
    if (newBearbeiterId !== '') {
      updateBearbeiterAssignment(newBearbeiterId);
    }
  }

  if (useKeycloakUserError) {
    return (
      <ErrorComponentBehoerdenServer
        errorCode={useKeycloakUserError?.traceId}
      />
    );
  } else if (zuweisungUpdating) {
    return <FjdSpinner />;
  } else if (users !== undefined) {
    const selectedItems: Array<SelectOption> = users?.map((b) => {
      return { label: b.fullName, value: b.id, disabled: false };
    });
    const notAssignedBearbeiter = {
      label: '- nicht zugewiesen -',
      value: 'null'
    };
    if (!caseAlreadyEdited) {
      selectedItems.unshift(notAssignedBearbeiter);
    }
    const assignedBearbeiter: SelectOption =
      props.antragObj.bearbeiterDto == null
        ? notAssignedBearbeiter
        : {
            label: toEmpty(props.antragObj.bearbeiterDto?.fullName),
            value: toEmpty(props.antragObj.bearbeiterDto?.id)
          };

    return (
      <div
        style={{
          position: dropdownPosition,
          bottom: dropdownMarginBottom,
          width: '12.2rem'
        }}
      >
        <FjdNewSelect
          id={`zuweisung_select_${caseId}`}
          name={`zuweisung_select_name_${caseId}`}
          disableSearch={true}
          size="m"
          searchInputMode={'text'}
          selectedItems={[assignedBearbeiter]}
          onChange={(options) => onChangeAssignment(options[0].value)}
          options={selectedItems}
        />
      </div>
    );
  } else {
    return <span id={'id'}>{props.antragObj.bearbeiterDto?.fullName}</span>;
  }
}
export default ZuweisungTeamleiter;
