import Config from '../config.json';

function getFqdn(): string {
  return window.location.hostname;
}

function removeFirstSubdomain(partialFqdn: string): string {
  if (partialFqdn.includes('.')) {
    partialFqdn = partialFqdn.substring(partialFqdn.indexOf('.') + 1);
  }

  return partialFqdn;
}

function removeFirstSubdomainPrefix(partialFqdn: string): string {
  const regex: RegExp = /^[a-z\d]+-/;

  if (regex.test(partialFqdn)) {
    partialFqdn = partialFqdn.split('-')[1];
  }

  return partialFqdn;
}

export function getApiServerUrl(): string {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  } else if (
    Config.REACT_APP_API_URL &&
    Config.REACT_APP_API_URL !== 'ENV_'.concat('API_URL')
  ) {
    return Config.REACT_APP_API_URL;
  } else {
    return `${window.location.protocol}//api-${removeFirstSubdomainPrefix(
      getFqdn()
    )}`;
  }
}

export function getFooterApiServerUrl(): string {
  if (process.env.REACT_APP_FOOTER_API_URL) {
    return process.env.REACT_APP_FOOTER_API_URL;
  } else if (
    Config.REACT_APP_FOOTER_API_URL &&
    Config.REACT_APP_FOOTER_API_URL !== 'ENV_'.concat('FOOTER_API_URL')
  ) {
    return Config.REACT_APP_FOOTER_API_URL;
  } else {
    return `${window.location.protocol}//footerservice-${removeFirstSubdomain(
      getFqdn()
    )}`;
  }
}

export function getKeycloakClientId(): string {
  return 'behoerdenclient-client';
}
export function getKeycloakRealm(): string {
  return `beh-${getMandant()}`;
}

export function getKeycloakUrl(): string {
  return process.env.REACT_APP_KEYCLOAK_URL || Config.REACT_APP_KEYCLOAK_URL;
}

export function getMandant(): string {
  return window.location.pathname.substring(1).split('/')[0];
}
