import { FjdBadge, FjdStack } from 'fjd-react-components';
import { FilterBadge } from '../../../hooks/useFilterBadges';

interface FilterBadgesProps {
  readonly badges: Array<FilterBadge>;
  readonly onRemoveBadge: (badge: FilterBadge) => void;
}

export default function FilterBadges({
  badges,
  onRemoveBadge
}: FilterBadgesProps) {
  if (badges.length <= 0) {
    return <></>;
  }

  return (
    <FjdStack orientation="horizontal" spacing="xs">
      {badges.map((badge) => (
        <FjdBadge
          key={badge.value}
          label={badge.label}
          onClick={() => onRemoveBadge(badge)}
          appearance="info"
          icon="close"
        ></FjdBadge>
      ))}
    </FjdStack>
  );
}
