import {
  ClientDestinationsModel,
  OrganizationalUnit
} from '../../../../../hooks/useDestinations';
import {
  Button,
  Card,
  IconButton,
  Modal,
  Section,
  Tooltip
} from '@efast_public/fjd-component-library';
import './OrgaUnitUpdateComponent.css';

import { useEffect, useState } from 'react';
import { OrgUnitUpdateComponent } from './OrgUnitUpdateComponent';

interface OrgaUnitModalProps {
  readonly orgaUnits?: ClientDestinationsModel;
  readonly orgaUnitsFromFitConnect?: ClientDestinationsModel;
  readonly isOpenModal: boolean;
  readonly setOpenModal: (value: boolean) => void;
  readonly setUpdatedOrgaUnitsName: (value: Map<string, string>) => void;
}

export function OrgaUnitModal({
  orgaUnitsFromFitConnect,
  setOpenModal,
  orgaUnits,
  isOpenModal,
  setUpdatedOrgaUnitsName
}: OrgaUnitModalProps) {
  const [newNameByOldName, setNewNameByOldName] = useState<Map<string, string>>(
    new Map<string, string>()
  );
  const [hasError, setHasError] = useState<boolean>(false);

  function onUpdateNewNameByOldName(
    oldOrgUnitName: string,
    updatedOrgUnitName: string
  ) {
    setNewNameByOldName((state) => {
      state.set(oldOrgUnitName, updatedOrgUnitName.trim());
      return new Map<string, string>(state);
    });
  }

  useEffect(() => {
    const values: Array<string> = Array.from(newNameByOldName.values());
    const valuesAsSet: Set<string> = new Set(values);
    if (values.length === valuesAsSet.size) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [newNameByOldName]);

  function onClose() {
    newNameByOldName.clear();
    setOpenModal(false);
    setHasError(false);
  }

  function onSave() {
    setUpdatedOrgaUnitsName(newNameByOldName);
  }

  function extractOriginalOrgName(
    orgFromDb: OrganizationalUnit
  ): OrganizationalUnit | undefined {
    return orgaUnitsFromFitConnect?.organizationalUnitDtos.find(
      (org) =>
        org.destinationDtos[0].destinationId ===
        orgFromDb.destinationDtos[0].destinationId
    );
  }

  return (
    <Modal
      id="orgaUnit-modal"
      onClose={() => setOpenModal(false)}
      open={isOpenModal}
      className="orgunit-modal"
    >
      <Modal.Header id="orgaUnitModal" data-testid="orgaUnitModal">
        Optional: Zustellpunkte bezeichnen
      </Modal.Header>
      <div className="sectionTitle">
        {orgaUnits?.organizationalUnitDtos.length} Zustellpunkte aus FIT-Connect
        importiert{' '}
        <Tooltip tooltip="Die korrekten Verwaltungsleistungen pro Zustellpunkt müssen bei FIT-Connect eingestellt werden.">
          <IconButton
            icon="information-filled"
            label="More information"
            variant="tertiary"
          />
        </Tooltip>
      </div>

      <Modal.Content>
        <Section className={'section'}>
          Sie können hier Bezeichnungen eingeben, welche für die
          Gruppen-Zuordnung im Behörden-Client verwendet werden. Geben Sie keine
          Bezeichnungen ein, dann bleiben die Gruppennamen im Format
          'Zustellpunkt [n] (Destination ID)' bestehen, wobei [n] die
          hochgezählte Ziffer in der bei FIT-Connect angelegten Reihenfolge ist.
        </Section>
        <Card className={'card'}>
          <Card.Content>
            {orgaUnits?.organizationalUnitDtos.map((orga) => {
              return (
                <OrgUnitUpdateComponent
                  key={orga.destinationDtos[0].destinationId}
                  originalOrgName={extractOriginalOrgName(orga)?.name}
                  newNameByOldName={newNameByOldName}
                  hasError={!hasError}
                  updateNewNameByOldName={onUpdateNewNameByOldName}
                  orgName={orga.name}
                />
              );
            })}
          </Card.Content>
        </Card>
      </Modal.Content>
      <Modal.Footer auto direction="rtl">
        <Button onClick={onClose}>Abbrechen</Button>
        <Button id={'close_orgUnitModal'} onClick={onSave} disabled={!hasError}>
          Bezeichnungen speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OrgaUnitModal;
