import { FjdModal } from 'fjd-react-components';
import { VermerkDto } from '../../models/Vermerk/VermerkDto';

interface HintModalProps {
  readonly onClose: () => void;
  readonly isOpen: boolean;
  readonly onDelete: (vermerk: VermerkDto) => void;
  readonly vermerk: VermerkDto;
}

export function DeleteVermerkConfirmationModal({
  onClose,
  isOpen,
  onDelete,
  vermerk
}: HintModalProps) {
  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={'Vermerk löschen'}
      id="vermerk_delete_modal_confirmation"
      onClose={onClose}
      onPrimaryButtonClick={() => {
        onDelete(vermerk);
      }}
      onSecondaryButtonClick={() => onClose()}
      open={isOpen}
      primaryButtonLabel={'Vermerk löschen'}
      secondaryButtonLabel={'Abbrechen'}
    >
      <div id={'delete_vermerk_hint'}>
        Möchten Sie diesen Vermerk wirklich endgültig löschen?
      </div>
    </FjdModal>
  );
}

export default DeleteVermerkConfirmationModal;
