import {
  Bearbeitungsstand,
  bearbeitungsstandToString
} from '../models/Status/Bearbeitungsstand';
import {
  Bescheidstatus,
  bescheidstatusToString
} from '../models/Status/Bescheidstatus';

import { useProfile } from '../hooks/useProfile';
import { FjdSelectOption } from 'fjd-react-components/build/components/Selects/Select.types';
import { useMemo } from 'react';

export class SelectOption implements FjdSelectOption {
  disabled?: boolean | undefined;
  label: string;
  value: string;
  constructor(label: string, value: string, disabled?: boolean) {
    this.label = label;
    this.value = value;
    this.disabled = disabled ?? false;
  }
}

export const bearbeitungsstandSelectOptions: Array<SelectOption> =
  createBearbeitungsstandSelectOptions();

function createBearbeitungsstandSelectOptions() {
  let array: Array<SelectOption> = [];

  for (const value in Bearbeitungsstand) {
    if ((value as Bearbeitungsstand) === Bearbeitungsstand.UNKNOWN) {
      continue;
    }
    array.push(
      new SelectOption(
        bearbeitungsstandToString(value as Bearbeitungsstand),
        value
      )
    );
  }
  return array;
}

export const bescheidstatusSelectOptions: Array<SelectOption> =
  createBescheidstatusSelectOptions();

function createBescheidstatusSelectOptions() {
  let array: Array<SelectOption> = [];

  for (const value in Bescheidstatus) {
    if ((value as Bescheidstatus) === Bescheidstatus.UNKNOWN) {
      continue;
    }
    array.push(
      new SelectOption(bescheidstatusToString(value as Bescheidstatus), value)
    );
  }
  return array;
}

export function useBearbeiterSelectOption() {
  const { profile, error } = useProfile();

  const bearbeiterSelectOptions: Array<SelectOption> = useMemo(
    () => [
      new SelectOption('Antrag zugewiesen', 'NOTNULL'),
      new SelectOption('Antrag nicht zugewiesen', 'NULL'),
      new SelectOption('Antrag mir zugewiesen', profile?.id ?? '')
    ],
    [profile]
  );

  return { bearbeiterSelectOptions, error };
}

export const buildLikeQuery = (input: any) => {
  if (input) {
    return `%${input}%`;
  }
  return '';
};

export function formatByte(x: string) {
  const units = ['Bytes', 'KB', 'MB'];
  let unitsIndex = 0,
    size = parseInt(x, 10) || 0;
  while (size > 1024) {
    size = size / 1024;
    unitsIndex++;
  }
  return `${size.toFixed(unitsIndex >= 2 ? 1 : 0).replace('.', ',')} ${
    units[unitsIndex]
  }`;
}
