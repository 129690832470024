import { useKeycloak } from '@react-keycloak/web';
import { AntragObj } from '../../../models/Antrag/AntragsModel';
import ZuweisungTeamleiter from './ZuweisungTeamleiter';
import ZuweisungSachbearbeiter from './ZuweisungSachbearbeiter';
import { CaseDetailsModel } from '../../../models/Case/CaseDetailsModel';
import { FjdButtonSize } from 'fjd-react-components/build/components/Button/Button';
import { Property } from 'csstype';

export interface ZuweisungComponentConfig {
  readonly antrag: AntragObj | CaseDetailsModel;
  readonly reloadData: (caseDetails: CaseDetailsModel) => void;
  readonly isSchreibtisch: boolean;
  readonly position?: Property.Position;
  readonly marginBottom?: string | undefined;
  readonly isValidating?: boolean;
  readonly buttonSize?: FjdButtonSize;
}
function ZuweisungComponent(props: ZuweisungComponentConfig): JSX.Element {
  const { keycloak } = useKeycloak();

  const isTeamleiter = keycloak.tokenParsed?.['groups'].includes(
    `/${props.antrag.organizationalUnitName}/Teamleitung`
  );
  return isTeamleiter ? (
    <ZuweisungTeamleiter
      antragObj={props.antrag}
      reloadData={props.reloadData}
      marginBottom={props.marginBottom}
      position={props.position}
    />
  ) : (
    <ZuweisungSachbearbeiter
      antragObj={props.antrag}
      reloadData={props.reloadData}
      isSchreibtisch={props.isSchreibtisch}
      isValidating={props.isValidating}
      buttonSize={props.buttonSize}
    />
  );
}
export default ZuweisungComponent;
