import { AxiosResponse } from 'axios';
import { useState } from 'react';

import useAlerts from './useAlerts';
import { useApi } from './useApi';
import {
  BEHError,
  ErrorType,
  useAlertErrorMsg,
  userConflictErrorMsg
} from '../utils/Error/ErrorCode';

export interface KeycloakGroup {
  name: string;
  id: string;
}

export interface KeycloakAssignedGroups {
  groupName: string;
  subGroups: Array<KeycloakGroup>;
}
export interface UserRequestDto {
  email: string;
  groups: Array<KeycloakAssignedGroups>;
  redirectUri: string;
}

export function useInvite() {
  const { alert } = useAlerts();
  const { makeRequest } = useApi();
  const [loading, setLoading] = useState(false);

  const invite = async (userRequest: UserRequestDto) => {
    setLoading(true);

    try {
      const newInviteResponse = await makeRequest<AxiosResponse>(
        `/api/v1/users`,
        'POST',
        undefined,
        undefined,
        undefined,
        userRequest
      );

      alert('success', 'Einladung wurde erfolgreich versendet!');
      setLoading(false);

      return newInviteResponse;
    } catch (e) {
      const inviteError = e as BEHError;
      switch (inviteError.type) {
        case ErrorType.BEHOERDENCLIENT_PROBLEMS_USER_CONFLICT:
          alert('error', userConflictErrorMsg, 10000, true);
          break;
        default:
          alert(
            'error',
            useAlertErrorMsg.concat(` (${inviteError?.traceId})`),
            10000,
            true
          );
      }
      setLoading(false);
    }
  };

  return { invite, loading };
}
