import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  FileDropField,
  FileList,
  Icon,
  IconButton,
  Spacer,
  Switch,
  Tooltip,
  Typography
} from '@efast_public/fjd-component-library';
import IConfigAssistantStep from '../IConfigAssistantStep';
import { FormatUtil } from '../../../../utils/formatUtil';
import { FjdLoadingOverlay } from 'fjd-react-components';
import forge from 'node-forge';
import {
  useBundIdDelete,
  useBundIdGet,
  useBundIdPost
} from '../../../../hooks/useBundId';
import useAlerts from '../../../../hooks/useAlerts';
import './BundId.css';
import { convertBlobToTextString } from '../../../../utils/fileToString';
import { compareDateTime } from '../../../../utils/compareUtil';

type AccordionLabel = 'BundID-Postfach-Zertifikat' | 'Private Key';
type DataTestId = 'uploadFieldCertificate' | 'uploadFieldPrivateKey';

type FileType = 'zbpCertificate' | 'zbpPrivateKey';

interface KeyPairFileState {
  readonly file: File | undefined;
  readonly validity: Date | undefined;
  readonly uploadDate: Date | undefined;
}

interface BundIdProps extends IConfigAssistantStep {}

interface CertificateUploaderSectionProps {
  readonly fileType: FileType;
  readonly fileData: KeyPairFileState | undefined;
  readonly handleFileChange: (file: File, fileType: FileType) => void;
  readonly onFileDelete: (fileType: FileType) => void;
  readonly isFileUploadFieldDisabled: boolean;
}

function KeyPairUploadFields(
  props: CertificateUploaderSectionProps
): ReactElement {
  const label: AccordionLabel =
    props.fileType === 'zbpCertificate'
      ? 'BundID-Postfach-Zertifikat'
      : 'Private Key';

  const dataTestId: DataTestId =
    props.fileType === 'zbpCertificate'
      ? 'uploadFieldCertificate'
      : 'uploadFieldPrivateKey';

  return (
    <Accordion
      data-testid={`accordion-${dataTestId}`}
      before={
        <Icon
          data-testid={`statusIcon-${dataTestId}`}
          name={props.fileData ? 'checkmark-filled' : 'circle-dash'}
          variant={props.fileData ? 'success' : undefined}
          title="Status: ok"
        />
      }
      label={label}
    >
      <div style={{ paddingLeft: '2.7rem', marginBottom: '0.5rem' }}>
        <Typography variant="secondary" size="s">
          {props.fileData && props.fileData.uploadDate
            ? `Hochgeladen am: ${FormatUtil.formatDateTime(props.fileData.uploadDate, 'DD.MM.YYYY')}${props.fileData.validity ? `, Gültig bis: ${FormatUtil.formatDateTime(props.fileData.validity, 'DD.MM.YYYY')}` : ', Gültig bis: unbegrenzt'}`
            : null}
        </Typography>
      </div>
      {!props.fileData ? (
        <FileDropField
          disabled={props.isFileUploadFieldDisabled}
          className="uploadField"
          data-testid={`uploadField-${dataTestId}`}
          label="Neue Datei auswählen"
          dropHelperText={'oder in dieses Feld ziehen'}
          onAdd={(files) => props.handleFileChange(files[0], props.fileType)}
        />
      ) : (
        <FileList>
          <FileList.FileItem
            data-testid={`fileItem-${dataTestId}`}
            file={{ name: props.fileData?.file?.name ?? '' }}
            onDelete={() => props.onFileDelete(props.fileType)}
          />
        </FileList>
      )}
    </Accordion>
  );
}

export default function BundId(props: BundIdProps): ReactElement {
  const [zbpCertificate, setZbpCertificate] = useState<
    KeyPairFileState | undefined
  >();
  const [zbpPrivateKey, setZbpPrivateKey] = useState<
    KeyPairFileState | undefined
  >();
  const [isSwitchButtonChecked, setIsSwitchButtonChecked] =
    useState<boolean>(false);

  const { alert } = useAlerts();

  const { uploadBundIdKeyPair } = useBundIdPost();
  const {
    data: zbpKeyPair,
    mutateBundIdKeyPair,
    isValidating
  } = useBundIdGet();
  const { deleteBundIdKeyPair } = useBundIdDelete();

  const { addPrecondition, nextStepAvailable } = props;

  useEffect(() => {
    if (zbpKeyPair) {
      setZbpCertificate({
        file: new File([], zbpKeyPair.certificateFileName, {
          type: 'text/plain'
        }),
        uploadDate: zbpKeyPair.certificateUploadTime,
        validity: zbpKeyPair.certificateExpirationTime
      });
      setZbpPrivateKey({
        file: new File([], zbpKeyPair.privateKeyFileName, {
          type: 'text/plain'
        }),
        uploadDate: zbpKeyPair.privateKeyUploadTime,
        validity: undefined
      });
    }
  }, [zbpKeyPair]);

  useEffect(() => {
    if (isSwitchButtonChecked) {
      nextStepAvailable(true);
    } else {
      nextStepAvailable(!!(zbpCertificate && zbpPrivateKey));
    }
  }, [isSwitchButtonChecked, nextStepAvailable, zbpCertificate, zbpPrivateKey]);

  props.setButtonLabel('Speichern und weiter');

  const setAlertOnSuccess: (
    hasBundIdNotification: boolean,
    hasPrivateKeyNotification: boolean
  ) => void = useCallback(
    (hasBundIdNotification: boolean, hasPrivateKeyNotification: boolean) => {
      alert(
        'success',
        `Sie haben ${hasBundIdNotification ? 'das BundID-Postfach-Zertifikat' : ''} ${hasBundIdNotification && hasPrivateKeyNotification ? 'und' : ''} ${hasPrivateKeyNotification ? 'den Private Key' : ''} erfolgreich hinterlegt.`,
        10000,
        true
      );
    },
    [alert]
  );

  const getPrecondition: () => Promise<void> =
    useCallback(async (): Promise<void> => {
      if (isSwitchButtonChecked) {
        try {
          await deleteBundIdKeyPair();
          mutateBundIdKeyPair().then();
          return;
        } catch (error) {
          console.error('Error on BundId Keypair delete:', error);
          return await Promise.reject();
        }
      }

      const isCertificateUploadEqual: boolean =
        zbpCertificate?.uploadDate && zbpKeyPair
          ? compareDateTime(
              new Date(zbpCertificate.uploadDate),
              new Date(zbpKeyPair.certificateUploadTime)
            )
          : true;

      const isPrivateKeyUploadEqual: boolean =
        zbpPrivateKey?.uploadDate && zbpKeyPair
          ? compareDateTime(
              new Date(zbpPrivateKey.uploadDate),
              new Date(zbpKeyPair.privateKeyUploadTime)
            )
          : true;

      const zbpCertificateText: string | undefined =
        await convertBlobToTextString(zbpCertificate?.file);

      const zbpPrivateKeyText: string | undefined =
        await convertBlobToTextString(zbpPrivateKey?.file);

      if (zbpKeyPair) {
        if (!isCertificateUploadEqual && !isPrivateKeyUploadEqual) {
          try {
            await uploadBundIdKeyPair({
              certificate: zbpCertificateText,
              privateKey: zbpPrivateKeyText,
              certificateFileName: zbpCertificate?.file?.name || '',
              privateKeyFileName: zbpPrivateKey?.file?.name || ''
            });
            setAlertOnSuccess(true, true);
          } catch (e) {
            console.error('Error on certificate and private key upload', e);
            await Promise.reject();
          }
        } else if (!isCertificateUploadEqual && isPrivateKeyUploadEqual) {
          try {
            await uploadBundIdKeyPair({
              certificate: zbpCertificateText,
              certificateFileName: zbpCertificate?.file?.name || ''
            });
            setAlertOnSuccess(true, false);
          } catch (e) {
            console.error('Error on certificate upload', e);
            await Promise.reject();
          }
        } else if (isCertificateUploadEqual && !isPrivateKeyUploadEqual) {
          try {
            await uploadBundIdKeyPair({
              privateKey: zbpPrivateKeyText,
              privateKeyFileName: zbpPrivateKey?.file?.name || ''
            });
            setAlertOnSuccess(false, true);
          } catch (e) {
            console.error('Error on private key upload', e);
            await Promise.reject();
          }
        }
      } else {
        if (zbpCertificate?.file && zbpPrivateKey?.file) {
          try {
            await uploadBundIdKeyPair({
              certificate: zbpCertificateText,
              privateKey: zbpPrivateKeyText,
              certificateFileName: zbpCertificate?.file?.name || '',
              privateKeyFileName: zbpPrivateKey?.file?.name || ''
            });
            setAlertOnSuccess(true, true);
          } catch (e) {
            console.error('Error on certificate and private key upload', e);
            await Promise.reject();
          }
        }
      }
    }, [
      setAlertOnSuccess,
      mutateBundIdKeyPair,
      zbpKeyPair,
      deleteBundIdKeyPair,
      isSwitchButtonChecked,
      zbpCertificate,
      zbpPrivateKey,
      uploadBundIdKeyPair
    ]);

  useEffect(() => {
    addPrecondition({ condition: getPrecondition });
  }, [addPrecondition, getPrecondition]);

  const handleFileChange: (
    file: File,
    fileType: FileType
  ) => Promise<void> = async (
    file: File,
    fileType: FileType
  ): Promise<void> => {
    if (!file) {
      return;
    }
    try {
      const filePEM: string = await file.text();
      if (fileType === 'zbpCertificate') {
        const cert = forge.pki.certificateFromPem(filePEM);

        const isRSA: boolean =
          cert?.publicKey && 'n' in cert.publicKey && 'e' in cert.publicKey;

        if (!isRSA) {
          alert(
            'error',
            'Ungültiges Dateiformat. (Es wird nur eine Datei mit X.509 Formatierung akzeptiert.)',
            10000,
            true
          );
          return;
        }

        setZbpCertificate({
          file,
          validity: cert.validity.notAfter,
          uploadDate: new Date()
        });
      } else if (fileType === 'zbpPrivateKey') {
        try {
          forge.pki.privateKeyFromPem(filePEM);
        } catch (error) {
          alert(
            'error',
            'Ungültiges Dateiformat. Die Datei ist kein Private Key.',
            10000,
            true
          );
          return;
        }

        setZbpPrivateKey({
          file,
          validity: undefined,
          uploadDate: new Date()
        });
      }
    } catch (error) {
      alert(
        'error',
        'Fehler beim Lesen der Datei: Bitte stellen Sie sicher, dass die Datei im PEM-Format vorliegt.',
        10000,
        true
      );
      console.error('Error on reading file:', error);
    }
  };

  const handleSwitchButton = (checked: boolean) => {
    setIsSwitchButtonChecked(checked);
    setZbpCertificate(undefined);
    setZbpPrivateKey(undefined);
    if (!isSwitchButtonChecked) {
      alert(
        'warning',
        'Für die Kommunikation mit dem ZBP benötigen Sie ein aktuelles BundID-Postfach-Zertifikat sowie Ihren Privaten Schlüssel. Bitte laden Sie dafür beides nachträglich in den Einstellungen hoch.',
        10000,
        true
      );
    }
  };

  const handleFileDelete: (fileType: FileType) => void = (
    fileType: FileType
  ) => {
    if (fileType === 'zbpCertificate') {
      setZbpCertificate(undefined);
    } else {
      setZbpPrivateKey(undefined);
    }
  };

  return (
    <FjdLoadingOverlay loading={isValidating}>
      <div className="contentHeading">
        Speichern des BundID-Postfach-Zertifikats und des Private Keys
        <Tooltip
          data-testid="tooltip"
          tooltip="Für die Kommunikation müssen Sie ein aktuelles BundID-Postfach-Zertifikat und den Private Key hochladen. Wie Sie diese erhalten, erfahren Sie im Self-Service-Portal der BundID. Bitte beachten Sie, dass das Zertifikat nur begrenzt gültig ist."
        >
          <IconButton
            icon="information-filled"
            label="More information"
            variant="tertiary"
          />
        </Tooltip>
        <br />
        (Schritt 5 von 7)
      </div>
      <div className="descriptionText">
        Bitte laden Sie jeweils im vorgesehenem Feld das
        BundID-Postfach-Zertifikat und den Private Key der BundID hoch, um
        fortzufahren.
      </div>
      <Spacer size="s" />
      {(['zbpCertificate', 'zbpPrivateKey'] as const).map((fileType) => (
        <KeyPairUploadFields
          isFileUploadFieldDisabled={isSwitchButtonChecked}
          key={fileType}
          fileType={fileType}
          fileData={
            fileType === 'zbpCertificate' ? zbpCertificate : zbpPrivateKey
          }
          handleFileChange={handleFileChange}
          onFileDelete={(fileType: FileType) => handleFileDelete(fileType)}
        />
      ))}
      <Spacer size="3xl" />
      <Switch
        data-testid="switchButton"
        label="Ich möchte ohne Upload fortfahren. Nach Bedarf kann ich beides auch später unter Einstellungen hochladen."
        onChange={(checked: boolean) => {
          handleSwitchButton(!checked);
        }}
      />
    </FjdLoadingOverlay>
  );
}
