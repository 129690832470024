import {
  FjdVirtualizedTableCell,
  FjdVirtualizedTableRow,
  FjdTooltip,
  FjdAlert
} from 'fjd-react-components';
import { useTable } from '../../../hooks/useTable';
import { AntragObj } from '../../../models/Antrag/AntragsModel';
import { AntragsuebersichtTableCaseRow } from './AntragsuebersichtTableCaseRow';
import { Fragment } from 'react';
import { EmptyStateComponent } from '../../shared/EmptyStateComponent';

interface AntragsueberssichtTableCaseRowsProps {
  readonly antraege: Array<AntragObj>;
  readonly table: ReturnType<typeof useTable>;
  readonly clearFilter: any;
  readonly hasActiveFilters: boolean;
}

export function AntragsuebersichtTableCaseRows({
  antraege,
  table,
  clearFilter,
  hasActiveFilters
}: AntragsueberssichtTableCaseRowsProps) {
  return antraege ? (
    <Fragment>
      {antraege.length === 0 && (
        <FjdVirtualizedTableRow>
          {hasActiveFilters ? (
            <FjdVirtualizedTableCell level={2}>
              <FjdTooltip
                tooltip={
                  'Mit der aktuellen Suchkombination wurden leider keine passenden Anträge gefunden. Geben Sie einen neuen Suchbegriff ein oder wählen Sie Filter ab.'
                }
              >
                <FjdAlert
                  actionLabel={'Filter zurücksetzen'}
                  onActionClick={clearFilter}
                  appearance="muted"
                  closable={false}
                  intent="info"
                  title="Mit der aktuellen Suchkombination wurden leider keine passenden Anträge gefunden. Geben Sie einen neuen Suchbegriff ein oder wählen Sie Filter ab."
                />
              </FjdTooltip>
            </FjdVirtualizedTableCell>
          ) : (
            <FjdVirtualizedTableCell level={2}>
              <EmptyStateComponent
                msg={'Derzeit liegen keine neuen Anträge vor.'}
              />
            </FjdVirtualizedTableCell>
          )}
        </FjdVirtualizedTableRow>
      )}

      {antraege.map((antrag, index) => (
        <div key={antrag?.caseId}>
          <AntragsuebersichtTableCaseRow
            antrag={antrag}
            key={antrag.caseId}
            table={table}
          />
        </div>
      ))}
    </Fragment>
  ) : null;
}
