import { FjdButton, FjdButtonGroup } from 'fjd-react-components';
import { useEffect, useState } from 'react';
import { useFooter } from '../../../hooks/useFooter';
import FooterDto from '../../../models/Footer/FooterDto';
import { defaultFooterLinks } from '../../../utils/footer/defaultFooterLinks';
import useConfigurationStep, {
  ConfigurationStep
} from '../../../hooks/useConfigurationStep';

export function Footer() {
  const { footers, error } = useFooter();
  const [footer, setFooter] = useState<Array<FooterDto>>(defaultFooterLinks);
  const { configurationStep } = useConfigurationStep();

  useEffect(() => {
    if (!error && footers) {
      setFooter(footers);
    }
  }, [footers, error, configurationStep]);

  const footerAvailable: boolean = configurationStep
    ? configurationStep.configurationStep === ConfigurationStep.FINISH
    : false;

  if (!footerAvailable) {
    return <></>;
  }

  return (
    <FjdButtonGroup>
      {footer?.map((footerDto) => {
        return (
          <FjdButton
            id={`id_${footerDto.id}`}
            key={`key_${footerDto.id}`}
            appearance="link"
            href={footerDto.url}
            label={footerDto.title}
            size="s"
          />
        );
      })}
    </FjdButtonGroup>
  );
}
