import React from 'react';
import { VermerkDto } from '../../../../../models/Vermerk/VermerkDto';
import _moment from 'moment';
import { useVermerkVerlauf } from '../../../../../hooks/useVermerkVerlauf';
import {
  FjdButton,
  FjdSpinner,
  FjdFlexSpacer,
  FjdStack
} from 'fjd-react-components';
import classes from '../../../Schreibtisch.module.css';

export interface VermerkVerlaufProps {
  readonly vermerk: VermerkDto;
  readonly caseId: string;
  readonly hasBereitsAbgeschlossen: boolean;
  readonly lastVermerk: VermerkDto | undefined;
  readonly onEditVermerk: (vermerk: VermerkDto) => void;
  readonly onDeleteVermerk: (vermerk: VermerkDto) => void;
}

const VermerkVerlauf = ({
  vermerk,
  caseId,
  hasBereitsAbgeschlossen,
  onEditVermerk,
  onDeleteVermerk,
  lastVermerk
}: VermerkVerlaufProps) => {
  const vermerkId: string | undefined = vermerk.vermerkId;
  const { vermerkVerlaufData } = useVermerkVerlauf({
    caseId,
    vermerkId
  });
  const UpdatedOnTimestamp: string = _moment
    .utc(vermerk.updatedOn)
    .local()
    .format('DD.MM.YYYY [um] HH:mm');
  let updatedTimestampHint: JSX.Element;
  let firstUpdatedVermerkVerlauf: VermerkDto;
  let lastUpdatedVermerkVerlauf: VermerkDto | undefined;
  if (vermerkVerlaufData && vermerkVerlaufData.length > 1) {
    firstUpdatedVermerkVerlauf = vermerkVerlaufData[0];
    lastUpdatedVermerkVerlauf = vermerkVerlaufData.at(-1);
    const vermerkVerlauf =
      firstUpdatedVermerkVerlauf?.bearbeiterDto?.id ===
      lastUpdatedVermerkVerlauf?.bearbeiterDto?.id
        ? `zuletzt bearbeitet am: ${UpdatedOnTimestamp}`
        : `zuletzt bearbeitet am: ${UpdatedOnTimestamp} von ${vermerk.bearbeiterDto?.fullName}`;
    updatedTimestampHint = <div>{vermerkVerlauf}</div>;
  } else {
    updatedTimestampHint = <div>{UpdatedOnTimestamp}</div>;
    firstUpdatedVermerkVerlauf = vermerk;
    lastUpdatedVermerkVerlauf = vermerk;
  }

  if (vermerk.updatedOn !== firstUpdatedVermerkVerlauf?.updatedOn) {
    return <FjdSpinner />;
  }
  return (
    <FjdStack orientation={'vertical'}>
      <FjdStack orientation={'horizontal'}>
        <div
          id={`bearbeiter_fullname_${vermerk.vermerkId}`}
          style={{ fontWeight: 'bold' }}
        >
          {lastUpdatedVermerkVerlauf?.bearbeiterDto?.fullName}
        </div>
        <FjdFlexSpacer />
        <div>
          <FjdButton
            disabled={hasBereitsAbgeschlossen}
            id={`edit_vermerk_${vermerk.vermerkId}`}
            data-testid={`edit_vermerk_${vermerk.vermerkId}`}
            appearance="primary-link-invert"
            iconLeft="edit"
            size="s"
            onClick={() => onEditVermerk(vermerk)}
            hideLabel={true}
            label="Vermerk bearbeiten"
          />
          <FjdButton
            disabled={hasBereitsAbgeschlossen}
            id={`delete_vermerk_${vermerk.vermerkId}`}
            appearance="primary-link-invert"
            iconLeft="trashcan"
            size="s"
            onClick={() => onDeleteVermerk(vermerk)}
            label="Vermerk endgültig löschen"
            hideLabel={true}
          />
        </div>
      </FjdStack>
      <div
        id={`timestamp-vermerk_${vermerk.vermerkId}`}
        className={classes.vermerkTime}
      >
        {updatedTimestampHint}
      </div>
      <div
        id={`desc-vermerk_${vermerk.vermerkId}`}
        className={classes.vermerkText}
      >
        {vermerk?.description}
      </div>
      <div />
      {firstUpdatedVermerkVerlauf.vermerkId !== lastVermerk?.vermerkId ? (
        <div className={classes.border} />
      ) : null}
    </FjdStack>
  );
};
export default VermerkVerlauf;
