import React, { ReactElement } from 'react';
import { FjdStack } from 'fjd-react-components';
import { Alert } from '@efast_public/fjd-component-library';
import { Link } from 'react-router-dom';
import { RouteKey, routes } from '../../utils/router';

export default function EmptySchreibtischNoAntraege(): ReactElement {
  return (
    <React.Fragment>
      <FjdStack orientation="horizontal" spacing="xs">
        <Alert
          message="Derzeit liegen keine Anträge vor."
          variant="info"
          key="alert-keineAntraegeVorhanden"
          data-testid="alert-keineAntraegeVorhanden"
        >
          <Link
            key={'link-antragsuebersicht'}
            to={routes[RouteKey.ANTRAEGE].path}
          >
            Zur Antragsübersicht
          </Link>
        </Alert>
      </FjdStack>
    </React.Fragment>
  );
}
