import { Button, Modal, Paragraph } from '@efast_public/fjd-component-library';
import { ReactElement } from 'react';

interface KeyConfirmationModalProps {
  readonly onConfirmation: () => void;
  readonly isOpen: boolean;
  readonly onClosed: () => void;
}

function KeyConfirmationModal(props: KeyConfirmationModalProps): ReactElement {
  return (
    <Modal open={props.isOpen} onClose={props.onClosed}>
      <Modal.Header id="headerModal" data-testid="headerModal">
        Private Keys ersetzen?
      </Modal.Header>
      <Modal.Content>
        <Paragraph>
          Sind Sie sicher, dass Sie die bereits hochgeladenen Keys ersetzen
          wollen?
        </Paragraph>
      </Modal.Content>
      <Modal.Footer auto direction="rtl">
        <Button onClick={() => props.onClosed()}>Abbrechen</Button>
        <Button onClick={() => props.onConfirmation()}>Keys ersetzen</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default KeyConfirmationModal;
