import { ConfigurationStep } from '../../../hooks/useConfigurationStep';
import CheckList from './Checklist/CheckList';
import FitConnect from './Fitconnect/FitConnect';
import IConfigAssistantStep from './IConfigAssistantStep';
import Summary from './Summary/Summary';
import FooterLinks from './FooterLinks/FooterLinks';
import DataModel from './DataModel/DataModel';
import Destinations from './Destinations/Destinations';
import { ReactElement } from 'react';
import Logo from './Logo/Logo';
import BundId from './BundId/BundId';

interface ConfigAssistantStepsProps {
  readonly configurationStep: ConfigurationStep | undefined;
  readonly props: IConfigAssistantStep;
}

function ConfigAssistantSteps(
  props: ConfigAssistantStepsProps
): ReactElement | null {
  let content;
  switch (props.configurationStep) {
    case undefined: {
      return null;
    }
    case ConfigurationStep.CHECKLIST:
      content = <CheckList {...props.props} />;
      break;
    case ConfigurationStep.FITCONNECTCLIENT:
      content = <FitConnect {...props.props} />;
      break;
    case ConfigurationStep.DESTINATIONS:
      content = <Destinations {...props.props} />;
      break;
    case ConfigurationStep.DATAMODELS:
      content = <DataModel {...props.props} />;
      break;
    case ConfigurationStep.BUNDID:
      content = <BundId {...props.props} />;
      break;
    case ConfigurationStep.LOGO:
      content = <Logo {...props.props} />;
      break;
    case ConfigurationStep.FOOTER:
      content = <FooterLinks {...props.props} />;
      break;
    case ConfigurationStep.SUMMARY:
      content = <Summary {...props.props} />;
      break;
    case ConfigurationStep.FINISH:
      content = <h2>Finish</h2>;
  }

  return content;
}

export default ConfigAssistantSteps;
