import { useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { BEHError } from '../utils/Error/ErrorCode';
import { getApiServerUrl } from '../utils/mandant';
import axios, { AxiosProgressEvent, Method } from 'axios';

interface RequestOptions {
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  responseType?: XMLHttpRequestResponseType;
}

export function useApi() {
  const { keycloak } = useKeycloak();
  const apiUrl = getApiServerUrl();

  const makeRequestWithFullResponse = useCallback(
    async <T>(
      url: string,
      method: Method,
      baseUrl?: string,
      accept?: string,
      contentType?: string,
      data?: any,
      options?: RequestOptions
    ) => {
      const endpointBaseUrl: string =
        baseUrl || baseUrl === ''
          ? `${baseUrl}${encodeURI(url)}`
          : `${apiUrl}${encodeURI(url)}`;
      const headers = {
        Authorization: keycloak.token ? `Bearer ${keycloak.token}` : undefined,
        'Content-Type': contentType
          ? contentType
          : data
            ? data instanceof FormData
              ? 'multipart/form-data'
              : 'application/json'
            : undefined,
        Accept:
          accept !== undefined && accept !== null ? accept : 'application/json'
      };
      const response = await axios
        .request<T>({
          data,
          onUploadProgress: options?.onUploadProgress,
          headers: headers,
          method,
          responseType:
            options && options.responseType ? options.responseType : 'json',
          url: endpointBaseUrl
        })
        .catch((reason: any) => {
          throw new BEHError(
            reason.response?.data.type,
            reason.response?.data.message,
            reason.response?.data.status,
            reason.response?.data.detail,
            reason.response?.data?.traceId
          );
        });

      return response;
    },
    [keycloak.token, apiUrl]
  );

  const makeRequest = useCallback(
    async <T>(
      url: string,
      method: Method,
      baseUrl?: string,
      accept?: string,
      contentType?: string,
      data?: any,
      options?: RequestOptions
    ) => {
      return (
        await makeRequestWithFullResponse<T>(
          url,
          method,
          baseUrl,
          accept,
          contentType,
          data,
          options
        )
      ).data;
    },
    [makeRequestWithFullResponse]
  );

  return { makeRequest, makeRequestWithFullResponse };
}
