import {
  FjdStack,
  FjdFileInput,
  FjdButtonSelectGroup,
  FjdFilePreviewList
} from 'fjd-react-components';
import { FilePreviewListItem } from 'fjd-react-components/build/components/FilePreviewList/FilePreviewList';
import React, { ChangeEvent, Dispatch, useState } from 'react';
import {
  ActionType,
  FileUploadAction
} from '../../models/UploadFiles/FileUploadAction';
import { UploadFiles } from '../../models/UploadFiles/UploadFiles';
import { KommunikationAttachmentModel } from '../Schreibtisch/SchreibtischBody/Kommunikation/NachrichtSenden/KommunikationRequestModel';
import UploadFilePreviewModal from '../Schreibtisch/SchreibtischBody/Kommunikation/NachrichtSenden/UploadFilePreviewModal';

interface UploadsListProps {
  readonly applicationIsPositiveDecided?: boolean | undefined;
  readonly setApplicationIsPositiveDecided?: (
    isPositiveDecided: boolean | undefined
  ) => void;
  readonly uploadFiles: UploadFiles;
  readonly dispatch: Dispatch<FileUploadAction>;
  readonly hasUploadButtonEnabled: boolean;
  readonly uploadButtonTextZeroFilesUploaded?: string;
  readonly uploadButtonTextAtLeastOneFileUploaded?: string;
  readonly showPreview?: boolean;
  readonly hasBescheidSelect?: boolean;
  readonly hasIconsRightAligned?: boolean;
}

export default function UploadsList(props: UploadsListProps) {
  const [previewPayload, setPreviewPayload] = useState<undefined | string>(
    undefined
  );

  const toPreviewFunction = (
    uploadedFile: KommunikationAttachmentModel
  ): React.MouseEventHandler | null | undefined => {
    if (!props.showPreview) {
      return undefined;
    }

    if (uploadedFile.mimeType === 'application/pdf') {
      return () => {
        setPreviewPayload(uploadedFile.content);
      };
    }

    return null;
  };

  const toRemoveFileFunction = (index: number): React.MouseEventHandler => {
    return () => {
      props.dispatch({ type: ActionType.REMOVE_ADDITIONAL_FILE, index: index });
    };
  };

  const toRemoveBescheidFunction = (): React.MouseEventHandler => {
    return () => {
      props.dispatch({ type: ActionType.REMOVE_BESCHEID });
    };
  };

  const truncate = (str: string, n: number): string => {
    return str.length > n ? `${str.slice(0, n - 1)}...` : str;
  };

  const handleClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ): void => {
    if (props.setApplicationIsPositiveDecided) {
      if (event.currentTarget.hasAttribute('checked')) {
        props.setApplicationIsPositiveDecided(undefined);
      } else {
        props.setApplicationIsPositiveDecided(
          event.currentTarget.value === 'positive'
        );
      }
    }
  };

  const toRightSection = (): JSX.Element => {
    return (
      <div style={{ paddingLeft: '0.5rem' }}>
        <FjdButtonSelectGroup
          id="btn-select-group-upload-list-bescheid"
          deselectableOptions={true}
          onClick={handleClick}
          options={[
            {
              text: '✓ Positiv',
              value: 'positive',
              selected: props.applicationIsPositiveDecided ?? false,
              color: 'success'
            },
            {
              text: '✗ Negativ',
              value: 'negative',
              selected:
                props.applicationIsPositiveDecided !== undefined &&
                !props.applicationIsPositiveDecided,
              color: 'error'
            }
          ]}
        />
      </div>
    );
  };

  const toFilePreviewListItem = (
    file: KommunikationAttachmentModel,
    withBescheid?: boolean,
    index?: number
  ): FilePreviewListItem => {
    return {
      title: truncate(
        file.fileName,
        props.hasBescheidSelect ? (!index ? 35 : 55) : 55
      ),
      titleTooltip: file.fileName,
      onDelete:
        index !== undefined
          ? toRemoveFileFunction(index)
          : toRemoveBescheidFunction(),
      onView: toPreviewFunction(file),
      icon: 'none',
      rightSection: withBescheid && !index ? toRightSection() : undefined,
      backgroundColor: '#fafafa',
      iconsLeftAligned: !props.hasIconsRightAligned ?? true
    };
  };

  const additionalFiles: Array<FilePreviewListItem> =
    props.uploadFiles.additionalFiles.map(
      (file: KommunikationAttachmentModel, index: number) => {
        return toFilePreviewListItem(file, false, index);
      }
    );

  const bescheid: FilePreviewListItem | undefined =
    props.uploadFiles.bescheidUpload !== undefined
      ? toFilePreviewListItem(
          props.uploadFiles.bescheidUpload,
          props.hasBescheidSelect
        )
      : props.uploadFiles.bescheidUpload;

  return (
    <FjdStack orientation="vertical" spacing={'l'}>
      <UploadFilePreviewModal
        payload={previewPayload}
        onClose={() => setPreviewPayload(undefined)}
        isOpen={previewPayload ? true : false}
      />
      <FjdFilePreviewList items={bescheid ? [bescheid] : []} />
      {props.uploadFiles.additionalFiles.length > 0 && (
        <div
          style={{
            color: '#333333',
            fontWeight: 700,
            fontSize: '0.97rem'
          }}
        >
          {props.uploadFiles.bescheidUpload ? 'Weitere Dokumente' : ''}
        </div>
      )}
      <FjdFilePreviewList items={additionalFiles} />
      {props.hasUploadButtonEnabled && (
        <FjdFileInput
          appearance={'primary-link'}
          icon={'export'}
          id="upload_documents_button"
          label={
            props.uploadFiles.bescheidUpload
              ? props.uploadButtonTextAtLeastOneFileUploaded
                ? props.uploadButtonTextAtLeastOneFileUploaded
                : ''
              : props.uploadButtonTextZeroFilesUploaded
                ? props.uploadButtonTextZeroFilesUploaded
                : ''
          }
          multiple={true}
          name="upload_more_documents_button"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            props.dispatch({
              type: ActionType.ADD_ADDITIONAL_FILES,
              files: event.target.files
            });
          }}
        />
      )}
    </FjdStack>
  );
}
