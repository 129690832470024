import {
  FjdStack,
  FjdContentTabBar,
  FjdContentTabBarItem
} from 'fjd-react-components';
import { useContext } from 'react';
import Antragsdetails from './SchreibtischBody/Antragsdetails/Antragsdetails';
import SchreibtischHeader from './SchreibstischHeader/SchreibtischHeader';
import classes from './Schreibtisch.module.css';
import EmptySchreibtischNoTabs from './EmptySchreibtischNoTabs';
import useTabs from '../../hooks/useTabs';
import { ErrorComponentBehoerdenServer } from '../shared/Error/ErrorComponent';
import Antragsdokumente from './SchreibtischBody/Dokumente/Antragsdokumente';
import Kommunikation from './SchreibtischBody/Kommunikation/Kommunikation';
import { caseDetailsDataContext } from '../../context/Context';
import { Bearbeitungsstand } from '../../models/Status/Bearbeitungsstand';
import ZuweisungHeader from './SchreibstischHeader/Zuweisung/ZuweisungHeader';
import { useStorageState } from 'react-storage-hooks';
import EmptySchreibtischNoAntraege from './EmptySchreibtischNoAntraege';
import { useCaseCount } from '../../hooks/useCaseCount';

export enum ContentTab {
  ANTRAGSDETAILS = 'antragsdetails',
  DOKUMENTE = 'dokumente',
  KOMMUNIKATION = 'kommunikation'
}

export const activeContentTabLocalStorageKey = 'beh-activeContentTab-state-';

const Schreibtisch = () => {
  const { empty } = useTabs();
  const { caseCount } = useCaseCount();
  const caseDetails = useContext(caseDetailsDataContext);

  const [storageActiveContentTabState, setStorageActiveContentTabState] =
    useStorageState(
      localStorage,
      `${activeContentTabLocalStorageKey}${caseDetails.data.caseId}`
    );

  if (caseDetails?.data?.hasError) {
    return (
      <ErrorComponentBehoerdenServer errorCode={caseDetails?.data?.errorCode} />
    );
  }

  if (empty && caseCount === 0) {
    return <EmptySchreibtischNoAntraege />;
  }
  if (empty && caseCount !== 0) {
    return <EmptySchreibtischNoTabs />;
  }

  function isContentTabBarItemActive(contentTab: ContentTab): boolean {
    return storageActiveContentTabState
      ? storageActiveContentTabState === contentTab
      : contentTab === ContentTab.ANTRAGSDETAILS;
  }

  return (
    <FjdStack spacing={'xl'}>
      <SchreibtischHeader
        setContentTabState={setStorageActiveContentTabState}
      />
      {caseDetails?.data?.bearbeitungsstand !==
        Bearbeitungsstand.ABGESCHLOSSEN && <ZuweisungHeader />}
      <div className={classes.antragsheader}>
        <FjdContentTabBar>
          <FjdContentTabBarItem
            active={isContentTabBarItemActive(ContentTab.ANTRAGSDETAILS)}
            label="Antragsdetails"
            onClick={() =>
              setStorageActiveContentTabState(ContentTab.ANTRAGSDETAILS)
            }
            icon={'document'}
          />

          <FjdContentTabBarItem
            active={isContentTabBarItemActive(ContentTab.DOKUMENTE)}
            label="Dokumente"
            onClick={() =>
              setStorageActiveContentTabState(ContentTab.DOKUMENTE)
            }
            icon={'link'}
          />
          <FjdContentTabBarItem
            label="Kommunikation"
            active={isContentTabBarItemActive(ContentTab.KOMMUNIKATION)}
            onClick={() =>
              setStorageActiveContentTabState(ContentTab.KOMMUNIKATION)
            }
            icon={'email'}
          />
        </FjdContentTabBar>
      </div>
      {isContentTabBarItemActive(ContentTab.ANTRAGSDETAILS) && (
        <Antragsdetails />
      )}
      {isContentTabBarItemActive(ContentTab.DOKUMENTE) && <Antragsdokumente />}
      {isContentTabBarItemActive(ContentTab.KOMMUNIKATION) && (
        <Kommunikation setContentTabState={setStorageActiveContentTabState} />
      )}
    </FjdStack>
  );
};

export default Schreibtisch;
