import { FjdAlert, FjdStack } from 'fjd-react-components';
import {
  NachrichtType,
  NachrichtModel
} from '../../../../../models/Nachrichten/NachrichtModel';

interface NachrichtBodyProps {
  readonly nachricht: NachrichtModel;
  readonly onLinkClicked: () => void;
}

export function NachrichtBody({
  nachricht,
  onLinkClicked
}: NachrichtBodyProps) {
  const isPostalischBeschieden = (nachrichtType: NachrichtType): boolean => {
    return nachrichtType === NachrichtType.BESCHEID_POSTALISCH;
  };
  if (!nachricht.isSubmission) {
    return (
      <FjdStack orientation="vertical">
        <div style={{ fontWeight: 600, fontSize: '12px' }}>Nachrichtentext</div>
        {!nachricht.isHtml ? (
          <div
            dangerouslySetInnerHTML={{
              __html: nachricht.nachrichtBody
            }}
          />
        ) : (
          <div>{nachricht.nachrichtBody}</div>
        )}
      </FjdStack>
    );
  } else {
    return isPostalischBeschieden(nachricht.nachrichtType) ? (
      <FjdAlert
        testId="postalischAlert"
        appearance="muted"
        closable={false}
        intent="info"
        title="Der Bescheid wurde postalisch versandt."
      />
    ) : (
      <FjdAlert
        testId="linkToDetails"
        actionLabel={'Antragsdetails öffnen'}
        appearance="muted"
        closable={false}
        intent="info"
        title="Genauere Angaben zu diesem Antrag finden Sie auf der Antragsdetailansicht."
        onActionClick={onLinkClicked}
      />
    );
  }
}

export default NachrichtBody;
