import { FjdHeading, FjdSectionHeader, FjdStack } from 'fjd-react-components';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Antragsuebersicht } from './Antragsuebersicht';

export function AntragsuebersichtPage() {
  return (
    <React.Fragment>
      <Helmet>
        <title>{'Behoerdenclient'}</title>
      </Helmet>

      <FjdStack spacing="xl">
        <FjdSectionHeader
          align="center"
          heading={
            <FjdHeading
              id={'antragliste'}
              level={1}
              text={'Antragsübersicht'}
            />
          }
        />
        <Antragsuebersicht />
      </FjdStack>
    </React.Fragment>
  );
}

export default AntragsuebersichtPage;
