import { useApi } from './useApi';
import { useCallback, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import useSWR, { KeyedMutator } from 'swr';
import { BundIdKeyPairUploadDto, IBundIdGetDto } from '../models/ZBP/BundId';

const url: string = `/api/v1/zbp`;

interface IBundIdGet {
  data: IBundIdGetDto | undefined;
  error: AxiosError | undefined;
  isValidating: boolean;
  mutateBundIdKeyPair: KeyedMutator<IBundIdGetDto>;
}

export function useBundIdGet(): IBundIdGet {
  let {
    data,
    error,
    mutate: mutateBundIdKeyPair,
    isValidating
  } = useSWR<IBundIdGetDto>(url, {
    suspense: false
  });

  // if case is a workaround like in useLogo.ts, because mutate() is not working as should be aspected
  if (error) {
    data = undefined;
  }

  return { data, mutateBundIdKeyPair, isValidating, error } as IBundIdGet;
}

export function useBundIdPost() {
  const [errorResponse, setErrorResponse] = useState<any>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const { makeRequest } = useApi();

  const uploadBundIdKeyPair = useCallback(
    async (bundIdKeyPair: BundIdKeyPairUploadDto) => {
      setIsUploading(true);
      try {
        await makeRequest<AxiosResponse>(
          url,
          'POST',
          undefined,
          undefined,
          'application/json',
          bundIdKeyPair
        );
      } catch (error) {
        setErrorResponse(error as AxiosResponse);
        throw error;
      } finally {
        setIsUploading(false);
      }
    },
    [makeRequest]
  );

  return { errorResponse, uploadBundIdKeyPair, isUploading };
}

export function useBundIdDelete() {
  const [errorResponse, setErrorResponse] = useState<any>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { makeRequest } = useApi();

  const deleteBundIdKeyPair =
    useCallback(async (): Promise<AxiosResponse | void> => {
      setIsDeleting(true);
      try {
        return await makeRequest<AxiosResponse>(
          url,
          'DELETE',
          undefined,
          undefined,
          undefined,
          undefined
        );
      } catch (error) {
        setErrorResponse(error as AxiosResponse);
        setIsDeleting(false);
        throw error;
      } finally {
        setIsDeleting(false);
      }
    }, [makeRequest]);

  return { errorResponse, deleteBundIdKeyPair, isDeleting };
}
