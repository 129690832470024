export enum ActionType {
  ADD_ADDITIONAL_FILES,
  REMOVE_ADDITIONAL_FILE,
  REMOVE_ALL_ADDITIONAL_FILES,
  ADD_BESCHEID,
  REMOVE_BESCHEID,
  REMOVE_ALL_FILES
}

export interface FileUploadAction {
  type: ActionType;
  files?: FileList | Array<never> | null;
  index?: number;
  onConfirmation?: () => void;
}
