import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { AntragLayout } from '../pages/Antragsuebersicht/AntragLayout/AntragLayout';
import { SchreibtischLayout } from '../pages/Schreibtisch/SchreibtischLayout/SchreibtischLayout';
import { SettingsLayout } from '../pages/Settings/SettingsLayout/SettingsLayout';
import { ConfigAssistantLayout } from '../pages/ConfigAssistant/ConfigAssistantLayout/ConfigAssistantLayout';

export enum RouteKey {
  HOME,
  ANTRAEGE,
  SCHREIBTISCH,
  SCHREIBTISCH_CASE,
  EINSTELLUNGEN,
  CONFIGASSISTANT
}

export interface Route {
  disabled?: boolean;
  element: ReactElement | null;
  path: string;
}

export const routes: {
  [key in RouteKey]: Route;
} = {
  [RouteKey.HOME]: {
    element: <Navigate to="/antraege" />,
    path: '/'
  },
  [RouteKey.ANTRAEGE]: {
    element: <AntragLayout />,
    path: '/antraege/'
  },
  [RouteKey.SCHREIBTISCH]: {
    element: <SchreibtischLayout />,
    path: '/schreibtisch/'
  },
  [RouteKey.SCHREIBTISCH_CASE]: {
    disabled: true,
    element: <SchreibtischLayout />,
    path: '/schreibtisch/:caseId/'
  },
  [RouteKey.EINSTELLUNGEN]: {
    disabled: true,
    element: <SettingsLayout />,
    path: '/einstellungen/'
  },
  [RouteKey.CONFIGASSISTANT]: {
    element: <ConfigAssistantLayout />,
    path: '/config/'
  }
};

export function getCasePath(caseId: string) {
  return `/schreibtisch/${caseId}`;
}
