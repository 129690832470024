import { FjdModal, FjdSpinner } from 'fjd-react-components';
import { useAttachment } from '../../hooks/useAttachment';
import { ErrorComponentBehoerdenServer } from './Error/ErrorComponent';
import { FjdModalProps } from 'fjd-react-components/build/components/Modal/Modal';

interface AttachmentModalProps {
  readonly onClose: () => void;
  readonly attachmentId: string | undefined;
  readonly submissionId: string | undefined;
  readonly caseId: string;
  readonly isOpen: boolean;
}

export function AttachmentPreviewModal({
  onClose,
  attachmentId,
  submissionId,
  caseId,
  isOpen
}: AttachmentModalProps) {
  const { attachment, isValidating, error } = useAttachment({
    caseId,
    submissionId,
    attachmentId
  });

  const payload = attachment?.pdfPreviewPayload
    ? attachment?.pdfPreviewPayload
    : '';

  const modalPropertiesBase: Partial<FjdModalProps> = {
    appElement: document.querySelector('.fjd-base-layout') as HTMLElement,
    id: 'attachment-modal',
    onClose: onClose,
    closeOnBackdropClick: false,
    testId: 'attachment-modal',
    open: isOpen
  };

  const modalPropertiesError: Partial<FjdModalProps> = {
    heading: 'Vorschau',
    secondaryButtonDisabled: true,
    primaryButtonLabel: 'Schließen',
    onPrimaryButtonClick: () => onClose()
  };

  const modalPropertiesNoError: Partial<FjdModalProps> = {
    heading: '',
    primaryButtonDisabled: true,
    secondaryButtonDisabled: true,
    width: 'fullscreen'
  };

  const modalProperties: FjdModalProps = error
    ? ({ ...modalPropertiesBase, ...modalPropertiesError } as FjdModalProps)
    : ({ ...modalPropertiesBase, ...modalPropertiesNoError } as FjdModalProps);

  return (
    <FjdModal {...modalProperties}>
      {error ? (
        <ErrorComponentBehoerdenServer errorCode={error?.traceId} />
      ) : isValidating ? (
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <FjdSpinner size="l" />
        </div>
      ) : (
        <object
          aria-label="Antrags-PDF"
          data={`data:application/pdf;base64, ${encodeURI(payload)}`}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      )}
    </FjdModal>
  );
}

export default AttachmentPreviewModal;
