import { FjdAlert, FjdTooltip } from 'fjd-react-components';

interface emptyStateComponentProps {
  readonly msg: string;
}

export function EmptyStateComponent({
  msg
}: emptyStateComponentProps): JSX.Element {
  return (
    <FjdTooltip tooltip={msg}>
      <FjdAlert
        testId={'emptyState'}
        appearance="muted"
        closable={false}
        intent="info"
        title={msg}
      />
    </FjdTooltip>
  );
}
