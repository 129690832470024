import { AxiosResponse } from 'axios';
import { useState } from 'react';
import useAlerts from './useAlerts';
import { useApi } from './useApi';
import useIdByLocation from './useIdByLocation';
import { BEHError, useAlertErrorMsg } from '../utils/Error/ErrorCode';

export function useMessageRead() {
  const [loadingSend, setLoadingSend] = useState(false);
  const { makeRequest } = useApi();
  const caseId = useIdByLocation();
  const { alert } = useAlerts();

  const markMessageAsRead = async (submissionId: string, userId?: string) => {
    setLoadingSend(true);

    try {
      await makeRequest<AxiosResponse>(
        `/api/v1/cases/${caseId}/submissions/${submissionId}/users/${userId}`,
        'PUT',
        undefined,
        undefined,
        'application/json'
      );
      setLoadingSend(false);
    } catch (e) {
      const msgError = e as BEHError;
      alert(
        'error',
        useAlertErrorMsg.concat(` (${msgError?.traceId})`),
        10000,
        true
      );
      setLoadingSend(false);
    }
  };

  return { loadingSend, markMessageAsRead };
}
