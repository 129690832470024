import { useKeycloak } from '@react-keycloak/web';
import { useContext } from 'react';
import {
  FjdButton,
  FjdHeader,
  FjdMenu,
  FjdMenuItem,
  FjdStack,
  FjdFlyout
} from 'fjd-react-components';

import { useProfile } from '../../../hooks/useProfile';
import { errorContext } from '../../../context/Context';
import { RouterLink } from '../../../utils/Router/RouterLink';
import { useNavigate } from 'react-router-dom';
import useConfigurationStep, {
  ConfigurationStep
} from '../../../hooks/useConfigurationStep';
import useLogo, {
  createDataUrlFromLogoVO,
  IUseLogo
} from '../../../hooks/useLogo';
import efastBehLogo from './efastBehoerdenClientLogo.svg';

import style from './Header.module.css';

export function Header() {
  const { keycloak } = useKeycloak();
  const { profile } = useProfile(); // Error handling does not look good, all known error cases are catched via route
  const { behError } = useContext(errorContext);
  const { configurationStep } = useConfigurationStep();

  const { logoVO }: IUseLogo = useLogo();

  const hasError = behError.status !== undefined;
  const hasGroupAdministration =
    keycloak.tokenParsed?.['groups'].includes('/Administration');

  const navigate = useNavigate();

  const logout = () => {
    keycloak?.logout({
      redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT
    });
  };

  const isInConfigAssistantMode: boolean = configurationStep
    ? configurationStep.configurationStep !== ConfigurationStep.FINISH
    : false;

  const showSettings =
    !hasError &&
    keycloak.authenticated &&
    hasGroupAdministration &&
    !isInConfigAssistantMode;

  return (
    <FjdHeader
      logo={
        <>
          <img
            src={efastBehLogo}
            alt="Logo Behörden-Client"
            className={`${style.headerLogos} ${style.behLogo}`}
          />
          {logoVO && (
            <img
              src={createDataUrlFromLogoVO(logoVO)}
              className={`${style.headerLogos} ${style.clientLogo}`}
              alt="Logo Mandant"
            />
          )}
        </>
      }
      menu={
        <FjdMenu>
          {showSettings && (
            <FjdMenuItem
              appearance="light"
              icon="settings"
              label={'Einstellungen'}
              onClick={() => navigate('/einstellungen')}
            />
          )}
          {keycloak.authenticated && (
            <FjdMenuItem
              appearance="light"
              href={process.env.REACT_APP_DOCS}
              icon="help-outline"
              label={'Abmelden'}
              onClick={logout}
            />
          )}
        </FjdMenu>
      }
      navigation={
        <FjdStack orientation="horizontal">
          {showSettings && (
            <RouterLink
              component={() => (
                <FjdButton
                  appearance={'link'}
                  iconLeft="settings"
                  label={'Einstellungen'}
                  id={'settings-link'}
                  onClick={() => navigate('/einstellungen')}
                />
              )}
              to={'/einstellungen'}
            />
          )}
          {keycloak.authenticated && (
            <FjdFlyout
              flyout={
                <FjdMenu>
                  <FjdMenuItem
                    appearance="light"
                    data-testid="buttonLogout"
                    icon="logout"
                    label={'Abmelden'}
                    onClick={logout}
                  />
                </FjdMenu>
              }
              offset={6}
              placement="bottom-end"
            >
              {keycloak.authenticated && (
                <FjdButton
                  appearance="primary-link"
                  data-testid="user-button"
                  iconLeft="user-avatar"
                  label={`${profile?.firstName ?? '?'} ${
                    profile?.lastName ?? '?'
                  } `}
                />
              )}
            </FjdFlyout>
          )}
        </FjdStack>
      }
    />
  );
}
