import { FjdBaseLayout } from 'fjd-react-components';
import { Footer } from '../../shared/Footer/Footer';
import { Header } from '../../shared/Header/Header';

import ConfigAssistant from '../ConfigAssistant';

export function ConfigAssistantLayout() {
  return (
    <>
      <FjdBaseLayout
        footer={<Footer />}
        header={<Header />}
        fullWidthContent
        overflow={true}
      >
        <ConfigAssistant />
      </FjdBaseLayout>
    </>
  );
}
