import { FjdBaseLayout } from 'fjd-react-components';
import { Header } from '../../shared/Header/Header';
import AntragsuebersichtPage from '../AntragsuebersichtPage';
import { MainNavigation } from '../../shared/MainNavigation/MainNavigation';
import ErrorProvider from '../../../context/ErrorContext/ErrorContext';
import { Footer } from '../../shared/Footer/Footer';

export function AntragLayout() {
  const content = <AntragsuebersichtPage />;

  return (
    <>
      <ErrorProvider>
        <FjdBaseLayout
          footer={<Footer />}
          header={<Header />}
          mainNavigation={<MainNavigation />}
          fullWidthContent
        >
          {content}
        </FjdBaseLayout>
      </ErrorProvider>
    </>
  );
}
