import { FjdTabBarTab } from 'fjd-react-components/build/components/TabBar/TabBar';
import { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStorageState } from 'react-storage-hooks';
import { atom, useRecoilState } from 'recoil';
import { RouteKey, routes } from '../utils/router';

import { useElementNavigation } from './useElementNavigation';

import { AntragObj } from '../models/Antrag/AntragsModel';
import { CaseDetailsModel } from '../models/Case/CaseDetailsModel';
import { tabStatusConfig } from '../pages/Antragsuebersicht/AntragsuebersichtTabelle/AntragsuebersichtCustomizedCell';
import { caseDetailsDataContext } from '../context/Context';
import { activeContentTabLocalStorageKey } from '../pages/Schreibtisch/Schreibtisch';

export const tabsAtom = atom<TabsState>({
  default: { tabs: [] },
  key: 'tabs'
});

export const tabsStateLocalStorageKey = 'beh-tabs-state';

export interface TabsState {
  tabs: Array<FjdTabBarTab>;
}

function useTabs() {
  const { navigateToElement } = useElementNavigation();
  const navigate = useNavigate();

  const caseDetails = useContext(caseDetailsDataContext);

  const [, setStorageTabsState] = useStorageState<TabsState>(
    localStorage,
    tabsStateLocalStorageKey
  );

  const [tabsState, setTabsState] = useRecoilState<TabsState>(tabsAtom);

  const activeTab = tabsState?.tabs.find((tab) => tab.active);

  const empty =
    !tabsState?.tabs ||
    tabsState?.tabs.length === 0 ||
    (tabsState?.tabs.length === 1 && tabsState?.tabs[0].id === 'empty');

  const reloadTabContent = useCallback(
    (element: CaseDetailsModel | AntragObj) => {
      setTabsState((tabsState) => {
        if (tabsState?.tabs.some((tab) => tab.id === element.caseId)) {
          return {
            ...tabsState,
            tabs: tabsState.tabs.map((tab) => {
              if (tab.id === element.caseId) {
                const type: 'case' = 'case';
                const statusConfig = tabStatusConfig(
                  element.bearbeitungsstand,
                  element.bescheidstatus
                );

                const concatWith = element.auftragsnummer ? ' • ' : '';
                const tabTitle =
                  element.auftragsnummer + concatWith + element.name;
                const newTab: FjdTabBarTab = {
                  active: true,
                  closable: true,
                  data: { element, type },
                  id: element.caseId,
                  label: tabTitle,
                  withStatus: statusConfig?.withStatus,
                  statusIntent: statusConfig?.statusIntent,
                  statusLabel: statusConfig?.statusLabel,
                  statusIcon: statusConfig?.statusIcon,
                  iconAppearance: statusConfig?.iconAppearance,
                  iconSize: statusConfig?.iconSize,
                  icon: statusConfig?.glyph ? statusConfig.glyph : undefined,
                  pinnable: false,
                  pinned: false
                };

                return newTab;
              } else {
                return {
                  ...tab,
                  active: false
                };
              }
            })
          };
        }
        return tabsState;
      });
    },
    [setTabsState]
  );

  const closeAllTabs = () => {
    setTabsState((tabsState) => ({ ...tabsState, tabs: [] }));
    for (const tab of tabsState.tabs) {
      localStorage.removeItem(`${activeContentTabLocalStorageKey}${tab.id}`);
    }

    navigate(`${routes[RouteKey.ANTRAEGE].path}`);
  };

  const closeInactiveTabs = () => {
    setTabsState((tabsState) => ({
      ...tabsState,
      tabs: tabsState.tabs.filter((tab) => tab.active)
    }));
    for (const tab of tabsState.tabs) {
      if (!tab.active) {
        localStorage.removeItem(`${activeContentTabLocalStorageKey}${tab.id}`);
      }
    }
  };

  const closeTab = (id: string) => {
    const closingTabIsActive = activeTab?.id === id;
    const updatedTabsState = {
      ...tabsState,
      tabs: tabsState.tabs.filter((tab) => tab.id !== id)
    };
    setTabsState(updatedTabsState);
    localStorage.removeItem(
      `${activeContentTabLocalStorageKey}${caseDetails.data.caseId}`
    );

    if (closingTabIsActive) {
      const lastTab = updatedTabsState.tabs[updatedTabsState.tabs.length - 1];

      if (lastTab && lastTab.data?.element) {
        const element = lastTab.data.element;
        navigateToElement(element);
      } else {
        navigate(`${routes[RouteKey.ANTRAEGE].path}`);
      }
    }
  };

  const openTab = useCallback(
    (element: CaseDetailsModel | AntragObj) => {
      if (element && element.caseId !== '') {
        setTabsState((tabsState) => {
          if (tabsState?.tabs.some((tab) => tab.id === element.caseId)) {
            return {
              ...tabsState,
              tabs: tabsState.tabs.map((tab) =>
                tab.id === element.caseId
                  ? {
                      ...tab,
                      active: true
                    }
                  : {
                      ...tab,
                      active: false
                    }
              )
            };
          } else {
            const type: 'case' = 'case';
            const statusConfig = tabStatusConfig(
              caseDetails?.data?.bearbeitungsstand,
              caseDetails?.data?.bescheidstatus
            );

            const tab: FjdTabBarTab = {
              active: true,
              closable: true,
              data: { element, type },
              id: element.caseId,
              label: `${element.auftragsnummer} • ${element.name}`,
              withStatus: statusConfig?.withStatus,
              statusIntent: statusConfig?.statusIntent,
              statusIcon: statusConfig?.statusIcon,
              statusLabel: statusConfig?.statusLabel,
              iconAppearance: statusConfig?.iconAppearance,
              iconSize: statusConfig?.iconSize,
              icon: statusConfig?.glyph ? statusConfig.glyph : undefined,
              pinnable: false,
              pinned: false
            };

            return {
              ...tabsState,
              tabs: [
                ...(tabsState.tabs || [])
                  .filter(
                    (tab) => tab.id !== element.caseId && tab.id !== 'empty'
                  )
                  .map((tab) => ({ ...tab, active: false })),
                tab
              ]
            };
          }
        });
      }
    },
    [setTabsState, caseDetails]
  );

  const toggleTabPin = (elementId: string) => {
    setTabsState((tabsState) => ({
      ...tabsState,
      tabs: [
        ...tabsState.tabs.map((tab) =>
          tab.id === elementId ? { ...tab, pinned: !tab.pinned } : tab
        )
      ]
    }));
  };

  const setTabs = (tabs: Array<FjdTabBarTab>) => {
    setTabsState((tabsState) => ({ ...tabsState, tabs }));
  };

  useEffect(() => {
    if (caseDetails) {
      reloadTabContent(caseDetails.data);
    }
  }, [caseDetails, reloadTabContent]);

  useEffect(() => {
    if (tabsState?.tabs.length === 0) {
      setTabsState((tabsState) => ({
        ...tabsState,
        tabs: [
          {
            active: true,
            icon: 'help-outline',
            id: 'empty',
            label: `Los geht's`
          }
        ]
      }));
    }
  }, [setTabsState, tabsState]);

  useEffect(() => {
    setStorageTabsState(tabsState);
  }, [setStorageTabsState, tabsState]);

  useEffect(() => {
    if (!tabsState) {
      setTabsState((tabsState) => ({
        ...tabsState,
        tabs: []
      }));
    }
  }, [setTabsState, tabsState, caseDetails]);

  return {
    activeTab,
    closeAllTabs,
    closeInactiveTabs,
    closeTab,
    empty,
    openTab,
    setTabs,
    tabs: (tabsState?.tabs || []).map((t) => ({ ...t })),
    toggleTabPin,
    tabsState
  };
}

export default useTabs;
