import { FjdAlert, FjdAlertContainer } from 'fjd-react-components';
import { FjdAlertIntent } from 'fjd-react-components/build/components/Alert/Alert';
import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

export interface Alert {
  id: string;
  intent: FjdAlertIntent;
  timeout?: number;
  title: string;
  closable: boolean;
}

const alertsState = atom<Array<Alert>>({
  default: [],
  key: 'alerts'
});

function useAlerts() {
  const [alerts, setAlerts] = useRecoilState(alertsState);
  const closeAlert = useCallback(
    (id: string) => {
      setAlerts((alerts) => alerts.filter((alert) => alert.id !== id));
    },
    [setAlerts]
  );

  const alert = useCallback(
    (
      intent: FjdAlertIntent,
      title: string,
      duration = 10000,
      closable = false
    ) => {
      const id = `${Math.round(Math.random() * 60000)}`;
      setAlerts((alerts) => [
        ...alerts,
        {
          id,
          intent,
          timeout: window.setTimeout(() => {
            setAlerts((currentAlerts) =>
              currentAlerts.filter((a) => a.id !== id)
            );
          }, duration),
          closable,
          title
        }
      ]);
    },
    [setAlerts]
  );

  const renderAlertContainer = () => {
    return (
      <FjdAlertContainer>
        {[...alerts].reverse().map((alert) => (
          <FjdAlert
            closable={alert.closable}
            intent={alert.intent}
            key={alert.id}
            title={alert.title}
            appearance={'muted'}
            onClose={() => closeAlert(alert.id)}
          />
        ))}
      </FjdAlertContainer>
    );
  };

  return { alert, alerts, renderAlertContainer, closeAlert };
}

export default useAlerts;
