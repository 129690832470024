import { AttachmentModel } from '../Case/CaseDetailsModel';

export enum NachrichtType {
  BESCHEID_DIGITAL,
  BESCHEID_POSTALISCH,
  NACHRICHT_DIGITAL
}
export interface NachrichtModel {
  submissionId: string;
  betreff: string;
  isBescheid: boolean;
  nachrichtType: NachrichtType;
  absenderName: string;
  createdOn: Date;
  hasAttachments: boolean;
  attachment: Array<AttachmentModel>;
  isVonBehoerde: boolean;
  nachrichtBody: string;
  isHtml: boolean;
  isSubmission: boolean;
  organisationUnitName: string;
  isSelected: boolean;
  hasBeenRead: boolean;
}
