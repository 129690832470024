import useSWR from 'swr';
import { ResultsObject } from '../models/ResultsObject/ResultsObject';

import { PaginationStyle, usePagination } from './usePagination';

export interface FilterParameter {
  property: String;
  operator: String;
  value: String | undefined;
}

interface UsePaginatedSwrConfig {
  key: string;
  filter?: Array<FilterParameter>;
  paginationStyle?: PaginationStyle;
  sortByOrder?: 'ASC' | 'DESC';
  sortByProperty?: string;
  initPageSize?: number;
  suspense?: boolean;
}

export function usePaginatedSwr<ResourceType>({
  key,
  filter = [],
  paginationStyle = 'default',
  sortByOrder,
  sortByProperty,
  initPageSize = 25,
  suspense = false
}: UsePaginatedSwrConfig) {
  const filterQueryString = filter
    .filter((f) => !!f.value)
    .reduce(
      (queryString, filter, index, array) =>
        `${index === 0 ? '&filter=' : ''}${queryString}${filter.property}_${
          filter.operator
        }_${filter.value}${index !== array.length - 1 ? ',' : ''}`,
      ''
    );

  const sortQueryString = sortByProperty
    ? `&orderBy=${sortByOrder === 'DESC' ? '-' : ''}${sortByProperty}`
    : '';

  const queryString = filterQueryString + sortQueryString;

  let { data: results, mutate } = useSWR<
    ResultsObject<ResourceType> | Array<ResourceType>
  >(`${key}?page=0&size=${initPageSize}${queryString}`, { suspense });

  const totalItems =
    results && 'items' in results
      ? Math.ceil(results?.total || 0)
      : Math.ceil(results?.length || 0);

  const pagination = usePagination({
    totalItems,
    paginationStyle,
    initPageSize,
    queryString
  });

  const { data, isValidating, error } = useSWR<ResultsObject<ResourceType>>(
    `${key}?${pagination.apiQueryString}${queryString}`,
    { suspense }
  );

  if (data && data.page !== pagination.currentPage) {
    pagination.setPage(data.page);
  }

  return {
    count: data?.total,
    data: data?.items,
    isValidating,
    mutate,
    pagination,
    error
  };
}
