import { FjdModal, FjdStack } from 'fjd-react-components';
import { useState } from 'react';
import { useUpdateCase } from '../../../../hooks/useUpdateCase';
import DownloadTabelle from './AntragDownloadTabelle';
import { FormatUtil } from '../../../../utils/formatUtil';
import { ErrorComponentBehoerdenServer } from '../../../shared/Error/ErrorComponent';
import { FjdModalProps } from 'fjd-react-components/build/components/Modal/Modal';
import { BEHError } from '../../../../utils/Error/ErrorCode';

interface AntragDownloadModalProps {
  readonly onClose: () => void;
  readonly caseId: string;
  readonly auftragsnummer: string;
  readonly isOpen: boolean;
}

export function AntragDownloadModal(props: AntragDownloadModalProps) {
  const [error, setError] = useState<BEHError | undefined>(undefined);
  const { downloadSummary, loadingSummary } = useUpdateCase();

  const [queryParameter, setQueryParameter] = useState<string | undefined>(
    undefined
  );

  const modalPropertiesBase: Partial<FjdModalProps> = {
    appElement: document.querySelector('.fjd-base-layout') as HTMLElement,
    heading: 'Antrag herunterladen',
    id: 'antrag-abschliessen-modal',
    onClose: props.onClose,
    closeOnBackdropClick: false,
    testId: 'antrag-download-modal',
    open: props.isOpen
  };

  const modalPropertiesError: Partial<FjdModalProps> = {
    secondaryButtonDisabled: true,
    primaryButtonLabel: 'Schließen',
    onPrimaryButtonClick: () => props.onClose()
  };

  const modalPropertiesNoError: Partial<FjdModalProps> = {
    secondaryButtonDisabled: loadingSummary,
    primaryButtonDisabled: loadingSummary,
    onPrimaryButtonClick: () => {
      const timestamp = FormatUtil.fileDateTimeFormatted();
      downloadSummary(
        props.caseId,
        `${props.auftragsnummer}_${timestamp}_Download.zip`,
        queryParameter
      ).then(() => props.onClose());
    },
    primaryButtonLabel: 'Antrag herunterladen (ZIP-Datei)',
    secondaryButtonLabel: 'Abbrechen',
    onSecondaryButtonClick: () => props.onClose()
  };

  const modalProperties: FjdModalProps = error
    ? ({ ...modalPropertiesBase, ...modalPropertiesError } as FjdModalProps)
    : ({ ...modalPropertiesBase, ...modalPropertiesNoError } as FjdModalProps);

  return (
    <FjdModal {...modalProperties}>
      {error ? (
        <ErrorComponentBehoerdenServer errorCode={error?.traceId} />
      ) : (
        <FjdStack orientation="vertical">
          <div>
            Sie haben die Möglichkeit, neben der Zusammenfassung des aktuellen
            Bearbeitungsstandes zusätzlich die Dokumente des Antrags
            herunterzuladen. Diese können Sie hier an- oder abwählen.
          </div>
          <div style={{ marginLeft: '2rem', marginRight: '2rem' }}>
            <DownloadTabelle
              updateQueryParameter={setQueryParameter}
              setError={setError}
            />
          </div>
        </FjdStack>
      )}
    </FjdModal>
  );
}

export default AntragDownloadModal;
