import { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useApi } from './useApi';
import { BEHError } from '../utils/Error/ErrorCode';
import { fileToDataURL } from '../utils/dataURLConverter';
import useSWR, { KeyedMutator } from 'swr';

export interface LogoVO {
  fileName: string;
  fileType: string;
  base64Data: string;
  size: number;
}

export function createDataUrlFromLogoVO(logoVO: LogoVO): string {
  return `data:${logoVO.fileType};base64,${logoVO.base64Data}`;
}

export interface IUseLogo {
  logoVO: LogoVO | undefined;
  getError: BEHError | undefined;
  setLogo: (logo: File) => Promise<any>;
  isValidating: boolean;
  convertingError: BEHError | Error | undefined;
  putError: BEHError | Error | undefined;
  mutate: KeyedMutator<LogoVO>;
  deleteLogo: () => Promise<void>;
  deleteError: BEHError | Error | undefined;
}

export default function useLogo(): IUseLogo {
  const [convertingError, setConvertingError] = useState<
    BEHError | Error | undefined
  >(undefined);
  const [putError, setPutError] = useState<BEHError | Error | undefined>(
    undefined
  );
  const [deleteError, setDeleteError] = useState<BEHError | Error | undefined>(
    undefined
  );

  const { makeRequest } = useApi();
  const url: string = `/api/v1/configuration/logo`;

  let {
    data: logoVOBuffer,
    error: getErrorBuffer,
    isValidating: isValidatingBuffer,
    mutate: mutateBuffer
  } = useSWR<LogoVO>(url, {
    suspense: false
  });

  if (getErrorBuffer && getErrorBuffer.status === 404) {
    getErrorBuffer = undefined;
    logoVOBuffer = undefined;
  }

  const logoVO = logoVOBuffer,
    getError = getErrorBuffer,
    isValidating = isValidatingBuffer,
    mutate = mutateBuffer;

  const setLogo = useCallback(
    async (
      logo: File
    ): Promise<void | AxiosResponse<AxiosResponse<any, any>>> => {
      setPutError(undefined);
      setConvertingError(undefined);
      setDeleteError(undefined);
      let hasPutErrorOccurred = false;

      return fileToDataURL(logo)
        .then(async (base64Result: string) => {
          const logoVO: LogoVO = {
            fileName: logo.name,
            fileType: base64Result.split(':')[1].split(';')[0],
            base64Data: base64Result.split(',')[1],
            size: logo.size
          };

          return makeRequest<AxiosResponse>(
            url,
            'PUT',
            undefined,
            undefined,
            undefined,
            logoVO
          ).catch((error: Error) => {
            hasPutErrorOccurred = true;
            setPutError(error as BEHError);
            throw error;
          });
        })
        .catch((error: Error) => {
          if (!hasPutErrorOccurred) {
            setConvertingError(error as Error);
          }

          throw error;
        });
    },
    [makeRequest, url]
  );

  const deleteLogo = useCallback(async (): Promise<void> => {
    setDeleteError(undefined);

    return makeRequest<AxiosResponse>(
      url,
      'DELETE',
      undefined,
      undefined,
      undefined,
      undefined
    )
      .then(() => {
        setPutError(undefined);
        setConvertingError(undefined);
      })
      .catch((error) => {
        setDeleteError(error as BEHError);
        throw error;
      });
  }, [makeRequest, url]);

  return {
    logoVO,
    getError,
    setLogo,
    isValidating,
    convertingError,
    putError,
    deleteLogo,
    deleteError,
    mutate
  };
}
