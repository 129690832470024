import {
  ExtendedFile,
  FileDropField,
  FileList,
  Stack
} from '@efast_public/fjd-component-library';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import useAlerts from '../../../../hooks/useAlerts';
import useLogo, { IUseLogo } from '../../../../hooks/useLogo';
import { ErrorComponentBehoerdenServer } from '../../../shared/Error/ErrorComponent';
import IConfigAssistantStep from '../IConfigAssistantStep';

interface LogoProps extends IConfigAssistantStep {}

export default function Logo(props: LogoProps): ReactElement {
  const [droppedFile, setDroppedFile] = useState<File | undefined>(undefined);
  const [showedFile, setShowedFile] = useState<ExtendedFile | undefined>(
    undefined
  );

  const {
    logoVO,
    getError,
    setLogo,
    convertingError,
    putError,
    deleteLogo,
    deleteError,
    mutate
  }: IUseLogo = useLogo();

  const { alert } = useAlerts();

  const { nextStepAvailable, addPrecondition, setInputChanged } = props;

  useEffect(() => {
    nextStepAvailable(true);
  }, [nextStepAvailable]);

  const isLogoUploaded: boolean = logoVO !== undefined;

  useEffect(() => {
    if (droppedFile) {
      const file: ExtendedFile = {
        name: droppedFile.name,
        size: droppedFile.size
      };
      setShowedFile(file);
    } else if (isLogoUploaded) {
      const file: ExtendedFile = { name: logoVO!.fileName, size: logoVO!.size };
      setShowedFile(file);
    } else {
      setShowedFile(undefined);
    }
  }, [logoVO, droppedFile, isLogoUploaded, alert]);

  const putOrDeleteLogo = useCallback(() => {
    if (droppedFile) {
      return setLogo(droppedFile).then(() => {
        mutate();
      });
    } else if (!showedFile && isLogoUploaded) {
      return deleteLogo().then(() => {
        mutate();
      });
    }

    return new Promise<void>((resolve) => {
      resolve();
    });
  }, [setLogo, droppedFile, isLogoUploaded, showedFile, deleteLogo, mutate]);

  useEffect(() => {
    addPrecondition({ condition: putOrDeleteLogo });
  }, [addPrecondition, putOrDeleteLogo]);

  useEffect(() => {
    setInputChanged(
      droppedFile !== undefined || (showedFile === undefined && isLogoUploaded)
    );
  }, [droppedFile, showedFile, isLogoUploaded, setInputChanged]);

  props.setButtonLabel('Speichern und weiter');

  const changeImage = async (files: Array<File>) => {
    if (files.length > 1) {
      alert('error', 'Nur ein einziges Logo ist zulässig.', 10000, true);

      return;
    }

    const file: File = files[0];

    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/svg+xml'
    ) {
      alert(
        'error',
        'Ungültiges Dateiformat. Es sind nur Dateien im Format PNG, JPG oder SVG zulässig.',
        10000,
        true
      );

      return;
    }

    if (file.size > 20000000) {
      alert(
        'error',
        'Es sind nur Bilder bis maximal 20 MB zulässig.',
        10000,
        true
      );

      return;
    }

    setDroppedFile(file);
  };

  const removeItem = () => {
    if (droppedFile) {
      setDroppedFile(undefined);
    } else {
      setShowedFile(undefined);
    }
  };

  if (getError) {
    return <ErrorComponentBehoerdenServer errorCode={getError.traceId} />;
  }

  return (
    <Stack spacing="xxl" alignItems="stretch">
      <div className="contentHeading">
        Optional: Individuelles Logo oder Wappen (Schritt 6 von 7)
      </div>
      <div className="descriptionText">
        Wenn Sie im Header neben dem Behörden-Client-Logo noch eine individuelle
        Kennzeichnung wünschen, können Sie hier oder später in den Einstellungen
        eine Datei hinzufügen. Erlaubte Formate: JPG, PNG, SVG.
      </div>

      <Stack alignItems="stretch" spacing="xs">
        {!droppedFile && (!isLogoUploaded || !showedFile) && (
          <FileDropField
            data-testid={'logoConfigurationAssistantFileDrop'}
            label="Logo auswählen"
            dropHelperText="oder in dieses Feld ziehen"
            onAdd={(files) => changeImage(files)}
          />
        )}

        {(droppedFile || isLogoUploaded) && showedFile && (
          <FileList data-testid="logoConfigurationAssistantFileList">
            {convertingError || putError || deleteError ? (
              <FileList.FileItemUpload
                data-testid="logoConfigurationAssistantFileItemError"
                file={{
                  size: showedFile!.size,
                  name: showedFile!.name,
                  error: deleteError
                    ? 'Fehler: Bild konnte nicht gelöscht werden'
                    : convertingError
                      ? 'Fehler: Ungültiges Bild. Datei konnte nicht verarbeitet werden'
                      : 'Fehler beim Hochladen des Bildes'
                }}
                onDelete={() => removeItem()}
              />
            ) : (
              <FileList.FileItem
                data-testid="logoConfigurationAssistantFileItem"
                file={showedFile!}
                onDelete={() => removeItem()}
              />
            )}
          </FileList>
        )}
      </Stack>
    </Stack>
  );
}
