import {
  FjdSection,
  FjdStack,
  FjdButton,
  FjdIcon,
  FjdFlexSpacer
} from 'fjd-react-components';
import React, { useContext } from 'react';
import { NachrichtModel } from '../../../../../models/Nachrichten/NachrichtModel';
import _moment from 'moment';

import classes from './NachrichtItem.module.css';
import { caseDetailsDataContext } from '../../../../../context/Context';
import BescheidstatusBadge from '../../../../shared/BescheidstatusBadge/BescheidstatusBadge';
import { Bescheidstatus } from '../../../../../models/Status/Bescheidstatus';
import AntragBadge from '../../../../shared/AntragBadge/AntragBadge';

const NachrichtItem: React.FC<{
  readonly nachricht: NachrichtModel;
  readonly selected: boolean;
  readonly selectNachrichtHandler: (Nachricht: NachrichtModel) => void;
}> = (props) => {
  const clickHandler = () => {
    props.selectNachrichtHandler(props.nachricht);
  };
  const caseDetailsData = useContext(caseDetailsDataContext);
  const initialSubmissionId: string =
    caseDetailsData.data.initialSubmission.submissionId;

  const cssClass = props.selected
    ? classes.selectedNachricht
    : classes.notSelectedNachricht;
  const showBescheidBadge: boolean =
    props.nachricht.isBescheid &&
    (caseDetailsData?.data.bescheidstatus === Bescheidstatus.POSITIV ||
      caseDetailsData?.data.bescheidstatus === Bescheidstatus.NEGATIV);
  return (
    <FjdSection key={Math.random()} showSeparator={true}>
      <div
        id={`nachrichtContainer_${props.nachricht.submissionId}`}
        className={
          !props.nachricht.hasBeenRead
            ? `${classes.nachrichtContainer} ${classes.unread}`
            : classes.nachrichtContainer
        }
      >
        <div className={cssClass} onClick={clickHandler}>
          <FjdStack orientation={'vertical'}>
            <FjdStack orientation={'horizontal'} spacing={'m'}>
              <div
                id={`betreff_${props.nachricht.submissionId}`}
                className={classes.betreff}
              >
                {props.nachricht.betreff}
              </div>
              <FjdFlexSpacer />

              {props.nachricht.hasAttachments && (
                <span className={classes.noHover}>
                  <FjdButton
                    id={`hatAnlage_${props.nachricht.submissionId}`}
                    size={'s'}
                    label={''}
                    appearance={'link'}
                    iconLeft={'link'}
                  />
                </span>
              )}
            </FjdStack>
            <FjdStack orientation={'horizontal'} spacing={'m'}>
              <FjdIcon
                size={'m'}
                glyph={props.nachricht.isVonBehoerde ? 'reply' : 'email'}
              ></FjdIcon>
              <div
                id={`bescheid_${props.nachricht.submissionId}`}
                className={
                  props.nachricht.isVonBehoerde
                    ? classes.vonBescheidNegative
                    : classes.vonBescheidPositive
                }
              >
                <span>
                  {props.nachricht.isVonBehoerde
                    ? `von ${props.nachricht.absenderName}`
                    : 'von Antragsteller:in'}
                </span>
              </div>
            </FjdStack>
            <FjdStack orientation={'horizontal'}>
              <div
                id={`nachricht_createdDate_${props.nachricht.submissionId}`}
                className={classes.nachrichtCreatedDate}
              >
                {_moment
                  .utc(props.nachricht.createdOn)
                  .local()
                  .format('DD.MM.YYYY • HH:mm')}
              </div>
              {showBescheidBadge && (
                <BescheidstatusBadge
                  bescheidstatus={caseDetailsData?.data.bescheidstatus}
                  fjdBadgeSize={'xs'}
                  badgeLabel={'Bescheid'}
                />
              )}
              {props.nachricht.submissionId === initialSubmissionId && (
                <AntragBadge data-testid="antragBadge" />
              )}
            </FjdStack>
          </FjdStack>
        </div>
      </div>
    </FjdSection>
  );
};

export default NachrichtItem;
