import classes from '../Schreibtisch.module.css';
import {
  FjdAlert,
  FjdButton,
  FjdFlexSpacer,
  FjdHeading,
  FjdStack
} from 'fjd-react-components';
import { useContext, useState } from 'react';
import useTabs from '../../../hooks/useTabs';
import SwitchApplicationModals from './BescheidModal/SwitchApplicationModals';
import { ContentTab } from '../Schreibtisch';
import NachrichtSendenModal from '../SchreibtischBody/Kommunikation/NachrichtSenden/NachrichtSendenModal';
import AntragAbschliessenModal from './AntragAbschliessen/AntragAbschliessenModal';
import { Bearbeitungsstand } from '../../../models/Status/Bearbeitungsstand';
import { Bescheidstatus } from '../../../models/Status/Bescheidstatus';
import KommunikationPdfDrucken from './KommunikationDrucken/KommunikationPdfDrucken';
import { useProfile } from '../../../hooks/useProfile';
import { caseDetailsDataContext } from '../../../context/Context';
import DownloadModal from './AntragDownload/AntragDownloadModal';
import _moment from 'moment/moment';

interface AntragsdeatilsHeaderProps {
  readonly setContentTabState: (val: ContentTab) => void;
}

const SchreibtischHeader = (props: AntragsdeatilsHeaderProps) => {
  const [openSendenModal, setOpenSendenModal] = useState(false);
  const [openDecideApplicationModal, setOpenDecideApplicationModal] =
    useState(false);
  const [openAbschliessenModal, setOpenAbschliessenModal] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [
    openKommunikationPdfDruckenModal,
    setOpenKommunikationPdfDruckenModal
  ] = useState(false);
  const { activeTab } = useTabs();
  const caseDetails = useContext(caseDetailsDataContext);
  const version: string = caseDetails.data.version;
  const organisationUnitName = activeTab?.data.element.organisationUnitName;
  const caseId = activeTab?.data.element.caseId;
  const auftragsnummer = caseDetails.data.auftragsnummer;

  const caseName =
    activeTab?.id !== 'empty' ? activeTab?.data.element.name : '';
  const caseAuftragsnummer =
    activeTab?.id !== 'empty' ? activeTab?.data.element.auftragsnummer : '';
  const { profile } = useProfile(); // No error handling needed, because error is catched in route
  const bearbeitendeNutzer = caseDetails.data.bearbeiterDto?.id === profile?.id;
  const canInteract =
    activeTab?.data.element.bearbeitungsstand !==
    Bearbeitungsstand.ABGESCHLOSSEN;

  const canCommunicate = caseDetails.data.canCommunicate;

  const initialSubmissionAbsender = activeTab?.data.element.initialSubmission
    ? activeTab?.data.element.initialSubmission.absenderName
    : '';

  return (
    <>
      <FjdStack
        className={classes.caseNameTitle}
        id={'casename_title'}
        orientation={'horizontal'}
      >
        <FjdHeading
          text={`${caseAuftragsnummer} • ${caseName}`}
          visualLevel={1}
        />
        <FjdFlexSpacer />
        <FjdStack orientation="horizontal">
          <FjdButton
            appearance={'outline'}
            size={'m'}
            label={'Antrag herunterladen'}
            testId={'download_form'}
            hideLabel={true}
            iconLeft={'download'}
            key={Math.random()}
            onClick={() => setOpenDownloadModal(true)}
            id={'download_btn'}
          />
          <FjdButton
            appearance={'outline'}
            size={'m'}
            label={'Antrag drucken'}
            testId={'print_formular'}
            hideLabel={true}
            iconLeft={'printer'}
            key={Math.random()}
            onClick={() => setOpenKommunikationPdfDruckenModal(true)}
            id={'print_formular'}
          />
        </FjdStack>
        {canInteract &&
          ((caseDetails?.data?.bescheidstatus as Bescheidstatus) ===
            Bescheidstatus.POSITIV ||
          (caseDetails?.data?.bescheidstatus as Bescheidstatus) ===
            Bescheidstatus.NEGATIV ? (
            <FjdButton
              appearance={'default'}
              size={'m'}
              label={'Abschließen'}
              testId={'abschliessen'}
              iconLeft={'certificate-check'}
              key={Math.random()}
              onClick={() => setOpenAbschliessenModal(true)}
              id={'bescheid_antragsdetails'}
              disabled={!bearbeitendeNutzer}
            />
          ) : (
            <FjdButton
              appearance={'default'}
              size={'m'}
              label={'Bescheidversand'}
              testId={'bescheid'}
              iconLeft={'send'}
              key={Math.random()}
              onClick={() => setOpenDecideApplicationModal(true)}
              id={'bescheid_antragsdetails'}
              disabled={!bearbeitendeNutzer}
            />
          ))}
        {canInteract && (
          <FjdButton
            disabled={!canCommunicate || !bearbeitendeNutzer}
            appearance={'default'}
            size={'m'}
            label={'Rückfrage'}
            testId={'rueckfrage'}
            iconLeft={'reply'}
            key={Math.random()}
            onClick={() => setOpenSendenModal(!openSendenModal)}
            id={'rueckfrage_antragsdetails'}
          />
        )}
      </FjdStack>
      {caseDetails?.data?.bearbeitungsstand ===
        Bearbeitungsstand.ABGESCHLOSSEN && (
        <div id={caseDetails.data.bearbeiterDto?.fullName}>
          <FjdStack
            id={caseDetails.data.bearbeiterDto?.fullName}
            className={classes.bearbeiter}
            orientation="horizontal"
          >
            <b>Bearbeiter:in: </b>&nbsp;{' '}
            {caseDetails.data.bearbeiterDto?.fullName ?? 'k. A.'}
          </FjdStack>
          <FjdAlert
            title={`Abgeschlossene Anträge werden nach 30 Tagen aus dem Behörden-Client gelöscht.
              Dieser Antrag wurde am ${_moment
                .utc(caseDetails.data.abgeschlossenAm)
                .local()
                .format('DD.MM.YYYY, HH:mm')}
              von ${
                caseDetails.data.bearbeiterDto?.fullName
              } heruntergeladen und wird am
              ${_moment
                .utc(caseDetails.data.abgeschlossenAm)
                .local()
                .add(30, 'd')
                .format('DD.MM.YYYY')}
              aus dem System gelöscht.`}
            appearance={'muted'}
            closable={false}
            intent={'warning'}
          />
        </div>
      )}
      {canInteract && openDecideApplicationModal && (
        <SwitchApplicationModals
          onClose={() => {
            setOpenDecideApplicationModal(false);
          }}
          organisationUnitName={organisationUnitName}
          caseId={caseId}
          setContentTabState={props.setContentTabState}
          openAbschliessenModal={() => setOpenAbschliessenModal(true)}
        />
      )}
      {caseDetails.data.isBeschieden && (
        <AntragAbschliessenModal
          onClose={() => {
            setOpenAbschliessenModal(false);
            caseDetails?.refresh();
          }}
          auftragsnummer={auftragsnummer}
          caseId={caseId}
          version={version}
          isOpen={openAbschliessenModal}
        />
      )}
      <DownloadModal
        onClose={() => {
          setOpenDownloadModal(false);
        }}
        auftragsnummer={auftragsnummer}
        caseId={caseId}
        isOpen={openDownloadModal}
      />
      <NachrichtSendenModal
        onClose={() => {
          setOpenSendenModal(false);
          caseDetails?.refresh();
        }}
        caseId={caseId}
        initialSubmissionAbsender={initialSubmissionAbsender}
        caseVersion={version}
        isOpen={canInteract && openSendenModal}
      />
      <KommunikationPdfDrucken
        onClose={() => setOpenKommunikationPdfDruckenModal(false)}
        caseId={caseId}
        isOpen={openKommunikationPdfDruckenModal}
      />
    </>
  );
};

export default SchreibtischHeader;
