export const fileToDataURL = (file: File | Blob): Promise<string> =>
  new Promise(
    (
      resolve: (value: string | PromiseLike<string>) => void,
      reject: (reason?: any) => void
    ) => {
      const reader: FileReader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };

      reader.readAsDataURL(file);
      reader.onerror = (error: ProgressEvent<FileReader>) => reject(error);
    }
  );
