import { AntragObj } from '../models/Antrag/AntragsModel';
import { useUpdateCase } from './useUpdateCase';
import { CaseDetailsModel } from '../models/Case/CaseDetailsModel';
import { useCallback } from 'react';

interface AssignmentProps {
  antragObj: AntragObj | CaseDetailsModel;
  reloadData: (caseDetails: CaseDetailsModel) => void;
  setZuweisungUpdating: (value: boolean) => void;
  isValidating?: boolean;
}

function useAssignment(props: AssignmentProps) {
  const BEARBEITERID = 'bearbeiterId';
  const { updateCase } = useUpdateCase();
  const BEARBEITUNGSSTAND = 'bearbeitungsstand';
  const IN_BEARBEITUNG = 'In Bearbeitung';
  const VERSION = 'caseVersion';

  const updateCaseAssignment = useCallback(
    (updatedField: Map<string, string | null>) => {
      if (props.isValidating === undefined) {
        props.setZuweisungUpdating(true);
      }
      updateCase(props.antragObj.caseId, updatedField)
        .then((data) => {
          if (data !== undefined) {
            props.reloadData(data);
          }
        })
        .finally(() => {
          if (props.isValidating === undefined) {
            props.setZuweisungUpdating(false);
          }
        });
    },
    [props, updateCase]
  );

  const assignCase = (bearbeiterid: string, version: string) => {
    props.setZuweisungUpdating(true);
    const updatedFields = new Map<string, string | null>();
    updatedFields.set(VERSION, version);
    updatedFields.set(BEARBEITERID, bearbeiterid);
    updatedFields.set(BEARBEITUNGSSTAND, IN_BEARBEITUNG);
    updateCaseAssignment(updatedFields);
  };

  const revokeCase = (version: string) => {
    const updatedFields = new Map<string, string | null>();
    updatedFields.set(VERSION, version);
    updatedFields.set(BEARBEITERID, null);
    updatedFields.set(BEARBEITUNGSSTAND, 'Offen');

    updateCaseAssignment(updatedFields);
  };

  return { assignCase, revokeCase };
}

export default useAssignment;
