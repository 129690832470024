import {
  CaseDetails,
  LabelValue
} from '../../../../../models/Case/CaseDetailsModel';

export enum PaymentInformationKey {
  TRANSACTIONID = 'Transaktionsnummer',
  BETRAG = 'Betrag',
  TRANSACTIONREF = 'Verwendungszweck',
  STATUS = 'Status'
}

export class PaymentInformation {
  values: Array<LabelValue>;

  constructor(values: Array<LabelValue>) {
    this.values = values;
  }

  available() {
    return this.values && this.values.length > 0;
  }

  getValueOf(key: PaymentInformationKey) {
    if (!this.available()) {
      return '– Keine Angabe –';
    }

    for (let value of this.values) {
      if (value.label === key.valueOf()) {
        return value.value;
      }
    }

    return '– Keine Angabe –';
  }
}

export const paymentInformationMapper = (
  caseDetails: CaseDetails | null
): PaymentInformation | null => {
  if (
    !caseDetails ||
    !caseDetails.metaData ||
    !(caseDetails.metaData.length > 0)
  ) {
    return new PaymentInformation([]);
  }

  let paymentArray: Array<LabelValue> = [];

  for (let data of caseDetails.metaData) {
    if (data.title === 'paymentInformation') {
      for (let paymentInfo of data.labelValue) {
        if (isUsedValue(paymentInfo.label)) {
          paymentArray.push(toReadable(paymentInfo));
        }
      }
    }
  }

  return new PaymentInformation(paymentArray);
};

function isUsedValue(value: string) {
  switch (value) {
    case 'transactionId':
      return true;
    case 'grossAmount':
      return true;
    case 'transactionReference':
      return true;
    case 'status':
      return true;
    default:
      return false;
  }
}

function toReadable(value: LabelValue) {
  switch (value.label) {
    case 'transactionUrl': {
      return new LabelValue('Transaktions-Url', value.value);
    }
    case 'transactionId': {
      return new LabelValue('Transaktionsnummer', value.value);
    }
    case 'transactionReference': {
      return new LabelValue('Verwendungszweck', value.value);
    }
    case 'transactionTimestamp': {
      return new LabelValue('Zeitstempel', value.value);
    }
    case 'grossAmount': {
      return new LabelValue('Betrag', amountToReadable(value.value));
    }
    case 'paymentMethod': {
      return new LabelValue('Zahlungsart', value.value);
    }
    case 'paymentMethodDetail': {
      return new LabelValue('Weitere Details', value.value);
    }
    case 'status': {
      return new LabelValue('Status', statusToReadable(value.value));
    }
    default: {
      return value;
    }
  }
}

function amountToReadable(value: string) {
  let num: number = +value;

  return `${(num * 0.01).toFixed(2)} €`;
}

function statusToReadable(value: string | undefined) {
  switch (value) {
    case 'INITIAL': {
      return 'In Arbeit';
    }
    case 'BOOKED': {
      return 'Eingegangen';
    }
    case 'FAILED': {
      return 'Fehlgeschlagen';
    }
    case 'CANCELED': {
      return 'Abgebrochen';
    }
    default: {
      return 'Unbekannt';
    }
  }
}
