import useSWR from 'swr';

interface UseKommunikationPdfConfig {
  caseId: string;
}

export function useKommunikationPdf({ caseId }: UseKommunikationPdfConfig) {
  const {
    data: pdf,
    error,
    isValidating,
    mutate
  } = useSWR<string>(
    [`/api/v1/cases/${caseId}`, undefined, 'application/pdf'],
    {
      suspense: false
    }
  );

  return { pdf, isValidating, mutate, error };
}
