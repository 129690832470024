import { Bearbeitungsstand } from '../Status/Bearbeitungsstand';
import { Bescheidstatus } from '../Status/Bescheidstatus';

export interface AntragObj {
  caseId: string;
  antragsArt: string;
  verwaltungleistung: string;
  name: string;
  absenderName: string | null;
  organizationalUnitName: string;
  bearbeitungsstand: Bearbeitungsstand;
  bescheidstatus: Bescheidstatus;
  createdOn: Date | string;
  anlageDto: AnlageDto;
  bearbeiterDto: BearbeiterDto | null;
  auftragsnummer: string;
  version: string;
  firstInteractionTimestamp?: Date | string;
  canCommunicate: boolean;
}

export interface AnlageDto {
  count: number;
  size: number;
}

export interface BearbeiterDto {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
}

export const initialAntragObj: AntragObj = {
  caseId: '',
  antragsArt: '',
  verwaltungleistung: '',
  name: '',
  absenderName: '',
  organizationalUnitName: '',
  bearbeitungsstand: Bearbeitungsstand.OFFEN,
  bescheidstatus: Bescheidstatus.NOCHNICHTBESCHIEDEN,
  createdOn: '',
  anlageDto: { size: 0 } as AnlageDto,
  bearbeiterDto: null,
  auftragsnummer: '',
  version: '',
  canCommunicate: false
};
