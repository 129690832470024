import { FjdStack, FjdSectionHeader, FjdHeading } from 'fjd-react-components';

export default function Notifications() {
  return (
    <div style={{ width: '50rem' }}>
      <FjdStack spacing="xl">
        <FjdSectionHeader
          align="center"
          heading={
            <FjdHeading
              id={'notifications'}
              level={2}
              text={'Benachrichtigungen'}
            />
          }
        />
      </FjdStack>
    </div>
  );
}
