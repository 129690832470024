import {
  FjdVirtualizedTableCell,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { AntragObj } from '../../../models/Antrag/AntragsModel';
import { useTable } from '../../../hooks/useTable';
import { useTableCellRenderer } from '../../../hooks/useTableCellRenderer';
import useTabs from '../../../hooks/useTabs';

interface CasesTableCaseRowProps {
  readonly antrag: AntragObj;
  readonly table: ReturnType<typeof useTable>;
}

export function AntragsuebersichtTableCaseRow({
  antrag: caseData,
  table
}: CasesTableCaseRowProps) {
  const { renderCell } = useTableCellRenderer({
    antrag: caseData,
    table
  });
  const { openTab } = useTabs();

  return caseData ? (
    <div style={{ position: 'relative' }}>
      <FjdVirtualizedTableRow
        bordered={true}
        highlightOnHover
        withTopSeparator
        onClick={() => openTab(caseData)}
      >
        {table.activeColumns.map((column) => (
          <FjdVirtualizedTableCell
            format={column.format || 'default'}
            key={`${caseData.caseId}-${column.id}`}
            maxWidth={column.maxWidth}
            minWidth={column.minWidth}
            verticalSeparator
            width={column.width}
          >
            {renderCell(column.id)}
          </FjdVirtualizedTableCell>
        ))}
      </FjdVirtualizedTableRow>
    </div>
  ) : null;
}
