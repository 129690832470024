import { FormRow, Input } from '@efast_public/fjd-component-library';
import { useEffect, useState } from 'react';
import './OrgaUnitUpdateComponent.css';

interface OrgUnitUpdateProps {
  readonly updateNewNameByOldName: (key: string, value: string) => void;
  readonly newNameByOldName: Map<string, string>;
  readonly orgName: string;
  readonly originalOrgName: string | undefined;
  readonly hasError: boolean;
}

export function OrgUnitUpdateComponent({
  orgName,
  originalOrgName,
  updateNewNameByOldName,
  newNameByOldName,
  hasError
}: OrgUnitUpdateProps) {
  const [name, setName] = useState<string>(orgName);
  useEffect(() => {
    if (newNameByOldName && !newNameByOldName.has(orgName)) {
      updateNewNameByOldName(orgName, orgName);
    }
  }, [orgName, updateNewNameByOldName, newNameByOldName]);

  return (
    <div key={orgName} className={'orgUnitNameTextInput'}>
      <FormRow
        className={'label'}
        label={originalOrgName}
        id={`label${orgName}`}
        variant={hasError ? 'error' : undefined}
        validationMessage={
          hasError ? 'Bitte geben Sie verschiedene Bezeichnungen ein.' : ''
        }
      >
        <Input
          key={orgName}
          id={`input_${orgName}`}
          type="text"
          minLength={1}
          maxLength={100}
          disabled={false}
          onChange={(event) => {
            updateNewNameByOldName(orgName, event.target.value);
            setName(event.target.value);
          }}
          placeholder={orgName}
          value={name}
        />
      </FormRow>
    </div>
  );
}
