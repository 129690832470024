import FooterLinks from '../../../ConfigAssistant/ConfigAssistantSteps/FooterLinks/FooterLinks';
import { nullImplementation } from '../../../ConfigAssistant/ConfigAssistantSteps/IConfigAssistantStep';
import './FooterlinksSettings.css';

export default function FooterlinksSettings() {
  return (
    <span className="containerFooterlinksSettings">
      <FooterLinks {...nullImplementation} isSettings={true} />
    </span>
  );
}
