import { FjdMarkdown, FjdSpinner, FjdStack } from 'fjd-react-components';
import { ReactNode } from 'react';

interface LoadingSpinnerProps {
  readonly label?: ReactNode;
}

export function LoadingSpinner({ label }: LoadingSpinnerProps) {
  return (
    <FjdStack orientation="horizontal" spacing="m">
      <FjdSpinner size="s" />
      {typeof label === 'string' && <FjdMarkdown source={label} />}
      {typeof label !== 'string' && label}
    </FjdStack>
  );
}
