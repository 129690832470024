import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export interface CheckBoxState {
  checkboxId: string;
  id: string;
  checked: boolean;
}

export interface ICheckboxesHook {
  getCheckBoxStateById: (id: string) => CheckBoxState;
  setSelectAllCheckBox: Dispatch<SetStateAction<boolean>>;
  selectAllCheckBox: boolean;
  onSelectAllCheckboxes: (value: boolean) => void;
  onClickCheckBox: (value: boolean, input: string) => void;
  setCheckBoxState: Dispatch<SetStateAction<Array<CheckBoxState>>>;
  checkBoxState: Array<CheckBoxState>;
  getIdsAsParam: () => string | undefined;
}

const initialCheckBoxState: CheckBoxState = {
  id: '',
  checkboxId: '',
  checked: true
};

function useCheckboxes() {
  const [checkBoxState, setCheckBoxState] = useState<Array<CheckBoxState>>([]);
  const [selectAllCheckBox, setSelectAllCheckBox] = useState<boolean>(true);

  const onClickCheckBox = (value: boolean, input: string) => {
    setSelectAllCheckBox(false);

    if (value) {
      setCheckBoxState(() => {
        const mappedCheckBox = checkBoxState.map((state) => {
          return state.id === input ? { ...state, checked: true } : state;
        });
        return mappedCheckBox;
      });
    } else {
      setCheckBoxState(() => {
        const mappedCheckBox = checkBoxState.map((state) => {
          return state.id === input ? { ...state, checked: false } : state;
        });
        return mappedCheckBox;
      });
    }
  };

  const getCheckBoxStateById = (id: string): CheckBoxState => {
    const foundCheckBoxState = checkBoxState.find((state) => state.id === id);
    return foundCheckBoxState ? foundCheckBoxState : initialCheckBoxState;
  };

  const getIdsAsParam = (): string | undefined => {
    const noChecked = checkBoxState.every((checkbox) => !checkbox.checked);
    return noChecked
      ? ' '
      : checkBoxState.length > 0
        ? checkBoxState
            .filter((checkbox) => checkbox.checked)
            .map((attId) => attId.id)
            .join(',')
        : undefined;
  };

  useEffect(() => {
    const allCheckboxsesSelect = checkBoxState.every(
      (checkbox) => checkbox.checked
    );
    if (allCheckboxsesSelect) {
      setSelectAllCheckBox(true);
    }
  }, [checkBoxState]);

  const onSelectAllCheckboxes = (value: boolean) => {
    setSelectAllCheckBox(value);
    if (value) {
      setCheckBoxState(() => {
        const mappedCheckBox = checkBoxState.map((state) => {
          return { ...state, checked: true };
        });
        return mappedCheckBox;
      });
    } else {
      setCheckBoxState(() => {
        const mappedCheckBox = checkBoxState.map((state) => {
          return { ...state, checked: false };
        });
        return mappedCheckBox;
      });
    }
  };

  return {
    getCheckBoxStateById,
    setSelectAllCheckBox,
    selectAllCheckBox,
    onSelectAllCheckboxes,
    onClickCheckBox,
    setCheckBoxState,
    checkBoxState,
    getIdsAsParam
  } as ICheckboxesHook;
}

export default useCheckboxes;
