import { FjdCheckbox, FjdLink } from 'fjd-react-components';
import { CSSProperties, useEffect, useState } from 'react';
import _moment from 'moment';

import { useTable } from './useTable';

import { getCasePath } from '../utils/router';
import { formatByte } from '../utils/antragsuebersicht';
import { useElementNavigation } from './useElementNavigation';
import { AntragObj, initialAntragObj } from '../models/Antrag/AntragsModel';
import { CaseDetailsModel } from '../models/Case/CaseDetailsModel';
import { RouterLink } from '../utils/Router/RouterLink';
import {
  bearbeitungsstandCell,
  bescheidstatusCell,
  rueckkanalCell,
  statusCell
} from '../pages/Antragsuebersicht/AntragsuebersichtTabelle/AntragsuebersichtCustomizedCell';
import ZuweisungComponent from '../pages/shared/Zuweisung/ZuweisungComponent';
import './TableCellRenderer.css';
interface UseTableCellRendererConfig {
  antrag: AntragObj;
  table: ReturnType<typeof useTable>;
}

export function useTableCellRenderer({
  antrag,
  table
}: UseTableCellRendererConfig) {
  const { navigateToElement } = useElementNavigation();
  const reloadCell = (caseDetails: CaseDetailsModel) => {
    setCaseData({
      ...antrag,
      bearbeitungsstand: caseDetails.bearbeitungsstand,
      bearbeiterDto: caseDetails.bearbeiterDto ?? null,
      version: caseDetails.version
    });
  };

  const [caseData, setCaseData] = useState<AntragObj>(initialAntragObj);
  const textStyleAbgeschlossen = (): CSSProperties => {
    return caseData.bearbeitungsstand === 'ABGESCHLOSSEN'
      ? { color: 'var(--fjd-color-text-dark-muted)' }
      : {};
  };
  const dateTitleStyleAbgeschlossen = (): CSSProperties => {
    return caseData.bearbeitungsstand === 'ABGESCHLOSSEN'
      ? { color: 'var(--fjd-color-brand-light)' }
      : caseData.bearbeitungsstand === 'OFFEN'
        ? { fontWeight: 'bold' }
        : {};
  };

  useEffect(() => {
    setCaseData(antrag);
  }, [antrag]);

  const renderCell = (columnId: string) => {
    const span =
      caseData?.anlageDto.size !== 0 ? (
        <>{formatByte(caseData?.anlageDto?.size.toString())}</>
      ) : null;

    switch (columnId) {
      case 'createdOn':
        const id: string = `datumLink_${caseData.caseId}`;
        return (
          <RouterLink
            component={({ href }) => (
              <FjdLink onClick={() => navigateToElement(caseData)}>
                <a
                  href={href}
                  style={{
                    padding: '0.75rem',
                    display: 'block',
                    textDecorationLine: 'none'
                  }}
                  id={id}
                >
                  <span style={dateTitleStyleAbgeschlossen()}>
                    {_moment
                      .utc(caseData.createdOn)
                      .local()
                      .format('DD.MM.YYYY')}
                  </span>
                  <br />
                  <span style={dateTitleStyleAbgeschlossen()}>
                    {_moment.utc(caseData?.createdOn).local().format('HH:mm')}
                  </span>
                </a>
              </FjdLink>
            )}
            to={getCasePath(caseData?.caseId ? caseData.caseId : '')}
          />
        );
      case 'status':
        return (
          <div
            style={{
              padding: '0.75rem'
            }}
          >
            {statusCell(caseData.bearbeitungsstand, caseData.bescheidstatus)}
          </div>
        );
      case 'titel':
        return (
          <RouterLink
            component={({ href }) => (
              <FjdLink onClick={() => navigateToElement(caseData)}>
                <a href={href} id="bezeichnerLink">
                  {''}
                </a>
                <div
                  className={'fjd-truncated-text--multiline caseTitle'}
                  style={dateTitleStyleAbgeschlossen()}
                >
                  {caseData.name}
                </div>
              </FjdLink>
            )}
            to={getCasePath(caseData?.caseId)}
          />
        );
      case 'auftragsnummer':
        return (
          <span style={textStyleAbgeschlossen()}>
            {caseData.auftragsnummer}
          </span>
        );
      case 'bearbeiter':
        return (
          <ZuweisungComponent
            marginBottom={'20%'}
            position={'absolute'}
            isSchreibtisch={false}
            antrag={caseData}
            reloadData={reloadCell}
          />
        );
      case 'absenderName':
        return (
          <span style={textStyleAbgeschlossen()}>{caseData.absenderName}</span>
        );
      case 'bearbeitungsstand':
        return bearbeitungsstandCell(caseData);
      case 'bescheidstatus':
        return bescheidstatusCell(caseData);
      case 'rueckkanal':
        return rueckkanalCell(caseData);
      case 'empfaenger':
        return (
          <span style={textStyleAbgeschlossen()}>
            {caseData.organizationalUnitName}
          </span>
        );
      case 'anlageDto':
        return (
          <div
            style={{
              padding: '0.75rem'
            }}
          >
            <span style={textStyleAbgeschlossen()} className={'fontWeightBold'}>
              {caseData.anlageDto.count}
            </span>
            <br />
            <span>({span})</span>
          </div>
        );
      case 'selection':
        return (
          <FjdCheckbox
            checked={table.selection.some(
              (el) => el.caseId === caseData.caseId
            )}
            id={`select-${caseData.name}`}
            label=""
            onChange={() => table.toggleSelection(caseData)}
            size="s"
          />
        );
      default:
        return null;
    }
  };

  return { renderCell };
}
