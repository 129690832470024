import {
  Bearbeitungsstand,
  bearbeitungsstandToString
} from '../../../models/Status/Bearbeitungsstand';
import { FjdBadge } from 'fjd-react-components';
import {
  FjdBadgeAppearance,
  FjdBadgeSize
} from 'fjd-react-components/build/components/Badge/Badge';
import { FjdIconGlyph } from 'fjd-react-components/build/components/Icon/Icon';

export interface BearbeitungsstandBadgeProps {
  readonly bearbeitungsstand: Bearbeitungsstand;
  readonly fjdBadgeSize: FjdBadgeSize;
}

export default function BearbeitungsstandBadge(
  props: BearbeitungsstandBadgeProps
) {
  let appearance: FjdBadgeAppearance;
  let fjdIconGlyph: FjdIconGlyph | undefined;
  let divId;
  switch (props.bearbeitungsstand) {
    case Bearbeitungsstand.OFFEN:
      appearance = 'outline';
      divId = `${appearance}-badge`;
      break;
    case Bearbeitungsstand.INBEARBEITUNG:
      appearance = 'default';
      divId = `${appearance}-badge`;
      break;
    case Bearbeitungsstand.ABGESCHLOSSEN:
      appearance = 'secondary-dark';
      fjdIconGlyph = 'locked';
      divId = `${appearance.toString()}-${fjdIconGlyph.toString()}-badge`;
      break;
    default:
      return null;
  }

  return (
    <div id={divId}>
      <FjdBadge
        appearance={appearance}
        label={bearbeitungsstandToString(props.bearbeitungsstand)}
        size={props.fjdBadgeSize}
        icon={fjdIconGlyph}
      />
    </div>
  );
}
