import useSWR from 'swr';
import { KeycloakGroup } from './useInvite';

export interface UserInviteConfig {
  systemRoles: Array<KeycloakGroup>;
  groupRoles: Record<string, Array<KeycloakGroup>>;
}

export function useReadUserInviteConfig() {
  const {
    data: inviteConfig,
    error,
    isValidating,
    mutate
  } = useSWR<UserInviteConfig>(`/api/v1/config/userInvite`, {
    suspense: false
  });

  return { inviteConfig, error, isValidating, mutate };
}
