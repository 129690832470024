import { SelectOption } from '../utils/antragsuebersicht';
import { useCallback, useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import { useStorageState } from 'react-storage-hooks';

export interface FilterBadge {
  value: string;
  label: string;
  filterIdentifier: string;
}

export const filterAtom = atom<Array<FilterBadge>>({
  default: [],
  key: 'filterBadges'
});

export const filterStateLocalStorageKey = 'beh-filter-state';

declare type Setter<T> = React.Dispatch<React.SetStateAction<T | undefined>>;

export default function useFilterBadges() {
  const [filterBadges, setFilterBadges] =
    useRecoilState<Array<FilterBadge>>(filterAtom);

  const [, setStorageFilterState] = useStorageState<Array<FilterBadge>>(
    localStorage,
    filterStateLocalStorageKey
  );

  const [filters, setFilters] = useState<
    Map<string, (value: FilterBadge) => void>
  >(new Map());

  const onRemove = useCallback(
    <T extends string | Array<SelectOption>>(
      setState: Setter<T>,
      value: FilterBadge
    ) => {
      setFilterBadges((prevValues: Array<FilterBadge>) => {
        return prevValues?.filter((v) => {
          if (v.filterIdentifier !== value.filterIdentifier) {
            return true;
          }

          return v.value !== value.value;
        });
      });

      setState((prevValues: T | undefined) => {
        if (!prevValues) {
          return prevValues;
        }

        if (typeof prevValues === 'string') {
          return prevValues
            .split('_')
            .filter((v: string) => v !== value.value)
            .join('_') as T;
        } else if (Array.isArray(prevValues)) {
          return prevValues.filter(
            (v: SelectOption) => v.value !== value.value
          ) as T;
        }
        return prevValues as T;
      });
    },
    [setFilterBadges]
  );

  const bindOnRemove = useCallback(
    <T extends string | Array<SelectOption>>(setState: Setter<T>) => {
      return (value: FilterBadge) => {
        onRemove(setState, value);
      };
    },
    [onRemove]
  );

  const addFilterBadges = useCallback(
    (value: string, label: string, identifier: string) => {
      setFilterBadges((values: Array<FilterBadge>) => {
        if (
          !values
            .filter((f: FilterBadge) => f.filterIdentifier === identifier)
            .map((v: FilterBadge) => v.value)
            .includes(value)
        ) {
          return [
            ...values,
            {
              value: value,
              label: label,
              filterIdentifier: identifier
            } as FilterBadge
          ];
        }

        return values;
      });
    },
    [setFilterBadges]
  );

  const removeFilterBadge = useCallback(
    (value: string, identifier: string) => {
      setFilterBadges((values: Array<FilterBadge>) => {
        values = values.filter((v: FilterBadge) => {
          if (v.filterIdentifier !== identifier) {
            return true;
          }

          return v.value !== value;
        });

        return values;
      });
    },
    [setFilterBadges]
  );

  const exchangeFilterBadge = useCallback(
    (
      exchangeValue: string,
      currentValue: string,
      label: string,
      identifier: string
    ) => {
      setFilterBadges((values: Array<FilterBadge>) => {
        if (
          !values
            .filter((f: FilterBadge) => f.filterIdentifier === identifier)
            .map((v: FilterBadge) => v.value)
            .includes(currentValue)
        ) {
          return values;
        }
        values = values.filter((v: FilterBadge) => {
          if (v.filterIdentifier !== identifier) {
            return true;
          }

          return v.value !== currentValue;
        });

        values.push({
          value: exchangeValue,
          label: label,
          filterIdentifier: identifier
        } as FilterBadge);

        return values;
      });
    },
    [setFilterBadges]
  );

  const updateSelectField = useCallback(
    (
      selectedValues: Array<string>,
      stateValues: Array<string>,
      options: Array<SelectOption>,
      identifier: string
    ) => {
      const stateLength: number =
        stateValues.length === 1
          ? stateValues[0] === ''
            ? 0
            : 1
          : stateValues.length;

      if (selectedValues.length > stateLength) {
        selectedValues.forEach((v: string) => {
          if (!stateValues.includes(v)) {
            addFilterBadges(v, findLabel(v, options), identifier);
          }
        });
      } else if (selectedValues.length < stateLength) {
        stateValues.forEach((v: string) => {
          if (!selectedValues.includes(v)) {
            removeFilterBadge(v, identifier);
          }
        });
      } else if (selectedValues.length === stateLength && stateLength === 1) {
        exchangeFilterBadge(
          selectedValues[0],
          stateValues[0],
          findLabel(selectedValues[0], options),
          identifier
        );
      }
    },
    [addFilterBadges, removeFilterBadge, exchangeFilterBadge]
  );

  const bindOnUpdate = useCallback(
    (options: Array<SelectOption>, identifier: string) => {
      return (selectedValues: Array<string>, stateValues: Array<string>) => {
        updateSelectField(selectedValues, stateValues, options, identifier);
      };
    },
    [updateSelectField]
  );

  const registerFilter = useCallback(
    <T extends string | Array<SelectOption>>(
      identifier: string,
      options: Array<SelectOption>,
      setState: Setter<T>
    ) => {
      setFilters((prevValue: Map<string, (value: FilterBadge) => void>) => {
        if (!prevValue.has(identifier)) {
          prevValue.set(identifier, bindOnRemove(setState));
        }
        return prevValue;
      });
      return bindOnUpdate(options, identifier);
    },
    [setFilters, bindOnUpdate, bindOnRemove]
  );

  const removeBadge = (badge: FilterBadge) => {
    const onRemove = filters.get(badge.filterIdentifier);

    if (onRemove) {
      onRemove(badge);
    } else {
      setFilterBadges((prevValues: Array<FilterBadge>) => {
        return prevValues?.filter((v) => {
          if (v.filterIdentifier !== badge.filterIdentifier) {
            return true;
          }

          return v.value !== badge.value;
        });
      });
    }
  };

  const resetFilterBadges = () => {
    setFilterBadges([]);
  };

  const findLabel = (
    value: string,
    options: Array<SelectOption> | undefined
  ): string => {
    if (!options) {
      return '';
    }

    const filteredOptions: Array<SelectOption> = options.filter(
      (option: SelectOption) => option.value === value
    );

    return filteredOptions.length > 0 ? filteredOptions[0].label : '';
  };

  useEffect(() => {
    setStorageFilterState(filterBadges);
  }, [setStorageFilterState, filterBadges]);

  useEffect(() => {
    const rawData = localStorage.getItem(filterStateLocalStorageKey);

    const parsedData = rawData ? JSON.parse(rawData) : [];

    setFilterBadges(parsedData);
  }, [setFilterBadges]);

  return {
    removeBadge,
    registerFilter,
    filterBadges,
    resetFilterBadges
  };
}
