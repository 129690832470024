import {
  Bescheidstatus,
  bescheidstatusToString
} from '../../../models/Status/Bescheidstatus';
import { Bearbeitungsstand } from '../../../models/Status/Bearbeitungsstand';
import { FjdBadge } from 'fjd-react-components';
import {
  FjdBadgeAppearance,
  FjdBadgeSize
} from 'fjd-react-components/build/components/Badge/Badge';
import { FjdIconGlyph } from 'fjd-react-components/build/components/Icon/Icon';

export interface BescheidstatusBadgeProps {
  readonly bescheidstatus: Bescheidstatus;
  readonly bearbeitungsstand?: Bearbeitungsstand;
  readonly fjdBadgeSize: FjdBadgeSize;
  readonly badgeLabel?: string;
}

export default function BescheidstatusBadge(props: BescheidstatusBadgeProps) {
  let appearance: FjdBadgeAppearance;
  let fjdIconGlyph: FjdIconGlyph | undefined;
  let divId;

  switch (props.bescheidstatus) {
    case Bescheidstatus.NOCHNICHTBESCHIEDEN:
      appearance = 'outline';
      divId = `${appearance}-badge`;
      break;
    case Bescheidstatus.POSITIV:
      fjdIconGlyph = 'checkmark';
      if (props.bearbeitungsstand === Bearbeitungsstand.ABGESCHLOSSEN) {
        appearance = 'secondary-dark';
        divId = `${appearance.toString()}-${fjdIconGlyph.toString()}-badge`;
      } else {
        appearance = 'success-outline';
        divId = `${appearance.toString()}-${fjdIconGlyph.toString()}-badge`;
      }

      break;
    case Bescheidstatus.NEGATIV:
      fjdIconGlyph = 'close';
      if (props.bearbeitungsstand === Bearbeitungsstand.ABGESCHLOSSEN) {
        appearance = 'secondary-dark';
        divId = `${appearance.toString()}-${fjdIconGlyph.toString()}-badge`;
      } else {
        appearance = 'error-outline';
        divId = `${appearance.toString()}-${fjdIconGlyph.toString()}-badge`;
      }

      break;
    default:
      return null;
  }

  return (
    <div id={divId}>
      <FjdBadge
        appearance={appearance}
        label={
          props.badgeLabel
            ? props.badgeLabel
            : bescheidstatusToString(props.bescheidstatus)
        }
        size={props.fjdBadgeSize}
        icon={fjdIconGlyph}
      />
    </div>
  );
}
