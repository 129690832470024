import {
  FjdButton,
  FjdFlyout,
  FjdTruncatedText,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { FjdVirtualizedTableCellFormat } from 'fjd-react-components/build/components/VirtualizedTableCell/VirtualizedTableCell';
import { useEffect, useMemo, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import { ColumnConfiguration } from '../pages/shared/ColumnConfigurationProps';
import { AntragObj } from '../models/Antrag/AntragsModel';
import useTabs from './useTabs';
import { useElementNavigation } from './useElementNavigation';
import useLocalStorage from 'use-local-storage';
import { useLocalStorageTable } from './useLocalStorageTable';

export const tablesAtom = atom<TablesState>({
  default: { columns: [] },
  key: 'tables'
});

export interface TablesState {
  columns: Array<TableColumn>;
}

export interface TableColumn {
  id: string;
  active: boolean;
  header: string;
  fixed?: boolean;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  resizable?: boolean;
  sort?: 'asc' | 'desc' | undefined;
  sortable?: boolean;
  sortKey?: string;
  format?: FjdVirtualizedTableCellFormat;
}

export function useTable(initialColumns: Array<TableColumn>) {
  const [tablesState, setTablesState] = useRecoilState(tablesAtom);
  const { openTab } = useTabs();
  const { navigateToElement } = useElementNavigation();
  const columns = useMemo(() => tablesState.columns || [], [tablesState]);

  const { tableColumnsKey } = useLocalStorageTable();

  const activeColumns: Array<TableColumn> = columns.filter(
    (column) => column.active
  );

  const [localStorageColumns, setLocalStorageColumns] = useLocalStorage<
    Array<TableColumn>
  >(tableColumnsKey, initialColumns);

  const sortOptions: Array<'asc' | 'desc' | undefined> = useMemo<
    Array<'asc' | 'desc' | undefined>
  >(() => ['asc', 'desc', undefined], []);

  const resizeColumn = (columnId: string, width: number) => {
    setTablesState((tablesState) => ({
      ...tablesState,
      columns: tablesState.columns.map((column) =>
        column.id === columnId
          ? {
              ...column,
              width: `${width / 16}rem`
            }
          : column
      )
    }));
  };

  const setColumns = (columns: Array<TableColumn>) => {
    setTablesState((tablesState) => ({
      ...tablesState,
      columns
    }));
  };

  const toggleColumn = (columnId: string) => {
    setTablesState((tablesState) => ({
      ...tablesState,
      columns: tablesState.columns.map((column) =>
        column.id === columnId ? { ...column, active: !column.active } : column
      )
    }));
  };

  const toggleSelection = (element: AntragObj) =>
    setSelection((selection) =>
      selection.some((el) => el.caseId === element.caseId)
        ? selection.filter((el) => el.caseId !== element.caseId)
        : [...selection, element]
    );

  const [selection, setSelection] = useState<Array<AntragObj>>([]);

  const toggleSort = (columnId: string) => {
    setTablesState((tablesState) => ({
      ...tablesState,
      columns: tablesState.columns.map((column: TableColumn) =>
        column.id === columnId
          ? {
              ...column,
              sort: sortOptions[
                (sortOptions.indexOf(column.sort) + 1) % sortOptions.length
              ]
            }
          : { ...column, sort: undefined }
      )
    }));
  };

  const sortByOrder = columns
    .find((column) => column.sort)
    ?.sort?.toUpperCase() as 'ASC' | 'DESC';

  const sortByProperty = columns.find((column) => column.sort)?.sortKey;

  const tableHeader = (
    <FjdVirtualizedTableRow>
      {activeColumns.map(
        (
          { header, id, maxWidth, minWidth, resizable, sort, sortable, width },
          index
        ) => (
          <FjdVirtualizedTableCol
            key={id}
            maxWidth={maxWidth}
            minWidth={minWidth}
            onResize={(width) => resizeColumn(id, width)}
            resizable={resizable}
            tools={
              sortable && (
                <FjdButton
                  appearance="primary-link"
                  hideLabel
                  iconLeft={
                    sort === undefined
                      ? 'sort-chevron'
                      : sort === 'asc'
                        ? 'chevron-up'
                        : 'chevron-down'
                  }
                  label={'Sortierung umschalten'}
                  onClick={() => toggleSort(id)}
                  size="s"
                />
              )
            }
            verticalSeparator={index < activeColumns.length - 1}
            width={width}
          >
            <div title={header}>
              {header && <FjdTruncatedText text={header} />}

              {id === 'config' && (
                <FjdFlyout
                  container={document.body}
                  flyout={
                    <ColumnConfiguration
                      columns={columns}
                      setColumns={setColumns}
                      toggleColumn={toggleColumn}
                    />
                  }
                  keepOpenIfFocused
                >
                  <FjdButton
                    appearance="primary-link"
                    hideLabel
                    iconLeft="settings"
                    label={'Spalten konfigurieren'}
                    size="s"
                    id={id}
                  />
                </FjdFlyout>
              )}
            </div>
          </FjdVirtualizedTableCol>
        )
      )}
    </FjdVirtualizedTableRow>
  );

  useEffect(() => {
    if (!tablesState || tablesState.columns.length <= 0) {
      setTablesState((tablesState) => ({
        ...tablesState,
        columns: localStorageColumns
      }));
    }
  }, [localStorageColumns, setTablesState, tablesState]);

  useEffect(() => {
    // sync columns from local storage with complete columns list
    if (columns) {
      const newColumns = localStorageColumns.filter(
        (column) => !columns.some((c) => c.id === column.id)
      );

      const deletedColumns = columns.filter(
        (column) => !localStorageColumns.some((c) => c.id === column.id)
      );

      if (newColumns.length > 0 || deletedColumns.length > 0) {
        const columnsWithoutDeletedOnes =
          tablesState.columns.filter(
            (column: TableColumn) =>
              !deletedColumns.some((col: TableColumn) => col.id === column.id)
          ) || [];

        const insertionIndexForNewColumns =
          columnsWithoutDeletedOnes.length - 1;

        setTablesState((tableState) => ({
          ...tableState,
          columns: columnsWithoutDeletedOnes
            .slice(0, insertionIndexForNewColumns)
            .concat(
              newColumns,
              columnsWithoutDeletedOnes.slice(insertionIndexForNewColumns)
            )
        }));
      }

      setLocalStorageColumns(tablesState.columns);
    }
  }, [
    columns,
    localStorageColumns,
    setLocalStorageColumns,
    setTablesState,
    tablesState
  ]);

  const openSelection = () => {
    selection.forEach((element) => {
      openTab(element);
    });
    if (selection.length > 0) {
      navigateToElement(selection[selection.length - 1]);
    }
  };

  const resetSelection = () => {
    setSelection([]);
  };

  return {
    activeColumns,
    columns,
    setColumns,
    sortByOrder,
    sortByProperty,
    tableHeader,
    selection,
    setSelection,
    toggleColumn,
    toggleSort,
    toggleSelection,
    openSelection,
    resetSelection
  };
}
