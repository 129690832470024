import { useDebounceCallback } from '@react-hook/debounce';
import {
  FjdButton,
  FjdHeading,
  FjdMultiSelect,
  FjdNewSelect,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import { Suspense, useContext, useMemo, useState } from 'react';
import useLocalStorage from 'use-local-storage';
import { LoadingSpinner } from '../shared/LoadingSpinner';
import {
  bearbeitungsstandSelectOptions,
  bescheidstatusSelectOptions,
  SelectOption,
  useBearbeiterSelectOption
} from '../../utils/antragsuebersicht';
import AntragsuebersichtTable from './AntragsuebersichtTabelle/AntragsuebersichtTable';
import { ErrorComponentBehoerdenServer } from '../shared/Error/ErrorComponent';
import { errorContext } from '../../context/Context';
import classes from '../Schreibtisch/Schreibtisch.module.css';
import './Antragsuebersicht.css';
import { useLocalStorageTable } from '../../hooks/useLocalStorageTable';
import { useKeycloak } from '@react-keycloak/web';
import { EmptyStateComponent } from '../shared/EmptyStateComponent';
import FilterBadges from './FilterBadges/FilterBadges';
import useFilterBadges from '../../hooks/useFilterBadges';

const msgNoCases: string =
  'Um Anträge sehen und bearbeiten zu können, müssen Sie mit der Rolle “Sachbearbeitung” einer Gruppe zugeordnet sein.';

const localSotrageKeyBearbeitungsstandFilter: string = 'Bearbeitungsstand';
const localSotrageKeyBescheidsstatusFilter: string = 'Bescheidstatus';
const localSotrageKeyBearbeiterFilter: string = 'Bearbeiter';

export function Antragsuebersicht() {
  const { behError } = useContext(errorContext);
  const { currentPageKey, setLocalStorageItem } = useLocalStorageTable();
  const { keycloak } = useKeycloak();
  const [isValidating, setIsValidating] = useState<boolean>(true);

  const [bearbeitungsstandFilter, setBearbeitungsstandFilter] = useLocalStorage(
    localSotrageKeyBearbeitungsstandFilter,
    ''
  );

  const [bescheidstatusFilter, setBescheidstatusFilter] = useLocalStorage(
    localSotrageKeyBescheidsstatusFilter,
    ''
  );

  const [bearbeiterFilter, setBearbeiterFilter] = useLocalStorage<
    Array<SelectOption>
  >(localSotrageKeyBearbeiterFilter, []);

  const [searchAntraege, setSearchAntraege] = useState<string>();

  const [inputValue, setInputValue] = useState('');

  const debouncedSetSearchTerm = useDebounceCallback(
    setSearchAntraege,
    1000,
    false
  );

  const { bearbeiterSelectOptions } = useBearbeiterSelectOption(); // No error handling needed, because here errors are catched via route

  const { registerFilter, removeBadge, filterBadges, resetFilterBadges } =
    useFilterBadges();

  const clearFilter = () => {
    setLocalStorageItem(currentPageKey, '0');
    setBearbeiterFilter([]);
    setBescheidstatusFilter('');
    setBearbeitungsstandFilter('');
    setInputValue('');
    setSearchAntraege('');
    resetFilterBadges();
  };

  const onUpdateBearbeitungsstand = useMemo(
    () =>
      registerFilter(
        localSotrageKeyBearbeitungsstandFilter,
        bearbeitungsstandSelectOptions,
        setBearbeitungsstandFilter
      ),
    [registerFilter, setBearbeitungsstandFilter]
  );

  const updateBearbeitungsstandBadges = (selectedValue: Array<string>) => {
    onUpdateBearbeitungsstand(
      selectedValue,
      bearbeitungsstandFilter?.split('_') ?? []
    );
  };

  const onUpdateZuweisung = useMemo(
    () =>
      registerFilter(
        localSotrageKeyBearbeiterFilter,
        bearbeiterSelectOptions,
        setBearbeiterFilter
      ),
    [registerFilter, setBearbeiterFilter, bearbeiterSelectOptions]
  );

  const updateZuweisungFilterBadges = (selectedValue: Array<SelectOption>) => {
    onUpdateZuweisung(
      selectedValue.map((v: SelectOption) => v.value),
      bearbeiterFilter ? bearbeiterFilter.map((v: SelectOption) => v.value) : []
    );
  };

  const onUpdateBescheidstatus = useMemo(
    () =>
      registerFilter(
        localSotrageKeyBescheidsstatusFilter,
        bescheidstatusSelectOptions,
        setBescheidstatusFilter
      ),
    [registerFilter, setBescheidstatusFilter]
  );

  const updateBescheidstatusBadges = (selectedValue: Array<string>) => {
    onUpdateBescheidstatus(
      selectedValue,
      bescheidstatusFilter?.split('_') ?? []
    );
  };

  let dropdownFilterEmpty =
    bearbeitungsstandFilter?.length === 0 &&
    bescheidstatusFilter?.length === 0 &&
    bearbeiterFilter?.length === 0;

  let noFilterSelected = dropdownFilterEmpty && inputValue === '';

  const groups: Array<string> = keycloak.tokenParsed?.groups;
  const hasSachbearbeitungRole = groups.find((role) =>
    role.includes('Sachbearbeitung')
  );
  if (!hasSachbearbeitungRole) {
    return <EmptyStateComponent msg={msgNoCases} />;
  }
  if (behError.status) {
    return <ErrorComponentBehoerdenServer errorCode={behError.traceId} />;
  }

  if (!isValidating) {
    return <ErrorComponentBehoerdenServer errorCode={behError.traceId} />;
  }

  return (
    <FjdStack orientation="vertical">
      <FjdStack spacing="s">
        <FjdTextInput
          type="search"
          onChange={(event) => {
            debouncedSetSearchTerm(event.target.value);
            setInputValue(event.target.value);
          }}
          id="antraege_durchsuchen"
          testId={'antraege_durchsuchen'}
          placeholder="Antragstitel oder Auftragsnummer durchsuchen"
          maxLength={50}
          value={inputValue}
        />
        <div className={classes.filterHeading}>
          <FjdHeading id={'filter'} level={3} text={'Filter'} />
        </div>
        <FjdStack orientation="horizontal" spacing="s">
          <FjdMultiSelect
            id="bearbeitungsstand_filter"
            valueTextAsCount={false}
            name={'bearbeitungsstand_filter'}
            minWidth="250px"
            disableSearch={true}
            label={'Bearbeitungsstand'}
            placeholder="Bearbeitungsstand"
            value={
              bearbeitungsstandFilter === ''
                ? undefined
                : bearbeitungsstandFilter?.split('_')
            }
            onChange={(selectedValue) => {
              setLocalStorageItem(currentPageKey, '0');
              setBearbeitungsstandFilter(() => selectedValue.join('_'));
              updateBearbeitungsstandBadges(selectedValue);
            }}
            options={bearbeitungsstandSelectOptions.map((bearbeitungsstand) => {
              return {
                disabled: false,
                label: bearbeitungsstand.label,
                value: bearbeitungsstand.value
              };
            })}
          />
          <div
            style={{
              width: '8px'
            }}
          />
          <FjdMultiSelect
            id="bescheidstatus_filter"
            name={'bescheidstatus_filter'}
            disableSearch={true}
            valueTextAsCount={false}
            minWidth="250px"
            label={'Bescheidstatus'}
            placeholder="Bescheidstatus"
            value={
              bescheidstatusFilter === ''
                ? undefined
                : bescheidstatusFilter?.split('_')
            }
            onChange={(selectedValue) => {
              setLocalStorageItem(currentPageKey, '0');
              setBescheidstatusFilter(() => selectedValue.join('_'));
              updateBescheidstatusBadges(selectedValue);
            }}
            options={bescheidstatusSelectOptions.map(
              (bescheid: SelectOption) => {
                return {
                  disabled: false,
                  label: bescheid.label,
                  value: bescheid.value
                };
              }
            )}
          />
          <div
            style={{
              width: '8px',
              height: '5px'
            }}
          />
          <FjdNewSelect
            id="zuweisung_filter"
            name={'zuweisung_filter_name'}
            disableSearch={true}
            placeholder={'Zuweisung'}
            size="m"
            selectedItems={bearbeiterFilter}
            onChange={(selectedValue) => {
              setLocalStorageItem(currentPageKey, '0');
              setBearbeiterFilter(selectedValue);
              updateZuweisungFilterBadges(selectedValue);
            }}
            options={bearbeiterSelectOptions.map((bearbeiter: SelectOption) => {
              return {
                disabled: false,
                label: bearbeiter.label,
                value: bearbeiter.value
              };
            })}
          />
          {!noFilterSelected ? (
            <FjdButton
              appearance={'primary-link'}
              onClick={clearFilter}
              id="filter_zuruecksetzen"
              testId={'filter_zuruecksetzen'}
              label="Filter zurücksetzen"
            />
          ) : null}
        </FjdStack>
        <FilterBadges badges={filterBadges} onRemoveBadge={removeBadge} />
      </FjdStack>
      <Suspense fallback={<LoadingSpinner label={'Lade Anträge …'} />}>
        <AntragsuebersichtTable
          setIsValidating={setIsValidating}
          bescheidstatus={bescheidstatusFilter}
          bearbeitungsstand={bearbeitungsstandFilter}
          bearbeiter={
            bearbeiterFilter && bearbeiterFilter?.length > 0
              ? bearbeiterFilter[0].value
              : ''
          }
          input={inputValue}
          searchAntraege={searchAntraege}
          clearFilter={clearFilter}
        />
      </Suspense>
    </FjdStack>
  );
}
