export const sniFileExtensions: Array<string> = [
  'doc',
  'docx',
  'odt',
  'rtf',
  'xls',
  'xlsx',
  'ods',
  'ppt',
  'pptx',
  'odp',
  'jpg',
  'jpeg',
  'png',
  'bmp',
  'txt',
  'pdf',
  'dxf',
  'dwg',
  'zip'
];

export const isFileTypeValid = (
  file: File,
  initialSubmissionAbsender: string
): boolean => {
  if (
    initialSubmissionAbsender &&
    initialSubmissionAbsender.startsWith('SNi_')
  ) {
    const mimetype: string = file.type;
    const extensionSplit: Array<string> = mimetype.split('/');
    const extension = extensionSplit.at(extensionSplit.length - 1);
    if (extension === undefined) {
      return false;
    }
    if (!sniFileExtensions.includes(extension)) {
      return false;
    }
  }
  return true;
};
