import useSWR from 'swr';

export interface SerivceSummaryDto {
  count: number;
}

export interface SummaryDto {
  datamodels: SerivceSummaryDto;
  destinations: SerivceSummaryDto;
  footerLinks: SerivceSummaryDto;
  logo: SerivceSummaryDto;
  bundId: SerivceSummaryDto;
}

export default function useSummary() {
  const url: string = `/api/v1/configuration/summary`;
  const {
    data: summary,
    error,
    mutate
  } = useSWR<SummaryDto>(url, {
    suspense: false
  });

  return { summary, error, mutate };
}
