import { FjdBaseLayout } from 'fjd-react-components';
import { Footer } from '../../shared/Footer/Footer';
import { Header } from '../../shared/Header/Header';
import { MainNavigation } from '../../shared/MainNavigation/MainNavigation';

import Settings from '../Settings';

export function SettingsLayout() {
  return (
    <>
      <FjdBaseLayout
        footer={<Footer />}
        header={<Header />}
        mainNavigation={<MainNavigation />}
        fullWidthContent
        overflow={true}
      >
        <Settings />
      </FjdBaseLayout>
    </>
  );
}
