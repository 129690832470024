import styles from './BescheidModal.module.css';
import {
  FjdButtonSelectGroup,
  FjdCheckbox,
  FjdLoadingOverlay,
  FjdModal,
  FjdStack
} from 'fjd-react-components';
import { useContext, useState } from 'react';
import useTabs from '../../../../hooks/useTabs';
import DecideApplicationPostal from './DecideApplicationPostal';
import DecideApplicationDigital from './DecideApplicationDigital';
import { useSendMessage } from '../../../../hooks/useSendMessage';
import { caseDetailsDataContext } from '../../../../context/Context';
import { useSubmissionsAsNachrichten } from '../../../../hooks/useSubmissionsAsNachrichten';
import { Bescheidstatus } from '../../../../models/Status/Bescheidstatus';
import { ConfirmAction } from '../../../../models/UploadFiles/ConfirmAction';
import {
  ActionType,
  FileUploadAction
} from '../../../../models/UploadFiles/FileUploadAction';
import { UploadFiles } from '../../../../models/UploadFiles/UploadFiles';
import UploadFilesConfirmationAlert from './UploadFilesConfirmationAlert';
import { ErrorComponentBehoerdenServer } from '../../../shared/Error/ErrorComponent';
import { FjdModalProps } from 'fjd-react-components/build/components/Modal/Modal';
import { NachrichtType } from '../../../../models/Nachrichten/NachrichtModel';

interface DecideApplicationModalProps {
  readonly closeOnSecondaryButtonClick: () => void;
  readonly onContinueButtonClick: (
    applicationIsPositiveDecidedVar: boolean
  ) => void;
  readonly isPositiveDecided?: boolean;
  readonly openAbschliessenModal: () => void;
  readonly isLoadingSend: boolean;
  readonly fileDispatch: (fileAction: FileUploadAction) => void;
  readonly filesUploadstate: UploadFiles;
  readonly confirmAction: ConfirmAction;
  readonly isOpen: boolean;
}

export default function DecideApplicationModal(
  props: DecideApplicationModalProps
) {
  const filesUploadstate: UploadFiles = props.filesUploadstate;
  const caseDetails = useContext(caseDetailsDataContext);
  const version: string = caseDetails.data.version;
  const { activeTab } = useTabs();
  const canCommunicate = activeTab?.data.element.canCommunicate;
  const [sendDigitally, setSendDigitally] = useState<boolean>(canCommunicate);
  const [isApplicationExaminedAndChecked, setIsApplicationExaminedAndChecked] =
    useState<boolean>(
      props.isPositiveDecided !== undefined ||
        filesUploadstate.additionalFiles.length > 0 ||
        filesUploadstate.bescheidUpload !== undefined
    );
  const [applicationIsPositiveDecided, setApplicationIsPositiveDecided] =
    useState<boolean | undefined>(props.isPositiveDecided);
  const [applicationIsFinished, setApplicationIsFinished] =
    useState<boolean>(false);

  const { sendMessage } = useSendMessage();

  const { mutate, error: useSubmissionsAsNachrichtenError } =
    useSubmissionsAsNachrichten({
      caseId: caseDetails.data.caseId
    });

  const caseId = activeTab?.data.element.caseId;

  const sendBescheidPostalisch = () => {
    sendMessage(
      '',
      '',
      filesUploadstate.bescheidUpload
        ? [filesUploadstate.bescheidUpload, ...filesUploadstate.additionalFiles]
        : filesUploadstate.additionalFiles,
      caseId,
      true,
      NachrichtType.BESCHEID_POSTALISCH,
      applicationIsPositiveDecided
        ? Bescheidstatus.POSITIV
        : Bescheidstatus.NEGATIV,
      version,
      applicationIsFinished
    )
      .then(() => mutate())
      .finally(() => {
        props.closeOnSecondaryButtonClick();
        caseDetails?.refresh();
      });
  };

  const onSendTypeSwitchChange = () => {
    props.fileDispatch({
      type: ActionType.REMOVE_ALL_FILES,
      onConfirmation: () => {
        setIsApplicationExaminedAndChecked(false);
        setApplicationIsPositiveDecided(undefined);
        setSendDigitally(!sendDigitally);
      }
    });
  };

  const onPrimaryClickPostal = () => {
    sendBescheidPostalisch();
    if (applicationIsFinished) {
      props.openAbschliessenModal();
    }
  };

  const getPrimaryButtonLabel = (): string => {
    if (props.confirmAction.toConfirm) {
      return props.confirmAction.buttonLabel;
    }

    if (sendDigitally) {
      return 'Weiter zum Bescheidversand';
    }

    if (applicationIsFinished) {
      return 'Abschließen und postalisch bescheiden';
    }

    return 'Postalisch bescheiden';
  };

  const getPrimaryButtonDisabled = (): boolean => {
    if (props.confirmAction.toConfirm) {
      return false;
    }

    if (!isApplicationExaminedAndChecked) {
      return true;
    }

    if (sendDigitally && filesUploadstate.bescheidUpload === undefined) {
      return true;
    }

    if (applicationIsPositiveDecided === undefined) {
      return true;
    }

    if (props.isLoadingSend) {
      return true;
    }

    return false;
  };

  const onPrimaryButtonClicked = () => {
    if (props.confirmAction.toConfirm) {
      props.confirmAction.onConfirmation();
      return;
    }

    if (sendDigitally) {
      props.onContinueButtonClick(applicationIsPositiveDecided!);
    } else {
      onPrimaryClickPostal();
    }
  };

  const onSecondaryButtonClicked = () => {
    if (props.confirmAction.toConfirm) {
      props.confirmAction.onCancel();
      return;
    }

    props.closeOnSecondaryButtonClick();
    caseDetails?.refresh();
  };

  const modalPropertiesBase: Partial<FjdModalProps> = {
    appElement: document.querySelector('.fjd-base-layout') as HTMLElement,
    heading: props.confirmAction.toConfirm
      ? props.confirmAction.heading
      : 'Antrag bescheiden',
    id: 'attachment-modal',
    onClose: () => {
      props.closeOnSecondaryButtonClick();
    },
    closeOnBackdropClick: false,
    testId: 'attachment-modal',
    open: props.isOpen
  };

  const modalPropertiesError: Partial<FjdModalProps> = {
    secondaryButtonDisabled: true,
    primaryButtonLabel: 'Schließen',
    onPrimaryButtonClick: () => onSecondaryButtonClicked()
  };

  const modalPropertiesNoError: Partial<FjdModalProps> = {
    primaryButtonLabel: getPrimaryButtonLabel(),
    primaryButtonDisabled: getPrimaryButtonDisabled(),
    secondaryButtonDisabled: props.isLoadingSend,
    secondaryButtonLabel: 'Abbrechen',
    onPrimaryButtonClick: () => onPrimaryButtonClicked(),
    onSecondaryButtonClick: () => onSecondaryButtonClicked()
  };

  const modalProperties: FjdModalProps = useSubmissionsAsNachrichtenError
    ? ({ ...modalPropertiesBase, ...modalPropertiesError } as FjdModalProps)
    : ({ ...modalPropertiesBase, ...modalPropertiesNoError } as FjdModalProps);

  return (
    <FjdModal {...modalProperties}>
      <FjdLoadingOverlay loading={props.isLoadingSend}>
        {useSubmissionsAsNachrichtenError ? (
          <ErrorComponentBehoerdenServer
            errorCode={useSubmissionsAsNachrichtenError?.traceId}
          />
        ) : props.confirmAction.toConfirm ? (
          <UploadFilesConfirmationAlert confirmAction={props.confirmAction} />
        ) : (
          <span className={styles.grey}>
            <FjdStack orientation={'vertical'} spacing="3xl">
              <FjdStack orientation={'vertical'}>
                {canCommunicate && (
                  <FjdStack orientation={'horizontal'}>
                    <FjdButtonSelectGroup
                      id="btn-select-group-bescheid"
                      onChange={() => onSendTypeSwitchChange()}
                      options={[
                        {
                          text: 'Bescheid digital versenden',
                          value: 'digital-versenden',
                          selected: sendDigitally
                        },
                        {
                          text: 'Bescheid postalisch versenden',
                          value: 'postal-versenden',
                          selected: !sendDigitally
                        }
                      ]}
                    />
                  </FjdStack>
                )}
              </FjdStack>
              <FjdStack orientation={'vertical'} spacing="s">
                <h3>Bitte bestätigen Sie folgenden Hinweis</h3>
                <FjdStack orientation={'horizontal'}>
                  <FjdCheckbox
                    id={'application_is_checked_checkbox'}
                    checked={isApplicationExaminedAndChecked}
                    onChange={() => {
                      if (isApplicationExaminedAndChecked) {
                        props.fileDispatch({
                          type: ActionType.REMOVE_ALL_FILES,
                          onConfirmation: () => {
                            setIsApplicationExaminedAndChecked(
                              !isApplicationExaminedAndChecked
                            );
                            setApplicationIsPositiveDecided(undefined);
                          }
                        });
                      } else {
                        setIsApplicationExaminedAndChecked(
                          !isApplicationExaminedAndChecked
                        );
                        setApplicationIsPositiveDecided(undefined);
                      }
                    }}
                  />
                  <div>
                    Der vorliegende Antrag ist vollständig geprüft und kann
                    beschieden werden
                  </div>
                </FjdStack>
              </FjdStack>
              {sendDigitally && !props.confirmAction.toConfirm && (
                <DecideApplicationDigital
                  isApplicationExaminedAndChecked={
                    isApplicationExaminedAndChecked
                  }
                  setIsApplicationPositiveDecided={
                    setApplicationIsPositiveDecided
                  }
                  isApplicationPositiveDecided={applicationIsPositiveDecided}
                  fileDispatch={props.fileDispatch}
                  filesUploadstate={filesUploadstate}
                />
              )}
              {!sendDigitally && !props.confirmAction.toConfirm && (
                <DecideApplicationPostal
                  isApplicationExaminedAndChecked={
                    isApplicationExaminedAndChecked
                  }
                  setApplicationIsPositiveDecided={
                    setApplicationIsPositiveDecided
                  }
                  applicationIsPositiveDecided={applicationIsPositiveDecided}
                  fileDispatch={props.fileDispatch}
                  filesUploadstate={filesUploadstate}
                  isApplicationFinished={applicationIsFinished}
                  setApplicationIsFinished={setApplicationIsFinished}
                />
              )}
            </FjdStack>
          </span>
        )}
      </FjdLoadingOverlay>
    </FjdModal>
  );
}
