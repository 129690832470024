import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { VermerkDto } from '../models/Vermerk/VermerkDto';

import useAlerts from './useAlerts';
import { useApi } from './useApi';
import {
  BEHError,
  conflictErrorMsg,
  ErrorType,
  useAlertErrorMsg
} from '../utils/Error/ErrorCode';

export function useVermerk() {
  const { alert } = useAlerts();
  const { makeRequest } = useApi();
  const [loading, setLoading] = useState(false);

  const createVermerk = async (vermerk: VermerkDto, caseId: string) => {
    setLoading(true);

    try {
      await makeRequest<AxiosResponse>(
        `/api/v1/cases/${caseId}/vermerke`,
        'POST',
        undefined,
        undefined,
        undefined,
        vermerk
      );
      alert('success', 'Der Vermerk wurde erfolgreich erstellt!');
      setLoading(false);
      return;
    } catch (e) {
      const vermerkError = e as BEHError;
      alert(
        'error',
        useAlertErrorMsg.concat(` (${vermerkError?.traceId})`),
        10000,
        true
      );
      setLoading(false);
    }
  };

  const updateVermerk = async (vermerk: VermerkDto, caseId: string) => {
    setLoading(true);

    try {
      await makeRequest<AxiosResponse>(
        `/api/v1/cases/${caseId}/vermerke/${vermerk.vermerkId}`,
        'PUT',
        undefined,
        undefined,
        undefined,
        vermerk
      );
      alert('info', 'Der Vermerk wurde bearbeitet!');
      setLoading(false);
      return;
    } catch (e) {
      const vermerkError = e as BEHError;
      switch (vermerkError.type) {
        case ErrorType.BEHOERDENCLIENT_PROBLEMS_UPDATE_NOT_ALLOWED:
          alert('error', conflictErrorMsg, 10000, true);
          break;
        default:
          alert(
            'error',
            useAlertErrorMsg.concat(` (${vermerkError?.traceId})`),
            10000,
            true
          );
      }
      setLoading(false);
    }
  };

  const deleteVermerk = async (vermerk: VermerkDto, caseId: string) => {
    setLoading(true);

    try {
      await makeRequest<AxiosResponse>(
        `/api/v1/cases/${caseId}/vermerke/${vermerk.vermerkId}`,
        'DELETE',
        undefined,
        undefined,
        undefined,
        vermerk
      );

      alert('success', 'Der Vermerk wurde erfolgreich entfernt!');
      setLoading(false);
      return;
    } catch (e) {
      setLoading(false);
      const vermerkError = e as BEHError;
      const errorCode = vermerkError?.traceId;
      alert('error', useAlertErrorMsg.concat(` (${errorCode})`), 10000, true);
    }
  };

  return { createVermerk, updateVermerk, deleteVermerk, loading };
}
