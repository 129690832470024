import {
  FjdDescriptionList,
  FjdSpinner,
  FjdStack,
  FjdDescriptionListItem,
  FjdButton,
  FjdFlexSpacer
} from 'fjd-react-components';
import { useContext, useState } from 'react';
import {
  NachrichtType,
  NachrichtModel
} from '../../../../../models/Nachrichten/NachrichtModel';
import _moment from 'moment';
import { useApi } from '../../../../../hooks/useApi';
import saveAs from 'file-saver';
import { ContentTab } from '../../../Schreibtisch';
import { useProfile } from '../../../../../hooks/useProfile';
import { AttachmentModel } from '../../../../../models/Case/CaseDetailsModel';
import AttachmentPreviewModal from '../../../../shared/AttachmentPreviewModal';
import NachrichtBody from './NachrichtBody';
import { caseDetailsDataContext } from '../../../../../context/Context';
import BescheidstatusBadge from '../../../../shared/BescheidstatusBadge/BescheidstatusBadge';
import { Bescheidstatus } from '../../../../../models/Status/Bescheidstatus';
import { ErrorComponentBehoerdenServer } from '../../../../shared/Error/ErrorComponent';
import AntragBadge from '../../../../shared/AntragBadge/AntragBadge';

interface NachrichtProps {
  readonly nachricht: NachrichtModel;
  readonly setContentTabState: (val: ContentTab) => void;
  readonly antragssteller: string | undefined;
  readonly bescheidstatus?: Bescheidstatus;
}

function NachrichtContent(props: NachrichtProps) {
  const { error } = useProfile();
  const caseDetails = useContext(caseDetailsDataContext);
  const { makeRequestWithFullResponse } = useApi();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const changeToDetailsTab = (): void => {
    props.setContentTabState(ContentTab.ANTRAGSDETAILS);
  };

  const initialSubmissionId: string =
    caseDetails.data.initialSubmission.submissionId;

  const fileNamesStack: JSX.Element = (
    <FjdStack
      orientation="vertical"
      key={'fileNamesStack'}
      id={'fileNamesStack'}
    >
      {props.nachricht.attachment.map((attachment: AttachmentModel) => (
        <div
          key={`fileNamesStack_${attachment.id}`}
          id={`fileNamesStack_${attachment.id}`}
          style={{ height: '24px' }}
        >
          <AttachmentPreviewModal
            onClose={() => setOpenPreviewModal(false)}
            attachmentId={attachment.id}
            submissionId={props.nachricht.submissionId}
            caseId={caseDetails?.data?.caseId}
            isOpen={openPreviewModal && props.nachricht !== undefined}
          />
          <FjdStack orientation={'horizontal'}>
            <FjdButton
              appearance={
                attachment.mimeType === 'application/pdf'
                  ? 'primary-link'
                  : 'link'
              }
              label={attachment.description + attachment.filetype}
              size="s"
              onClick={() =>
                attachment.mimeType === 'application/pdf'
                  ? setOpenPreviewModal(!openPreviewModal)
                  : {}
              }
            />
            {attachment.isBescheid && props.bescheidstatus && (
              <BescheidstatusBadge
                bescheidstatus={props.bescheidstatus}
                fjdBadgeSize={'xs'}
                badgeLabel={'Bescheid'}
              />
            )}
            {props.nachricht.submissionId === initialSubmissionId && (
              <AntragBadge data-testid="antragBadge-Nachricht" />
            )}
          </FjdStack>
        </div>
      ))}
    </FjdStack>
  );

  const previewsAndDownloadsStack: JSX.Element = (
    <FjdStack
      key={'previewsAndDownloadsStack'}
      id={'previewsAndDownloadsStack'}
      orientation="vertical"
    >
      {props.nachricht?.attachment.map((attachment: AttachmentModel) => (
        <FjdStack
          key={`previewsAndDownloadsStack_${attachment.id}`}
          id={`previewsAndDownloadsStack_${attachment.id}`}
          orientation="horizontal"
        >
          {attachment.mimeType === 'application/pdf' ? (
            <div
              key={`previewsAndDownloadsStack_view_${attachment.id}`}
              id={`previewsAndDownloadsStack_view_${attachment.id}`}
              style={{ height: '24px' }}
            >
              <AttachmentPreviewModal
                onClose={() => setOpenPreviewModal(false)}
                attachmentId={attachment.id}
                submissionId={props.nachricht?.submissionId}
                caseId={caseDetails?.data?.caseId}
                isOpen={openPreviewModal && props.nachricht !== undefined}
              />
              <FjdButton
                appearance="primary-link"
                hideLabel
                iconLeft="view"
                label={'Dokument anzeigen'}
                size="s"
                onClick={() => {
                  setOpenPreviewModal(!openPreviewModal);
                }}
              />
            </div>
          ) : (
            <div
              key={`previewsAndDownloadsStack_view_${attachment.id}`}
              id={`previewsAndDownloadsStack_view_${attachment.id}`}
              style={{ height: '24px', width: '2rem' }}
            ></div>
          )}

          <div
            key={`previewsAndDownloadsStack_download_${attachment.id}`}
            id={`previewsAndDownloadsStack_download_${attachment.id}`}
            style={{ height: '24px' }}
          >
            <FjdButton
              appearance="primary-link"
              hideLabel
              iconLeft="download"
              label={'Dokument herunterladen'}
              size="s"
              onClick={() => {
                downloadFile(
                  attachment.description + attachment.filetype,
                  attachment.id
                );
              }}
            />
          </div>
        </FjdStack>
      ))}
    </FjdStack>
  );

  const showAllDocumentsStack: JSX.Element = (
    <FjdStack
      key={'showAllDocumentsStack'}
      id={'showAllDocumentsStack'}
      orientation="vertical"
    >
      {props.nachricht?.attachment.map(
        (attachment: AttachmentModel, index: number) =>
          index === 0 ? (
            <div
              key={`showAllDocuments_${attachment.id}`}
              id={`showAllDocuments_${attachment.id}`}
              style={{ height: '24px' }}
            ></div>
          ) : (
            <div
              key={`showAllDocuments_${attachment.id}`}
              id={`showAllDocuments_${attachment.id}`}
              style={{ height: '24px' }}
            ></div>
          )
      )}
    </FjdStack>
  );

  const filePart: JSX.Element = (
    <>
      <FjdStack orientation="horizontal">
        {fileNamesStack}
        {previewsAndDownloadsStack}
        <FjdFlexSpacer />
        {showAllDocumentsStack}
      </FjdStack>
      <br />
      <FjdButton
        appearance="primary-link"
        iconLeft="launch"
        label={'zu allen Dokumenten'}
        size="s"
        onClick={() => props.setContentTabState(ContentTab.DOKUMENTE)}
      />
    </>
  );
  const isPostalischBeschieden = (nachrichtType: NachrichtType): boolean => {
    return nachrichtType === NachrichtType.BESCHEID_POSTALISCH;
  };
  const downloadFile = async (filename: string, attachmentId: string) => {
    const file = await makeRequestWithFullResponse(
      `/api/v1/cases/${caseDetails?.data?.caseId}/submissions/${props.nachricht?.submissionId}/attachments/${attachmentId}/download`,
      'GET',
      undefined,
      'application/octet-stream',
      undefined,
      undefined,
      {
        responseType: 'blob'
      }
    );
    saveAs(file.data as Blob, filename, { autoBom: false });
  };

  if (error) {
    return <ErrorComponentBehoerdenServer />;
  } else if (props.nachricht) {
    return (
      <FjdStack orientation={'vertical'}>
        <FjdDescriptionList>
          <FjdDescriptionListItem
            useParagraphs={true}
            description={_moment
              .utc(props.nachricht.createdOn)
              .local()
              .format('DD.MM.YYYY • HH:mm')}
            term={
              props.nachricht.isBescheid &&
              isPostalischBeschieden(props.nachricht.nachrichtType)
                ? 'Beschieden am'
                : 'Gesendet am'
            }
          />
          {props.nachricht.isVonBehoerde && (
            <FjdDescriptionListItem
              useParagraphs={true}
              description={props.nachricht.absenderName}
              term={'Absender:in'}
            />
          )}
          {!props.nachricht.isVonBehoerde &&
            !(
              props.nachricht.isBescheid &&
              isPostalischBeschieden(props.nachricht.nachrichtType)
            ) && (
              <FjdDescriptionListItem
                useParagraphs={true}
                description={
                  props.nachricht.isVonBehoerde
                    ? props.antragssteller
                    : props.nachricht.organisationUnitName
                }
                term={'Empfänger:in'}
              />
            )}
          {!(
            props.nachricht.isBescheid &&
            isPostalischBeschieden(props.nachricht.nachrichtType)
          ) && (
            <FjdDescriptionListItem
              useParagraphs={true}
              description={props.nachricht.betreff}
              term={'Betreff'}
            />
          )}
          {props.nachricht.hasAttachments && (
            <FjdDescriptionListItem
              useParagraphs={true}
              description={filePart}
              term={'Anlagen'}
            />
          )}
          <NachrichtBody
            onLinkClicked={changeToDetailsTab}
            nachricht={props.nachricht}
          />
        </FjdDescriptionList>
      </FjdStack>
    );
  } else {
    return <FjdSpinner />;
  }
}

export default NachrichtContent;
