import { ReactNode, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';
import useConfigurationStep, {
  ConfigurationStep
} from '../../hooks/useConfigurationStep';
import { RouteKey, routes } from '../router';

interface GuardedRouteProps {
  readonly children?: ReactNode | Array<ReactNode>;
  [prop: string]: any;
}

export function GuardedRoute({ children, ...rest }: GuardedRouteProps) {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  const { configurationStep } = useConfigurationStep();

  const isAdministator: boolean =
    keycloak.tokenParsed?.['groups'].includes('/Administration');

  const redirect: boolean =
    isAdministator &&
    initialized &&
    configurationStep?.configurationStep !== ConfigurationStep.FINISH;

  useEffect(() => {
    if (configurationStep && redirect) {
      navigate(`${routes[RouteKey.CONFIGASSISTANT].path}`);
    } else if (rest.path === routes[RouteKey.CONFIGASSISTANT].path) {
      navigate(routes[RouteKey.ANTRAEGE].path);
    }
  }, [redirect, navigate, configurationStep, rest.path]);

  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak]);

  return keycloak.authenticated ? <Outlet /> : null;
}

export default GuardedRoute;
