import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './utils/keycloak';
import { HelmetProvider } from 'react-helmet-async';
import { FjdSpinner } from 'fjd-react-components';
import './global.css';
import { Modal } from '@efast_public/fjd-component-library';
const container = document.getElementById('app');
const root = createRoot(container!);

Modal.setAppElement('#app');

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{ checkLoginIframe: false }}
  >
    <React.StrictMode>
      <HelmetProvider>
        <Suspense fallback={<FjdSpinner size="s" />}>
          <App />
        </Suspense>
      </HelmetProvider>
    </React.StrictMode>
  </ReactKeycloakProvider>
);
