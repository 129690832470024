import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  Button,
  FileDropField,
  FileList,
  Icon,
  Spacer,
  Stack,
  Title,
  Typography
} from '@efast_public/fjd-component-library';
import { FormatUtil } from '../../../../utils/formatUtil';

import forge from 'node-forge';
import {
  useBundIdDelete,
  useBundIdGet,
  useBundIdPost
} from '../../../../hooks/useBundId';
import useAlerts from '../../../../hooks/useAlerts';
import './BundId.css';
import { convertBlobToTextString } from '../../../../utils/fileToString';
import { FjdAlert, FjdLoadingOverlay } from 'fjd-react-components';
import { IBundIdGetDto } from '../../../../models/ZBP/BundId';

type AccordionLabel = 'BundID-Postfach-Zertifikat' | 'Private Key';
type DataTestId = 'uploadFieldCertificate' | 'uploadFieldPrivateKey';
type FileType = 'zbpCertificate' | 'zbpPrivateKey';

interface KeyPairFileState {
  readonly file?: File | undefined;
  readonly validity?: Date | undefined;
  readonly uploadDate?: Date | undefined;
  readonly isValid?: boolean | undefined;
}

interface CertificateUploaderSectionProps {
  readonly fileType: FileType;
  readonly fileData: KeyPairFileState | undefined;
  readonly handleFileChange: (file: File, fileType: FileType) => void;
  readonly onFileDelete: (fileType: FileType) => void;
}

type AlertType = 'error' | 'info';

type AlertTitle =
  | 'errorTitleCertificateNotValid'
  | 'infoTitleOnCertificateIsNeeded';

interface AlertProps {
  readonly titleKind: AlertTitle;
  readonly intent: AlertType;
}

function isCertificateValid(certificateExpirationTime: Date): boolean {
  const validityDate = new Date(certificateExpirationTime);
  const today = new Date();
  return validityDate > today;
}

function InfoOrErrorBannerAlert(props: AlertProps) {
  const titleOnError =
    'Das BundID-Postfach-Zertifikat ist abgelaufen. Für die Kommunikation brauchen Sie ein aktuelles Zertifikat.';
  const infoTitle =
    'Für die Kommunikation müssen Sie ein aktuelles BundID-Postfach-Zertifikat und den Private Key hochladen. Wie Sie diese erhalten, erfahren Sie im Self-Service-Portal der BundID. Bitte beachten Sie, dass das Zertifikat nur begrenzt gültig ist.';

  const alertTitle =
    props.titleKind === 'errorTitleCertificateNotValid'
      ? titleOnError
      : infoTitle;

  return (
    <FjdAlert
      closable={false}
      appearance="muted"
      intent={props.intent}
      title={alertTitle}
    />
  );
}

interface InfoOrErrorAlertContainerProps {
  readonly zbpKeyPair: IBundIdGetDto | undefined;
}

function InfoOrErrorBannerAlertContainer(
  props: InfoOrErrorAlertContainerProps
): ReactElement {
  return (
    <>
      {!props.zbpKeyPair ? (
        <InfoOrErrorBannerAlert
          titleKind="infoTitleOnCertificateIsNeeded"
          intent="info"
        />
      ) : (
        !isCertificateValid(props.zbpKeyPair.certificateExpirationTime) && (
          <InfoOrErrorBannerAlert
            titleKind="errorTitleCertificateNotValid"
            intent="error"
          />
        )
      )}
    </>
  );
}

function KeyPairUploadFields(props: CertificateUploaderSectionProps) {
  const label: AccordionLabel =
    props.fileType === 'zbpCertificate'
      ? 'BundID-Postfach-Zertifikat'
      : 'Private Key';
  const dataTestId: DataTestId =
    props.fileType === 'zbpCertificate'
      ? 'uploadFieldCertificate'
      : 'uploadFieldPrivateKey';

  return (
    <Stack>
      <Stack direction="row" spacing="xs" alignItems="center">
        <Icon
          data-testid={`statusIcon-${dataTestId}`}
          name={
            props.fileData
              ? props.fileData.isValid === false
                ? 'error-filled'
                : 'checkmark-filled'
              : 'circle-dash'
          }
          variant={
            props.fileData
              ? props.fileData.isValid === false
                ? 'error'
                : 'success'
              : undefined
          }
          title="Status: ok"
        />
        <Title level={6}>{label}</Title>
      </Stack>
      <Stack direction="row">
        <div style={{ paddingLeft: '1.8rem', marginBottom: '0.5rem' }}>
          <Typography variant="secondary" size="s">
            {props.fileData && props.fileData.uploadDate
              ? `Hochgeladen am: ${FormatUtil.formatDateTime(props.fileData.uploadDate, 'DD.MM.YYYY')}${props.fileData.validity ? `, Gültig bis: ${FormatUtil.formatDateTime(props.fileData.validity, 'DD.MM.YYYY')}` : ', Gültig bis: unbegrenzt'}`
              : null}
          </Typography>
        </div>
      </Stack>
      {!props.fileData ? (
        <FileDropField
          className="uploadField"
          data-testid={`uploadField-${dataTestId}`}
          label="Neue Datei auswählen"
          dropHelperText={'oder in dieses Feld ziehen'}
          onAdd={(files) => props.handleFileChange(files[0], props.fileType)}
        />
      ) : (
        <FileList>
          <FileList.FileItem
            data-testid={`fileItem-${dataTestId}`}
            file={{ name: props.fileData?.file?.name ?? '' }}
            onDelete={() => props.onFileDelete(props.fileType)}
          />
        </FileList>
      )}
      <Spacer size="xs" />
    </Stack>
  );
}

export default function BundIdSettings(): ReactElement {
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
    useState<boolean>(false);
  const [zbpCertificate, setZbpCertificate] = useState<
    KeyPairFileState | undefined
  >();
  const [zbpPrivateKey, setZbpPrivateKey] = useState<
    KeyPairFileState | undefined
  >();

  const { alert } = useAlerts();

  const { uploadBundIdKeyPair, isUploading } = useBundIdPost();
  const {
    data: zbpKeyPair,
    mutateBundIdKeyPair,
    isValidating
  } = useBundIdGet();
  const { deleteBundIdKeyPair, isDeleting } = useBundIdDelete();

  useEffect(() => {
    if (zbpKeyPair) {
      setZbpCertificate({
        file: new File([''], zbpKeyPair.certificateFileName, {
          type: 'text/plain'
        }),
        uploadDate: zbpKeyPair.certificateUploadTime,
        validity: zbpKeyPair.certificateExpirationTime,
        isValid: isCertificateValid(zbpKeyPair.certificateExpirationTime)
      });
      setZbpPrivateKey({
        file: new File([''], zbpKeyPair.privateKeyFileName, {
          type: 'text/plain'
        }),
        uploadDate: zbpKeyPair.privateKeyUploadTime,
        validity: undefined
      });
    }
  }, [zbpKeyPair]);

  const compareDateTime = (date1: Date, date2: Date): boolean => {
    return date1.getTime() === date2.getTime();
  };

  const setAlertOnSuccess = useCallback(
    (
      hasBundIdNotification: boolean,
      hasPrivateKeyNotification: boolean,
      hasCertificateAndPrivateDeleted: boolean
    ) => {
      if (!hasCertificateAndPrivateDeleted) {
        alert(
          'success',
          `Sie haben ${hasBundIdNotification ? 'das BundID-Postfach-Zertifikat' : ''} ${hasBundIdNotification && hasPrivateKeyNotification ? 'und' : ''} ${hasPrivateKeyNotification ? 'Private Key' : ''} erfolgreich hinterlegt.`,
          10000,
          true
        );
      } else if (hasCertificateAndPrivateDeleted) {
        alert(
          'success',
          `Das BundID-Postfach-Zertifikat und der Private Key wurden gelöscht.`,
          10000,
          true
        );
      }
    },
    [alert]
  );

  const getPrecondition: () => Promise<void> =
    useCallback(async (): Promise<void> => {
      if (!zbpCertificate && !zbpPrivateKey) {
        try {
          await deleteBundIdKeyPair();
          setAlertOnSuccess(false, false, true);
          try {
            await mutateBundIdKeyPair();
          } catch (e) {
            console.error(
              'Error while new loading after certificate and private key delete: ',
              e
            );
            alert('error', 'Fehler beim erneuten Laden der Daten.', 10000);
          }
        } catch (e) {
          console.error('Error on certificate and private key delete: ', e);
          alert(
            'error',
            'Fehler beim Löschen, bitte versuchen Sie es erneut',
            10000
          );
        }
        return;
      }

      const isCertificateUploadEqual: boolean =
        zbpCertificate?.uploadDate && zbpKeyPair
          ? compareDateTime(
              new Date(zbpCertificate.uploadDate),
              new Date(zbpKeyPair.certificateUploadTime)
            )
          : true;

      const isPrivateKeyUploadEqual: boolean =
        zbpPrivateKey?.uploadDate && zbpKeyPair
          ? compareDateTime(
              new Date(zbpPrivateKey.uploadDate),
              new Date(zbpKeyPair.privateKeyUploadTime)
            )
          : true;

      const zbpCertificateText: string | undefined =
        await convertBlobToTextString(zbpCertificate?.file);

      const zbpPrivateKeyText: string | undefined =
        await convertBlobToTextString(zbpPrivateKey?.file);

      if (zbpKeyPair) {
        if (!isCertificateUploadEqual && !isPrivateKeyUploadEqual) {
          try {
            await uploadBundIdKeyPair({
              certificate: zbpCertificateText,
              privateKey: zbpPrivateKeyText,
              certificateFileName: zbpCertificate?.file?.name || '',
              privateKeyFileName: zbpPrivateKey?.file?.name || ''
            });
            setAlertOnSuccess(true, true, false);
            try {
              await mutateBundIdKeyPair();
            } catch (e) {
              console.error(
                'Error while new loading after certificate and private key upload: ',
                e
              );
              alert('error', 'Fehler beim erneuten Laden der Daten.', 10000);
            }
          } catch (e) {
            console.error('Error on certificate and private key upload: ', e);
            alert(
              'error',
              'Fehler beim Speichern des BundID-Postfach-Zertifikats und des Private Keys, bitte versuchen Sie es erneut.',
              10000
            );
          }
        } else if (!isCertificateUploadEqual && isPrivateKeyUploadEqual) {
          try {
            await uploadBundIdKeyPair({
              certificate: zbpCertificateText,
              certificateFileName: zbpCertificate?.file?.name || ''
            });
            setAlertOnSuccess(true, false, false);
            try {
              await mutateBundIdKeyPair();
            } catch (e) {
              console.error(
                'Error while new loading after certificate upload: ',
                e
              );
              alert('error', 'Fehler beim erneuten Laden der Daten.', 10000);
            }
          } catch (e) {
            console.error('Error on certificate upload: ', e);
            alert(
              'error',
              'Fehler beim Speichern des BundID-Postfach-Zertifikats, bitte versuchen Sie es erneut.',
              10000
            );
          }
        } else if (isCertificateUploadEqual && !isPrivateKeyUploadEqual) {
          try {
            await uploadBundIdKeyPair({
              privateKey: zbpPrivateKeyText,
              privateKeyFileName: zbpPrivateKey?.file?.name || ''
            });
            setAlertOnSuccess(false, true, false);
            try {
              await mutateBundIdKeyPair();
            } catch (e) {
              console.error(
                'Error while new loading after private key upload: ',
                e
              );
              alert('error', 'Fehler beim erneuten Laden der Daten.', 10000);
            }
          } catch (e) {
            console.error('Error on private key upload: ', e);
            alert(
              'error',
              'Fehler beim Speichern des Private Keys, bitte versuchen Sie es erneut.',
              10000
            );
          }
        }
      } else {
        if (zbpCertificate?.file && zbpPrivateKey?.file) {
          try {
            await uploadBundIdKeyPair({
              certificate: zbpCertificateText,
              privateKey: zbpPrivateKeyText,
              certificateFileName: zbpCertificate?.file?.name || '',
              privateKeyFileName: zbpPrivateKey?.file?.name || ''
            });
            setAlertOnSuccess(true, true, false);
            try {
              await mutateBundIdKeyPair();
            } catch (e) {
              console.error(
                'Error while new loading after dropping certificate and private key and upload: ',
                e
              );
              alert('error', 'Fehler beim erneuten Laden der Daten.', 10000);
            }
          } catch (e) {
            console.error('Error on certificate and private key upload.', e);
            alert(
              'error',
              'Fehler beim Speichern, bitte versuchen Sie es erneut',
              10000
            );
          }
        }
      }
    }, [
      zbpCertificate,
      zbpPrivateKey,
      zbpKeyPair,
      deleteBundIdKeyPair,
      setAlertOnSuccess,
      mutateBundIdKeyPair,
      uploadBundIdKeyPair,
      alert
    ]);

  const handleFileChange = async (file: File, fileType: FileType) => {
    if (!file) {
      return;
    }

    try {
      const filePEM = await file.text();
      if (fileType === 'zbpCertificate') {
        const cert = forge.pki.certificateFromPem(filePEM);

        const isRSA =
          cert?.publicKey && 'n' in cert.publicKey && 'e' in cert.publicKey;

        if (!isRSA) {
          alert(
            'error',
            'Ungültiges Dateiformat. (Es wird nur eine Datei mit X.509 Formatierung akzeptiert.)',
            10000,
            true
          );
          return;
        }

        if (!isCertificateValid(cert.validity.notAfter)) {
          alert(
            'error',
            'Das BundID-Postfach-Zertifikat ist abgelaufen. Für die Kommunikation brauchen Sie ein aktuelles Zertifikat.'
          );
          return;
        }

        setZbpCertificate({
          file,
          validity: cert.validity.notAfter,
          uploadDate: new Date(),
          isValid: isCertificateValid(cert.validity.notAfter)
        });
      } else if (fileType === 'zbpPrivateKey') {
        try {
          forge.pki.privateKeyFromPem(filePEM);
        } catch (error) {
          alert(
            'error',
            'Ungültiges Dateiformat. Die Datei ist kein Private Key.',
            10000,
            true
          );
          return;
        }

        setZbpPrivateKey({
          file,
          validity: undefined,
          uploadDate: new Date()
        });
      }
    } catch (error) {
      alert(
        'error',
        'Fehler beim Lesen der Datei: Bitte stellen Sie sicher, dass die Datei im PEM-Format vorliegt.',
        10000,
        true
      );
      console.error('Error on reading file:', error);
    }
  };

  const handleFileDelete: (fileType: FileType) => void = (
    fileType: FileType
  ) => {
    if (fileType === 'zbpCertificate') {
      setZbpCertificate(undefined);
    } else {
      setZbpPrivateKey(undefined);
    }
  };

  const handleSaveButtonDisabledStatus = useCallback(() => {
    if (!zbpCertificate && !zbpPrivateKey) {
      setIsSaveButtonDisabled(false);
    } else if (zbpCertificate && zbpPrivateKey) {
      setIsSaveButtonDisabled(false);
    } else {
      setIsSaveButtonDisabled(true);
    }
  }, [zbpCertificate, zbpPrivateKey]);

  useEffect(() => {
    handleSaveButtonDisabledStatus();
  }, [handleSaveButtonDisabledStatus]);

  return (
    <FjdLoadingOverlay loading={isDeleting || isValidating || isUploading}>
      <div style={{ width: '50rem' }}>
        <Stack direction="column" spacing="l">
          <InfoOrErrorBannerAlertContainer zbpKeyPair={zbpKeyPair} />
          <div style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
            Konfiguration des BundID-Postfach-Zertifikats und des Private Keys
          </div>
          <div className="descriptionText">
            Bitte laden Sie jeweils im vorgesehenem Feld das
            BundID-Postfach-Zertifikat und den Private Key der BundID hoch.
          </div>

          <Stack alignItems="stretch" spacing="l">
            {(['zbpCertificate', 'zbpPrivateKey'] as const).map((fileType) => (
              <KeyPairUploadFields
                key={fileType}
                fileType={fileType}
                fileData={
                  fileType === 'zbpCertificate' ? zbpCertificate : zbpPrivateKey
                }
                handleFileChange={handleFileChange}
                onFileDelete={(fileType: FileType) =>
                  handleFileDelete(fileType)
                }
              />
            ))}
          </Stack>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Stack direction="row" spacing="s">
              <Button
                disabled={isSaveButtonDisabled}
                variant="primary"
                data-testid="primary_button-save_bundID"
                onClick={getPrecondition}
              >
                Speichern
              </Button>
            </Stack>
          </div>
        </Stack>
      </div>
    </FjdLoadingOverlay>
  );
}
