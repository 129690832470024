import {
  FjdStack,
  FjdButton,
  FjdLoadingOverlay,
  FjdVirtualizedTable,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow,
  FjdHeading,
  FjdVirtualizedTableCell,
  FjdSpacer
} from 'fjd-react-components';
import _moment from 'moment';
import React, { useContext, useState } from 'react';
import { useApi } from '../../../../hooks/useApi';
import { formatByte } from '../../../../utils/antragsuebersicht';
import { AttachmentExtendedModel } from '../../../../models/Case/CaseDetailsModel';
import styles from './dokumente.module.css';
import { saveAs } from 'file-saver';
import AttachmentPreviewModal from '../../../shared/AttachmentPreviewModal';
import { caseDetailsDataContext } from '../../../../context/Context';
import BescheidstatusBadge from '../../../shared/BescheidstatusBadge/BescheidstatusBadge';
import { useAttachmentsDokumenteTab } from '../../../../hooks/useAttachmentsDokumenteTab';
import { Bescheidstatus } from '../../../../models/Status/Bescheidstatus';
import { ErrorComponentBehoerdenServer } from '../../../shared/Error/ErrorComponent';
import AntragBadge from '../../../shared/AntragBadge/AntragBadge';
import { AxiosResponse } from 'axios';

const Antragsdokumente = () => {
  const caseDetails = useContext(caseDetailsDataContext);
  const initialSubmissionId: string =
    caseDetails.data.initialSubmission.submissionId;

  const { attachments, isValidating, error } = useAttachmentsDokumenteTab({
    caseId: caseDetails.data.caseId
  });
  const isBescheidPositivOrNegativ: boolean =
    caseDetails?.data.bescheidstatus === Bescheidstatus.POSITIV ||
    caseDetails?.data.bescheidstatus === Bescheidstatus.NEGATIV;

  const { makeRequestWithFullResponse } = useApi();
  const [openPreviewModal, setOpenPreviewModal] = useState<
    AttachmentExtendedModel | undefined
  >(undefined);

  const downloadFile = async (
    filename: string,
    submissionId: string,
    attachmentId: string
  ) => {
    const file: AxiosResponse<Blob, any> =
      await makeRequestWithFullResponse<Blob>(
        `/api/v1/cases/${caseDetails?.data?.caseId}/submissions/${submissionId}/attachments/${attachmentId}/download`,
        'GET',
        undefined,
        'application/octet-stream',
        undefined,
        undefined,
        {
          responseType: 'blob'
        }
      );
    saveAs(file.data, filename, { autoBom: false });
  };

  let firstRowBackgroundColorGrey = true;

  let dokumentenTableView = (
    <FjdLoadingOverlay loading={isValidating}>
      <FjdStack spacing="s">
        {attachments && (
          <FjdVirtualizedTable
            disableVirtualization
            header={
              <FjdVirtualizedTableRow>
                <FjdVirtualizedTableCol maxWidth="10rem">
                  Datum
                </FjdVirtualizedTableCol>
                <FjdVirtualizedTableCol>Name</FjdVirtualizedTableCol>
                <FjdVirtualizedTableCol maxWidth="7.5rem">
                  Typ
                </FjdVirtualizedTableCol>
                <FjdVirtualizedTableCol maxWidth="7.5rem">
                  Größe
                </FjdVirtualizedTableCol>
                <FjdVirtualizedTableCol maxWidth="7.5rem">
                  Aktionen
                </FjdVirtualizedTableCol>
              </FjdVirtualizedTableRow>
            }
          >
            {attachments.length === 0 && (
              <FjdVirtualizedTableRow>
                <FjdVirtualizedTableCell>
                  {'Keine Dokumente gefunden.'}
                </FjdVirtualizedTableCell>
              </FjdVirtualizedTableRow>
            )}
            {attachments.map((document) => (
              <FjdVirtualizedTableRow
                even={
                  (firstRowBackgroundColorGrey = !firstRowBackgroundColorGrey)
                }
                key={document?.id}
              >
                <FjdVirtualizedTableCell maxWidth="10rem">
                  {_moment
                    .utc(document.createdOn)
                    .local()
                    .format('DD.MM.YYYY, HH:mm')}
                </FjdVirtualizedTableCell>
                <FjdVirtualizedTableCell>
                  <div>
                    <FjdStack
                      orientation="horizontal"
                      spacing="xl"
                      className={styles.container}
                    >
                      <div style={{ whiteSpace: 'initial' }}>
                        {document.description + document.filetype}
                      </div>

                      <FjdStack
                        orientation="horizontal"
                        spacing="xl"
                        className={styles.container}
                      >
                        <span
                          className={
                            document.isVonBehoerde
                              ? styles.file_uploaded_by_authority
                              : styles.file_not_uploaded_by_authority
                          }
                        >
                          {document.isVonBehoerde
                            ? `von ${document.absenderName}`
                            : 'von Antragsteller:in'}
                        </span>

                        {document.isBescheid && isBescheidPositivOrNegativ && (
                          <BescheidstatusBadge
                            bescheidstatus={caseDetails?.data.bescheidstatus}
                            fjdBadgeSize={'xs'}
                            badgeLabel={'Bescheid'}
                          />
                        )}
                        {document.submissionId === initialSubmissionId && (
                          <AntragBadge data-testid="antragBadge" />
                        )}
                      </FjdStack>
                    </FjdStack>
                  </div>
                </FjdVirtualizedTableCell>
                <FjdVirtualizedTableCell maxWidth="7.5rem">
                  {document.filetype.toUpperCase()}
                </FjdVirtualizedTableCell>
                <FjdVirtualizedTableCell maxWidth="7.5rem">
                  {formatByte(`${document.size}`)}
                </FjdVirtualizedTableCell>
                <FjdVirtualizedTableCell maxWidth="7.5rem">
                  <FjdStack orientation="horizontal" spacing="xs">
                    {document.filetype === '.pdf' ? (
                      <FjdButton
                        appearance="primary-link"
                        hideLabel
                        iconLeft="view"
                        label={'Dokument anzeigen'}
                        size="s"
                        testId={`preview_${document.id}`}
                        onClick={() => {
                          setOpenPreviewModal(document);
                        }}
                      />
                    ) : (
                      <FjdSpacer size={'s'} />
                    )}
                    <FjdButton
                      appearance="primary-link"
                      hideLabel
                      iconLeft="download"
                      label={'Dokument herunterladen'}
                      size="s"
                      testId={`download_${document.id}`}
                      onClick={() =>
                        downloadFile(
                          document.description + document.filetype,
                          document.submissionId,
                          document.id
                        )
                      }
                    />
                  </FjdStack>
                </FjdVirtualizedTableCell>
              </FjdVirtualizedTableRow>
            ))}
          </FjdVirtualizedTable>
        )}
      </FjdStack>
      <AttachmentPreviewModal
        onClose={() => setOpenPreviewModal(undefined)}
        attachmentId={openPreviewModal?.id}
        submissionId={openPreviewModal?.submissionId}
        caseId={caseDetails?.data?.caseId}
        isOpen={openPreviewModal !== undefined}
      />
    </FjdLoadingOverlay>
  );

  if (error) {
    return <ErrorComponentBehoerdenServer errorCode={error?.traceId} />;
  }

  return (
    <React.Fragment>
      <FjdHeading text={'Angehängte Dokumente'} visualLevel={2} />
      {dokumentenTableView}
    </React.Fragment>
  );
};

export default Antragsdokumente;
