import { FjdStack, FjdRadio, FjdCheckbox } from 'fjd-react-components';
import {
  KeycloakAssignedGroups,
  KeycloakGroup
} from '../../../../../hooks/useInvite';
import './GroupRoleRow.css';

interface GroupRoleRowProps {
  readonly keycloakAssignedGroups: KeycloakAssignedGroups;
  readonly onChangeGroupCheckbox: (checked: boolean, groupName: string) => void;
  readonly onRoleRadioChanged: (
    checked: boolean,
    groupName: string,
    roles: Array<KeycloakGroup>
  ) => void;
  readonly isRoleRadioDisabled: (groupName: string) => boolean;
  readonly isRoleRadioChecked: (
    groupName: string,
    roleIds: Array<string>
  ) => boolean;
}

function GroupRoleRow(props: GroupRoleRowProps): JSX.Element {
  const groupName = props.keycloakAssignedGroups.groupName;
  return (
    <FjdStack orientation="horizontal" spacing="m">
      <div className="checkBox">
        <FjdCheckbox
          key={`${groupName}_checkbox`}
          label={`${groupName}:`}
          id={`group_${groupName}`}
          onChange={(event) => {
            props.onChangeGroupCheckbox(
              event.target.checked,
              props.keycloakAssignedGroups.groupName
            );
          }}
        ></FjdCheckbox>
      </div>
      <FjdStack orientation="horizontal" className="radioButtons">
        {props.keycloakAssignedGroups.subGroups
          ? props.keycloakAssignedGroups.subGroups
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((subGroup: KeycloakGroup) => {
                const keycloakSubGroups =
                  props.keycloakAssignedGroups.subGroups;
                return (
                  <FjdRadio
                    key={subGroup.id}
                    disabled={props.isRoleRadioDisabled(groupName)}
                    value={subGroup.id}
                    label={subGroup.name}
                    id={`subgroupRadio_${subGroup.id}`}
                    checked={props.isRoleRadioChecked(
                      groupName,
                      subGroup.name === 'Teamleitung'
                        ? keycloakSubGroups.map((role) => role.id)
                        : [subGroup.id]
                    )}
                    name={groupName}
                    onChange={(event) => {
                      props.onRoleRadioChanged(
                        event.target.checked,
                        groupName,
                        subGroup.name === 'Teamleitung'
                          ? keycloakSubGroups
                          : [subGroup]
                      );
                    }}
                  ></FjdRadio>
                );
              })
          : ''}
      </FjdStack>
    </FjdStack>
  );
}

export default GroupRoleRow;
