import { TableColumn, useTable } from '../../../hooks/useTable';

export const antragsuebersichtTableDefaultColumns: Array<TableColumn> = [
  {
    id: 'selection',
    active: true,
    header: '',
    fixed: true,
    width: '2.75rem',
    minWidth: '2.75rem',
    sortable: false
  },
  {
    id: 'status',
    active: true,
    header: 'Status',
    fixed: false,
    width: '6rem',
    minWidth: '6rem',
    sortKey: 'status',
    sortable: true
  },
  {
    id: 'createdOn',
    active: true,
    header: 'Datum',
    fixed: true,
    width: '10rem',
    resizable: true,
    sort: 'desc',
    sortKey: 'createdOn',
    sortable: true
  },
  {
    id: 'titel',
    active: true,
    header: 'Antragstitel',
    fixed: true,
    width: '48rem',
    resizable: true,
    sortable: true,
    sortKey: 'name'
  },
  {
    id: 'auftragsnummer',
    active: true,
    header: 'Auftragsnummer',
    fixed: false,
    width: '16rem',
    minWidth: '3rem',
    resizable: true,
    sortable: true,
    sortKey: 'auftragsnummer'
  },
  {
    id: 'anlageDto',
    active: true,
    header: 'Anlagen',
    width: '6rem',
    minWidth: '6rem',
    resizable: true,
    sortable: false
  },
  {
    id: 'bearbeiter',
    active: true,
    header: 'Bearbeiter:in',
    width: '15rem',
    minWidth: '15rem',
    resizable: false,
    sortKey: 'userEntity.fullname',
    sortable: true
  },
  {
    id: 'empfaenger',
    active: false,
    header: 'Empfänger:in',
    width: '8rem',
    resizable: true,
    sortable: true,
    sortKey: 'publicServiceEntity.id.organizationalUnitEntity.name'
  },
  {
    id: 'bearbeitungsstand',
    active: false,
    header: 'Bearbeitungsstand',
    minWidth: '6rem',
    resizable: true,
    sortable: true,
    sortKey: 'bearbeitungsstand'
  },
  {
    id: 'bescheidstatus',
    active: false,
    header: 'Bescheidstatus',
    width: '16rem',
    resizable: true,
    sortable: true,
    sortKey: 'bescheidstatus'
  },
  {
    id: 'rueckkanal',
    active: false,
    header: 'Rückkanal',
    width: '8rem',
    minWidth: '8rem',
    resizable: false,
    sortable: false
  },
  {
    id: 'config',
    active: true,
    header: '',
    fixed: true,
    width: '4.5rem',
    minWidth: '4.5rem',
    sortable: false
  }
];

interface AntragsuebersichtTableHeaderProps {
  table: ReturnType<typeof useTable>;
}

export function AntragsuebersichtTableHeader({
  table
}: AntragsuebersichtTableHeaderProps) {
  return table.tableHeader;
}
