import { useKeycloak } from '@react-keycloak/web';
import { FjdStack, FjdSectionHeader, FjdHeading } from 'fjd-react-components';
import { ERROR_CASE_CLIENT } from '../../utils/Error/ErrorCode';
import { ErrorComponentClient } from '../shared/Error/ErrorComponent';
import SettingsTabs from './SettingsTabs/SettingsTabs';

export default function Settings() {
  const { keycloak } = useKeycloak();
  const hasGroupAdministration =
    keycloak.tokenParsed?.['groups'].includes('/Administration');

  return !hasGroupAdministration ? (
    <div style={{ width: '50rem' }}>
      <ErrorComponentClient errorCase={ERROR_CASE_CLIENT.NOT_AUTHORIZED} />
    </div>
  ) : (
    <FjdStack spacing="xl">
      <FjdSectionHeader
        align="center"
        heading={
          <FjdHeading id={'einstellungen'} level={1} text={'Einstellungen'} />
        }
      />
      <SettingsTabs />
    </FjdStack>
  );
}
