import useSWR from 'swr';
import { VermerkDto } from '../models/Vermerk/VermerkDto';

interface UseVermerkVerlaufConfig {
  caseId: string;
  vermerkId: string | undefined;
}
export function useVermerkVerlauf({
  caseId,
  vermerkId
}: UseVermerkVerlaufConfig) {
  const {
    data: vermerkVerlaufData,
    error,
    isValidating
  } = useSWR<Array<VermerkDto>>(
    `/api/v1/cases/${caseId}/vermerke/${vermerkId}/verlauf`,
    { suspense: false }
  );

  return { vermerkVerlaufData, isValidating, error };
}
