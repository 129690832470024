import './BescheidModal.module.css';

import {
  FjdButtonSelectGroup,
  FjdCard,
  FjdCheckbox,
  FjdFileInput,
  FjdFlexSpacer,
  FjdIcon,
  FjdLoadingOverlay,
  FjdModal,
  FjdStack,
  FjdTextInput,
  FjdTooltip
} from 'fjd-react-components';
import { ChangeEvent, useContext, useState } from 'react';
import UploadsList from '../../../shared/UploadsList';
import { useProfile } from '../../../../hooks/useProfile';
import {
  FileUploadAction,
  ActionType
} from '../../../../models/UploadFiles/FileUploadAction';
import { UploadFiles } from '../../../../models/UploadFiles/UploadFiles';
import { ConfirmAction } from '../../../../models/UploadFiles/ConfirmAction';
import UploadFilesConfirmationAlert from './UploadFilesConfirmationAlert';
import { FjdModalProps } from 'fjd-react-components/build/components/Modal/Modal';
import { ErrorComponentBehoerdenServer } from '../../../shared/Error/ErrorComponent';
import { caseDetailsDataContext } from '../../../../context/Context';

interface SendApplicationModalProps {
  readonly closeOnSecondaryButtonClick: () => void;
  readonly returnOnSecondaryButtonClick: (
    subject: string,
    text: string,
    shouldCloseCase: boolean
  ) => void;
  readonly performApplicationModalActions: (
    subject: string,
    text: string,
    antragFinished: boolean
  ) => void;
  readonly isApplicationPositiveDecided: boolean;
  readonly setApplicationIsPositiveDecided: (
    isPositiveDecided: boolean | undefined
  ) => void;
  readonly subject?: string;
  readonly text?: string;
  readonly shouldCloseCase?: boolean;
  readonly isLoadingSend: boolean;
  readonly fileDispatch: (fileAction: FileUploadAction) => void;
  readonly filesUploadstate: UploadFiles;
  readonly confirmAction: ConfirmAction;
  readonly isOpen: boolean;
}

export default function SendApplicationModal(props: SendApplicationModalProps) {
  const caseDetails = useContext(caseDetailsDataContext);
  const filesUploadstate: UploadFiles = props.filesUploadstate;
  const [subject, setSubject] = useState(props.subject ?? 'Bescheid:');
  const [text, setText] = useState(props.text ?? '');
  const [shouldCloseCase, setShouldCloseCase] = useState(
    props.shouldCloseCase ?? false
  );
  const { profile, error } = useProfile();

  const primaryButtonDisabled = (): boolean => {
    if (props.confirmAction.toConfirm) {
      return false;
    }

    if (!subject || !text) {
      return true;
    }

    if (props.isApplicationPositiveDecided === undefined) {
      return true;
    }

    if (filesUploadstate.bescheidUpload === undefined) {
      return true;
    }

    if (filesUploadstate.bescheidUpload === undefined) {
      return true;
    }

    if (props.isLoadingSend) {
      return true;
    }

    return false;
  };

  const primaryButtonlabel = (): string => {
    if (props.confirmAction.toConfirm) {
      return props.confirmAction.buttonLabel;
    }

    return shouldCloseCase
      ? 'Abschließen und Bescheid senden'
      : 'Bescheid senden';
  };

  const modalPropertiesBase: Partial<FjdModalProps> = {
    appElement: document.querySelector('.fjd-base-layout') as HTMLElement,
    heading: props.confirmAction.toConfirm
      ? props.confirmAction.heading
      : 'Bescheid versenden',
    id: 'send-application-modal',
    testId: 'send-application-modal',
    onClose: () => props.closeOnSecondaryButtonClick(),
    closeOnBackdropClick: false,
    open: props.isOpen
  };

  const modalPropertiesError: Partial<FjdModalProps> = {
    secondaryButtonDisabled: true,
    primaryButtonLabel: 'Schließen',
    onPrimaryButtonClick: () => props.closeOnSecondaryButtonClick()
  };

  const modalPropertiesNoError: Partial<FjdModalProps> = {
    primaryButtonLabel: primaryButtonlabel(),
    primaryButtonDisabled: primaryButtonDisabled(),
    secondaryButtonDisabled: props.isLoadingSend,
    onPrimaryButtonClick: () => {
      if (props.confirmAction.toConfirm) {
        props.confirmAction.onConfirmation();
      } else {
        props.performApplicationModalActions(subject, text, shouldCloseCase);
      }
    },
    secondaryButtonLabel: props.confirmAction.toConfirm
      ? 'Abbrechen'
      : 'Zurück',
    onSecondaryButtonClick: () => {
      if (props.confirmAction.toConfirm) {
        props.confirmAction.onCancel();
      } else {
        props.returnOnSecondaryButtonClick(subject, text, shouldCloseCase);
      }
    }
  };

  const modalProperties: FjdModalProps = error
    ? ({ ...modalPropertiesBase, ...modalPropertiesError } as FjdModalProps)
    : ({ ...modalPropertiesBase, ...modalPropertiesNoError } as FjdModalProps);

  return (
    <FjdModal {...modalProperties}>
      {error ? (
        <ErrorComponentBehoerdenServer />
      ) : props.confirmAction.toConfirm ? (
        <UploadFilesConfirmationAlert confirmAction={props.confirmAction} />
      ) : (
        <FjdLoadingOverlay loading={props.isLoadingSend}>
          <FjdStack orientation="vertical">
            <FjdStack orientation="vertical" spacing="xs">
              <div>Absender:in der Nachricht</div>
              <FjdTextInput
                id="absender"
                type="text"
                value={`${profile?.firstName ?? '?'} ${
                  profile?.lastName ?? '?'
                }`}
                disabled
              />
            </FjdStack>
            <FjdStack orientation="vertical" spacing="xs">
              <div>Auftragsnummer & Antragstitel</div>
              <FjdTextInput
                id="auftragsnummer"
                type="text"
                minLength={1}
                value={`${caseDetails.data.auftragsnummer} • ${caseDetails.data.name}`}
                disabled
              />
            </FjdStack>
            <FjdStack orientation="vertical" spacing="xs">
              <div>Betreff</div>
              <FjdTextInput
                id="betreff"
                testId={'betreff'}
                type="text"
                minLength={1}
                onChange={(event) => setSubject(event.target.value)}
                value={subject}
                required={true}
              />
            </FjdStack>
            <FjdStack orientation="vertical" spacing="xs">
              <div>Nachrichtentext</div>
              <FjdTextInput
                id="nachrichtentext"
                testId={'nachrichtentext'}
                type="text"
                minLength={1}
                rows={6}
                onChange={(event) => setText(event.target.value)}
                value={text}
                placeholder="Nachrichtentext"
              />
            </FjdStack>

            <FjdStack orientation="vertical" spacing="xs">
              {filesUploadstate.bescheidUpload && (
                <div
                  style={{
                    color: '#333333',
                    fontWeight: 700,
                    fontSize: '0.97rem'
                  }}
                  id={'bescheid_heading'}
                >
                  Bescheid
                </div>
              )}

              {filesUploadstate.additionalFiles.length > 0 ||
              filesUploadstate.bescheidUpload ? (
                <UploadsList
                  applicationIsPositiveDecided={
                    props.isApplicationPositiveDecided
                  }
                  setApplicationIsPositiveDecided={
                    props.setApplicationIsPositiveDecided
                  }
                  uploadFiles={filesUploadstate}
                  dispatch={props.fileDispatch}
                  hasUploadButtonEnabled={true}
                  uploadButtonTextAtLeastOneFileUploaded={
                    'Weiteres Dokument hinzufügen'
                  }
                  showPreview={true}
                  hasBescheidSelect={true}
                  hasIconsRightAligned={true}
                />
              ) : (
                <FjdCard backgroundColor="#fafafa">
                  <FjdStack orientation="horizontal">
                    <FjdFileInput
                      appearance={'default'}
                      size={'m'}
                      icon={'export'}
                      id="application_upload_button"
                      label="Bescheid hochladen"
                      name="application_upload_button"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        props.fileDispatch({
                          type: ActionType.ADD_BESCHEID,
                          files: event.target.files ?? []
                        });
                      }}
                    />
                    <FjdFlexSpacer />
                    <FjdButtonSelectGroup
                      id="btn-select-group-upload-bescheid-digital"
                      deselectableOptions={true}
                      onClick={(event) => {
                        if (props.setApplicationIsPositiveDecided) {
                          if (event.currentTarget.hasAttribute('checked')) {
                            props.setApplicationIsPositiveDecided(undefined);
                          } else {
                            props.setApplicationIsPositiveDecided(
                              event.currentTarget.value === 'positive'
                            );
                          }
                        }
                      }}
                      options={[
                        {
                          text: '✓ Positiv',
                          value: 'positive',
                          selected: props.isApplicationPositiveDecided ?? false,
                          color: 'success'
                        },
                        {
                          text: '✗ Negativ',
                          value: 'negative',
                          selected:
                            props.isApplicationPositiveDecided !== undefined &&
                            !props.isApplicationPositiveDecided,
                          color: 'error'
                        }
                      ]}
                    />
                  </FjdStack>
                </FjdCard>
              )}
            </FjdStack>

            <FjdStack orientation="vertical" spacing="xs">
              <div style={{ paddingTop: '1rem' }}>
                <FjdStack orientation={'horizontal'} spacing={'xs'}>
                  <span
                    style={{
                      color: '#333333',
                      fontWeight: 700,
                      fontSize: '0.97rem'
                    }}
                    id={'finish_application_heading'}
                  >
                    Antrag abschließen?{' '}
                  </span>
                  <span
                    style={{
                      color: '#333333',
                      fontSize: '0.97rem'
                    }}
                  >
                    (optional)
                  </span>
                  <FjdTooltip
                    placement="auto"
                    tooltip={
                      <div>
                        Nach dem Abschließen des Antrags wird dieser nach Ablauf
                        von 30 Tagen aus dem Behörden-Client gelöscht.
                      </div>
                    }
                  >
                    <FjdIcon appearance="info" glyph="information-filled" />
                  </FjdTooltip>
                </FjdStack>
              </div>
              <FjdCheckbox
                id="antragAbschliessen"
                label="Den Antrag direkt als abgeschlossen kennzeichnen (Downloadoption im nächsten Schritt)"
                defaultChecked={shouldCloseCase}
                onChange={() => setShouldCloseCase(!shouldCloseCase)}
              />
            </FjdStack>
          </FjdStack>
        </FjdLoadingOverlay>
      )}
    </FjdModal>
  );
}
