import { FjdModal } from 'fjd-react-components';

interface UploadFilePreviewModalProps {
  readonly payload: string | undefined;
  readonly onClose: () => void;
  readonly isOpen: boolean;
}

export function UploadFilePreviewModal({
  payload,
  onClose,
  isOpen
}: UploadFilePreviewModalProps) {
  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={''}
      id="attachment-modal"
      open={isOpen}
      onClose={onClose}
      primaryButtonDisabled
      secondaryButtonDisabled
      testId="attachment-modal"
      width={'fullscreen'}
    >
      <object
        aria-label="Antrags-PDF"
        data={`data:application/pdf;base64, ${encodeURI(payload ?? '')}`}
        type="application/pdf"
        width="100%"
        height="100%"
      />
    </FjdModal>
  );
}

export default UploadFilePreviewModal;
