import {
  CaseDetailsModel,
  SubmissionDetailsDto
} from '../../models/Case/CaseDetailsModel';
import { Bescheidstatus } from '../../models/Status/Bescheidstatus';
import { Bearbeitungsstand } from '../../models/Status/Bearbeitungsstand';

export function getCaseDetailsErrorData(
  caseId: string,
  traceId: string | undefined
) {
  return new CaseDetailsModel(
    caseId,
    'Fehler',
    '',
    null,
    {} as SubmissionDetailsDto,
    {} as Bescheidstatus,
    {} as Bearbeitungsstand,
    false,
    false,
    false,
    '',
    '',
    new Date(),
    [],
    true,
    traceId
  );
}
