import { FjdIcon, FjdStatus, FjdTooltip } from 'fjd-react-components';
import { AntragObj } from '../../../models/Antrag/AntragsModel';
import {
  FjdIconAppearance,
  FjdIconGlyph
} from 'fjd-react-components/build/components/Icon/Icon';
import { FjdBadgeSize } from 'fjd-react-components/build/components/Badge/Badge';
import { FjdStatusIntent } from 'fjd-react-components/build/components/Status/Status';
import BearbeitungsstandBadge from '../../shared/BearbeitungsstandBadge/BearbeitungsstandBadge';
import BescheidstatusBadge from '../../shared/BescheidstatusBadge/BescheidstatusBadge';
import { Bearbeitungsstand } from '../../../models/Status/Bearbeitungsstand';
import { Bescheidstatus } from '../../../models/Status/Bescheidstatus';

export interface statusConfig {
  withStatus?: boolean;
  statusIntent?: FjdStatusIntent;
  statusLabel?: string;
  statusIcon?: FjdIconGlyph;
  iconAppearance?: FjdIconAppearance;
  iconSize?: FjdBadgeSize;
  glyph?: FjdIconGlyph;
}

export const statusCell = (
  bearbeitungsstand: Bearbeitungsstand,
  bescheidstatus: Bescheidstatus
): JSX.Element | null => {
  switch (bearbeitungsstand) {
    case Bearbeitungsstand.OFFEN:
      if (bescheidstatus === Bescheidstatus.NOCHNICHTBESCHIEDEN) {
        return (
          <FjdTooltip
            placement="auto"
            tooltip={<div>Offen, noch nicht beschieden</div>}
          >
            <div id="circle-dash-default-status">
              <FjdIcon glyph="circle-dash" size="m" />
            </div>
          </FjdTooltip>
        );
      }
      break;
    case Bearbeitungsstand.INBEARBEITUNG:
      if (bescheidstatus === Bescheidstatus.NOCHNICHTBESCHIEDEN) {
        return (
          <FjdTooltip
            placement="auto"
            tooltip={<div>In Bearbeitung, noch nicht beschieden</div>}
          >
            <div id="incomplete-default-status">
              <FjdIcon glyph="incomplete" size="m" />
            </div>
          </FjdTooltip>
        );
      }
      if (bescheidstatus === Bescheidstatus.POSITIV) {
        return (
          <FjdTooltip
            placement="auto"
            tooltip={<div>In Bearbeitung, positiv beschieden</div>}
          >
            <div id="checkmark-filled-success-status">
              <FjdIcon appearance="success" glyph="checkmark-filled" size="m" />
            </div>
          </FjdTooltip>
        );
      }
      if (bescheidstatus === Bescheidstatus.NEGATIV) {
        return (
          <FjdTooltip
            placement="auto"
            tooltip={<div>In Bearbeitung, negativ beschieden</div>}
          >
            <div id="close-filled-error-status">
              <FjdIcon appearance="error" glyph="close-filled" size="m" />
            </div>
          </FjdTooltip>
        );
      }
      break;
    case Bearbeitungsstand.ABGESCHLOSSEN:
      const bescheid: string =
        bescheidstatus === Bescheidstatus.POSITIV ? 'positiv' : 'negativ';
      return (
        <FjdTooltip
          placement="auto"
          tooltip={<div>Abgeschlossen, {bescheid} beschieden</div>}
        >
          <div id="locked-negative-status">
            <FjdStatus intent="negative" label="" icon="locked" />
          </div>
        </FjdTooltip>
      );
    default:
      return null;
  }
  return null;
};

export const tabStatusConfig = (
  bearbeitungsstand: Bearbeitungsstand,
  bescheidstatus: Bescheidstatus
): statusConfig | null => {
  switch (bearbeitungsstand) {
    case Bearbeitungsstand.OFFEN:
      if (bescheidstatus === Bescheidstatus.NOCHNICHTBESCHIEDEN) {
        return {
          withStatus: false,
          iconAppearance: 'default',
          glyph: 'circle-dash',
          iconSize: 'm'
        };
      }
      break;
    case Bearbeitungsstand.INBEARBEITUNG:
      if (bescheidstatus === Bescheidstatus.NOCHNICHTBESCHIEDEN) {
        return {
          withStatus: false,
          iconAppearance: 'default',
          glyph: 'incomplete',
          iconSize: 'm'
        };
      }
      if (bescheidstatus === Bescheidstatus.POSITIV) {
        return {
          withStatus: false,
          iconAppearance: 'success',
          glyph: 'checkmark-filled',
          iconSize: 'm'
        };
      }
      if (bescheidstatus === Bescheidstatus.NEGATIV) {
        return {
          withStatus: false,
          iconAppearance: 'error',
          glyph: 'close-filled',
          iconSize: 'm'
        };
      }
      break;
    case Bearbeitungsstand.ABGESCHLOSSEN:
      return {
        withStatus: true,
        statusIntent: 'negative',
        statusLabel: '',
        statusIcon: 'locked'
      };
    default:
      return null;
  }
  return null;
};

export const bearbeitungsstandCell = (
  antrag: AntragObj
): JSX.Element | null => {
  const bearbeitungsstand: Bearbeitungsstand = antrag.bearbeitungsstand;

  return (
    <BearbeitungsstandBadge
      bearbeitungsstand={bearbeitungsstand}
      fjdBadgeSize={'s'}
    />
  );
};

export const bescheidstatusCell = (antrag: AntragObj): JSX.Element | null => {
  const bescheidstatus: Bescheidstatus = antrag.bescheidstatus;
  const bearbeitungsstand: Bearbeitungsstand = antrag.bearbeitungsstand;

  return (
    <BescheidstatusBadge
      bescheidstatus={bescheidstatus}
      bearbeitungsstand={bearbeitungsstand}
      fjdBadgeSize={'s'}
    />
  );
};

export const rueckkanalCell = (
  antrag: AntragObj | undefined
): JSX.Element | null => {
  return antrag?.canCommunicate ? (
    <div id="checkmark-filled">
      <FjdIcon appearance="success" glyph="checkmark-filled" size="m" />
    </div>
  ) : (
    <div id="close-filled">
      <FjdIcon appearance="error" glyph="close-filled" size="m" />
    </div>
  );
};
