import Keycloak from 'keycloak-js';
import {
  getKeycloakClientId,
  getKeycloakRealm,
  getKeycloakUrl
} from './mandant';

const keycloak = new Keycloak({
  clientId: getKeycloakClientId(),
  realm: getKeycloakRealm(),
  url: getKeycloakUrl()
});

export default keycloak;
