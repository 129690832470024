import IConfigAssistantStep from '../IConfigAssistantStep';
import {
  Button,
  FormRow,
  FormRowProps,
  IconButton,
  Input,
  Spacer
} from '@efast_public/fjd-component-library';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  FjdCard,
  FjdHeading,
  FjdSectionHeader,
  FjdStack
} from 'fjd-react-components';
import { ReactSortable, SortableEvent } from 'react-sortablejs';
import { v4 as uuidv4 } from 'uuid';
import './FooterLinks.css';
import { useFooter } from '../../../../hooks/useFooter';
import FooterDto from '../../../../models/Footer/FooterDto';
import { BaseSemanticVariant } from '@efast_public/fjd-component-library/build/common/types/variants';
import { equals } from '../../../../utils/compareUtil';
import useAlerts from '../../../../hooks/useAlerts';

type FooterDtoDefault = Pick<FooterDto, 'title' | 'url'>;

const footerLinkDefault: FooterDtoDefault = {
  title: '',
  url: ''
};

interface FooterLinkProps extends IConfigAssistantStep {
  readonly isSettings?: boolean;
}

const validationWarning: Partial<FormRowProps> = {
  validationMessage: 'Fehlende Angabe.',
  variant: 'error' as BaseSemanticVariant
};

const validationUndefined: Partial<FormRowProps> = {
  validationMessage: '',
  variant: undefined
};

export default function FooterLinks(props: FooterLinkProps): ReactElement {
  const { footers, postFooterLinks, mutate } = useFooter();
  const { alert } = useAlerts();
  const [initialState, setInitialState] = useState<Array<FooterDto>>([]);

  const [footerLinkList, setFooterLinkList] = useState<Array<FooterDto>>([
    {
      order: 1,
      id: uuidv4(),
      ...footerLinkDefault
    },
    {
      order: 2,
      id: uuidv4(),
      ...footerLinkDefault
    },
    {
      order: 3,
      id: uuidv4(),
      ...footerLinkDefault
    }
  ]);
  const { nextStepAvailable, addPrecondition, setButtonLabel } = props;

  useEffect(() => setButtonLabel('Weiter'), [setButtonLabel]);

  useEffect(() => {
    if (footers && initialState.length <= 0) {
      const initialStateValue = JSON.parse(JSON.stringify(footers));
      setInitialState(initialStateValue);
    }
  }, [footers, initialState, setInitialState]);

  useEffect(() => {
    if (initialState) {
      props.setInputChanged(!equals(initialState, footerLinkList));
    }
  }, [footerLinkList, initialState, props]);

  useEffect(() => {
    setFooterLinkList((prevState) => {
      if (!footers) {
        return prevState;
      }

      while (footers.length <= 2) {
        footers.push({
          order: footers.length + 1,
          id: uuidv4(),
          ...footerLinkDefault
        });
      }
      return footers.sort((a, b) => a.order - b.order);
    });
  }, [footers]);

  const addBlankFooterLink = () => {
    if (footerLinkList.length === 7) {
      return;
    }

    setFooterLinkList([
      ...footerLinkList,
      {
        order: footerLinkList.length + 1,
        id: uuidv4(),
        ...footerLinkDefault
      }
    ]);
  };

  const setLinkOrder = useCallback(() => {
    footerLinkList.forEach((item, index) => {
      footerLinkList[index].order = index + 1;
    });
  }, [footerLinkList]);

  const deleteFooterLink = (id: string) => {
    setFooterLinkList((prevList) => {
      if (id) {
        return footerLinkList.filter(
          (footerListItem) => footerListItem.id !== id
        );
      }

      prevList.pop();
      return [...prevList];
    });
  };

  const updateTitle = (id: string, value: string) => {
    setFooterLinkList((prevState: Array<FooterDto>) => {
      prevState.forEach((footer: FooterDto) => {
        if (footer.id === id) {
          footer.title = value;
        }
      });

      return [...prevState];
    });
  };
  const updateUrl = (id: string, value: string) => {
    setFooterLinkList((prevState: Array<FooterDto>) => {
      prevState.forEach((footer: FooterDto) => {
        if (footer.id === id) {
          footer.url = value;
        }
      });

      return [...prevState];
    });
  };

  const validateUrl = useCallback(
    (id: string): boolean => {
      return !footerLinkList.some((footer) => {
        if (footer.id !== id) {
          return false;
        }

        if (footer.title === '' && footer.url === '') {
          return false;
        }

        if (footer.title !== '' && footer.url !== '') {
          return false;
        }

        return footer.title !== '';
      });
    },
    [footerLinkList]
  );

  const validatetitle = useCallback(
    (id: string): boolean => {
      return !footerLinkList.some((footer) => {
        if (footer.id !== id) {
          return false;
        }

        if (footer.title === '' && footer.url === '') {
          return false;
        }

        if (footer.title !== '' && footer.url !== '') {
          return false;
        }

        return footer.url !== '';
      });
    },
    [footerLinkList]
  );

  const postFooters = useCallback(() => {
    return postFooterLinks({
      footerDtos: [
        ...footerLinkList.filter(
          (footer) => footer.title !== '' && footer.url !== ''
        )
      ]
    });
  }, [postFooterLinks, footerLinkList]);

  const verifyInput = useCallback(() => {
    return !footerLinkList.some((footer) => {
      return !validateUrl(footer.id) || !validatetitle(footer.id);
    });
  }, [footerLinkList, validateUrl, validatetitle]);

  useEffect(() => {
    addPrecondition({ condition: postFooters });
  }, [addPrecondition, postFooters]);

  useEffect(
    () => nextStepAvailable(verifyInput()),
    [nextStepAvailable, verifyInput]
  );

  useEffect(() => {
    setLinkOrder();
  }, [footerLinkList, setLinkOrder]);

  return (
    <div>
      {props.isSettings ? (
        <>
          <FjdSectionHeader
            align="center"
            heading={
              <FjdHeading id={'einstellungen'} level={2} text={'Footerlinks'} />
            }
          />
          <br />
          <div>
            Individualisieren Sie bis zu sieben Links im Footer Ihres
            Behörden-Clients. Wenn Sie in einer Zeile nur eine der beiden
            Angaben eintragen, wird nur diese im Footer angelegt (Text ohne
            Verlinkung oder Verlinkung ohne Text).
          </div>
        </>
      ) : (
        <>
          <div className="contentHeading">
            Optional: Footer-Links definieren (Schritt 7 von 7)
          </div>
          <div className="descriptionText">
            Individualisieren Sie die Links im Footer Ihres Behörden-Clients
            jetzt oder später in den Einstellungen. Sie können bis zu sieben
            Footer-Links definieren.
          </div>
          <div className="descriptionText">
            Wenn Sie in einer Zeile nur eine der beiden Angaben eintragen, wird
            nur diese im Footer angelegt (Text ohne Verlinkung oder Verlinkung
            ohne Text).
          </div>
        </>
      )}
      <div>
        <Spacer />
        <FjdCard>
          <ReactSortable
            animation={200}
            handle="[data-handle]"
            list={footerLinkList.map((footerDto: FooterDto) => ({
              ...footerDto,
              chosen: true
            }))}
            onEnd={(evt: SortableEvent) => {
              [footerLinkList[evt.oldIndex], footerLinkList[evt.newIndex]] = [
                footerLinkList[evt.newIndex],
                footerLinkList[evt.oldIndex]
              ];
              setLinkOrder();
            }}
            setList={setFooterLinkList}
          >
            {footerLinkList.map((footerListItem) => (
              <div className="formContainer" key={footerListItem.id}>
                <FjdStack
                  className="stackRow"
                  orientation="horizontal"
                  spacing="s"
                >
                  <IconButton
                    className="footerlink-icon-button"
                    id={`drag_${footerListItem.id}`}
                    icon="draggable"
                    label="Tertiary"
                    variant="tertiary"
                    data-handle
                    aria-label={`Footer Link verschieben ${footerListItem.id}`}
                  />
                  <FormRow
                    label="Bezeichnung Footer-Link"
                    className="labels"
                    {...(validatetitle(footerListItem.id)
                      ? validationUndefined
                      : validationWarning)}
                  >
                    <Input
                      onChange={(e) =>
                        updateTitle(footerListItem.id, e.target.value)
                      }
                      id={`title_order_${footerListItem.order}`}
                      value={footerListItem.title}
                      placeholder="z.B. Hilfe, Kontakt"
                      type="text"
                      className="bezInputRow"
                    />
                  </FormRow>
                  <FormRow
                    label="Ziel-URL"
                    className="labels"
                    {...(validateUrl(footerListItem.id)
                      ? validationUndefined
                      : validationWarning)}
                  >
                    <Input
                      id={`url_order_${footerListItem.order}`}
                      onChange={(e) =>
                        updateUrl(footerListItem.id, e.target.value)
                      }
                      value={footerListItem.url}
                      placeholder="wwww.domain.com"
                      type="url"
                      className="urlInputRow"
                    />
                  </FormRow>
                  <IconButton
                    className="footerlink-icon-button"
                    id={`delete_${footerListItem.id}`}
                    icon="trashcan"
                    label="Tertiary"
                    variant="tertiary"
                    data-handle
                    aria-label={`Footer Link löschen ${footerListItem.id}`}
                    onClick={() => deleteFooterLink(footerListItem?.id)}
                  />
                </FjdStack>
                <Spacer />
              </div>
            ))}
          </ReactSortable>
        </FjdCard>
        <Spacer />
      </div>
      <span className="footerlinksButtonContainer">
        <Button
          variant="tertiary"
          disabled={footerLinkList.length >= 7}
          beforeIcon="add-outline"
          onClick={() => addBlankFooterLink()}
        >
          Neuen Link hinzufügen
        </Button>
        {props.isSettings && (
          <Button
            variant="primary"
            data-testid="saveFooterLinks"
            disabled={!verifyInput()}
            onClick={() => {
              if (verifyInput()) {
                postFooters()
                  .then(() => {
                    mutate();
                    alert(
                      'success',
                      'Footerlinks wurden gespeichert.',
                      10000,
                      true
                    );
                  })
                  .catch(() => {
                    mutate();
                    alert(
                      'error',
                      'Footerlinks konnten leider nicht gespeichert werden!',
                      10000
                    );
                  });
              } else {
                alert('info', 'Ungültige Eingabe!', 10000);
              }
            }}
          >
            Footerlinks speichern
          </Button>
        )}
      </span>
    </div>
  );
}
