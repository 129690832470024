export enum ErrorType {
  BEHOERDENCLIENT_PROBLEMS_USER_LOGGED_IN = 'behoerdenclient/problems/userLoggedIn',
  BEHOERDENCLIENT_PROBLEMS_UPDATE_NOT_ALLOWED = 'behoerdenclient/problems/updateNotAllowed',
  BEHOERDENCLIENT_PROBLEMS_USER_CONFLICT = 'behoerdenclient/problems/userConflict'
}

export enum ERROR_CASE_CLIENT {
  NOT_AUTHORIZED
}

export enum StatusCode {
  LOCKED = 423,
  CONFLICT = 409
}

export class BEHError extends Error {
  type: string;
  status: string | number | undefined;
  detail: string;
  traceId: string | undefined;

  constructor(
    type: string,
    msg: string | undefined,
    status: string | number | undefined,
    detail: string,
    traceId: string | undefined
  ) {
    super(msg);
    this.type = type;
    this.status = status;
    this.detail = detail;
    this.traceId = traceId;
  }
}

const errorMsgBackend =
  'Bitte wenden Sie sich mit folgendem Error-Code an Ihren Administrator.';

const errorMsgClient = 'Bitte wenden Sie sich an Ihren Administrator';

export const fjdAlertErrorBackendMsg = 'Es ist ein Fehler aufgetreten. '.concat(
  errorMsgBackend
);

export const useAlertErrorMsg = 'Fehler. '.concat(errorMsgBackend);

export const conflictErrorMsgWithReloadHint =
  'Das Objekt wurde seit dem Laden verändert. Bitte laden Sie die Seite neu und führen Sie die Aktion erneut durch.';

export const conflictErrorMsg =
  'Das Objekt wurde seit dem Laden verändert. Bitte führen Sie die Aktion erneut durch.';

export const userConflictErrorMsg = 'Diese Mailadresse wird bereits verwendet.';

const fjdAlertErrorClientMsg = `Es ist ein Fehler aufgetreten. ${errorMsgClient}`;

const error_NotAuthorized = `${fjdAlertErrorClientMsg} (Kein Zugriff).`;

export function getErrorMsgClient(errorCase: ERROR_CASE_CLIENT): string {
  switch (errorCase) {
    case ERROR_CASE_CLIENT.NOT_AUTHORIZED:
      return error_NotAuthorized;
    default:
      return `${fjdAlertErrorClientMsg}.`;
  }
}
