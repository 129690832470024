import { RefObject, useEffect, useState } from 'react';

export default function useElementOnScreen(ref: RefObject<HTMLElement>) {
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    const refCopy = ref;
    const observer = new IntersectionObserver(([entry]) => {
      setIsOnScreen(entry.isIntersecting);
    });

    refCopy.current && observer.observe(refCopy.current);

    return () => {
      if (refCopy.current) {
        observer.unobserve(refCopy.current);
      }
    };
  }, [ref]);

  return isOnScreen;
}
