import FooterDto from '../../models/Footer/FooterDto';

export const defaultFooterLinks: Array<FooterDto> = [
  {
    id: '1',
    order: 1,
    title: 'Kontakt',
    url: 'https://www.fjd.de/kontakt'
  },
  {
    id: '2',
    order: 2,
    title: 'Hilfe',
    url: 'https://www.fjd.de/kontakt'
  },
  {
    id: '3',
    order: 3,
    title: 'Impressum',
    url: 'https://www.fjd.de/impressum'
  },
  {
    id: '4',
    order: 4,
    title: 'Datenschutz',
    url: 'https://www.fjd.de/datenschutz'
  }
];
