import { AttachmentModel } from '../models/Case/CaseDetailsModel';
import useSWR from 'swr';

interface UseAttachmentConfig {
  caseId: string;
  submissionId: string | undefined;
  attachmentId: string | undefined;
}

export function useAttachment({
  caseId,
  submissionId,
  attachmentId
}: UseAttachmentConfig) {
  const attachmentUrl: string | null =
    submissionId && attachmentId
      ? `/api/v1/cases/${caseId}/submissions/${submissionId}/attachments/${attachmentId}`
      : null;
  const {
    data: attachment,
    error,
    isValidating,
    mutate
  } = useSWR<AttachmentModel>(attachmentUrl, { suspense: false });

  return { attachment, isValidating, error, mutate };
}
