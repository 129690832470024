import { FjdLoadingOverlay, FjdStack } from 'fjd-react-components';
import useSummary from '../../../../hooks/useSummary';
import IConfigAssistantStep from '../IConfigAssistantStep';
import { Alert } from '@efast_public/fjd-component-library';
import { ReactElement, useEffect } from 'react';

interface SummaryProps extends IConfigAssistantStep {}

type SummaryMessage = 'DESTINATIONS' | 'FOOTERLINKS' | 'DATAMODELS';

function Summary(props: SummaryProps): ReactElement {
  const { summary, error } = useSummary();

  const { nextStepAvailable, addProgress, setButtonLabel } = props;

  useEffect(() => {
    setButtonLabel('Behörden-Client öffnen');
  }, [setButtonLabel]);

  useEffect(() => {
    nextStepAvailable(true);

    const timer = setTimeout(() => {
      addProgress(3);
    }, 1200);
    return () => clearTimeout(timer);
  }, [nextStepAvailable, addProgress]);

  const numberToString = (value: number): string => {
    switch (value) {
      case 0:
        return 'Kein';
      case 1:
        return 'Ein';
      case 2:
        return 'Zwei';
      case 3:
        return 'Drei';
      case 4:
        return 'Vier';
      case 5:
        return 'Fünf';
      case 6:
        return 'Sechs';
      case 7:
        return 'Sieben';
      case 8:
        return 'Acht';
      case 9:
        return 'Neun';
      case 10:
        return 'Zehn';
      case 11:
        return 'Elf';
      case 12:
        return 'Zwölf';
      default:
        return value.toString();
    }
  };

  const formatMessage = (type: SummaryMessage, value: number) => {
    switch (type) {
      case 'DATAMODELS':
        return value > 1
          ? `${numberToString(value)} Datenmodelle erfolgreich importiert`
          : `${numberToString(value)} Datenmodell erfolgreich importiert`;
      case 'DESTINATIONS':
        return value > 1
          ? `${numberToString(value)} Zustellpunkte erfolgreich importiert`
          : `${numberToString(value)} Zustellpunkt erfolgreich importiert`;
      case 'FOOTERLINKS':
        return value > 1
          ? `${numberToString(value)} Footerlinks erfolgreich definiert`
          : `${numberToString(value)} Footerlink erfolgreich definiert`;
    }
  };

  return (
    <FjdLoadingOverlay loading={!summary && !error}>
      {error ? (
        <Alert
          message={`Es ist ein Fehler aufgetreten. (${error.traceId})`}
          variant="error"
        />
      ) : (
        <div>
          <div className="contentHeading">Einrichtung abgeschlossen</div>
          <div className="descriptionText">
            Sie können den Behörden-Client ab sofort nutzen.
          </div>
          <br />
          <FjdStack orientation="vertical">
            <Alert
              message={'FIT-Connect erfolgreich verbunden'}
              variant="success"
            />
            <Alert
              message={formatMessage(
                'DESTINATIONS',
                summary?.destinations.count ?? 0
              )}
              variant="success"
            />
            <Alert
              message={formatMessage(
                'DATAMODELS',
                summary?.datamodels.count ?? 0
              )}
              variant="success"
            />
            {summary && summary.bundId.count === 1 && (
              <Alert
                message={
                  'BundID-Postfach-Zertifikat und Private Key wurden erfolgreich hinterlegt'
                }
                variant="success"
              />
            )}
            {summary && summary.logo.count === 1 ? (
              <Alert
                message={'Individuelles Logo erfolgreich hochgeladen'}
                variant="success"
              />
            ) : null}
            {summary && summary.footerLinks.count >= 1 ? (
              <Alert
                message={formatMessage(
                  'FOOTERLINKS',
                  summary.footerLinks.count
                )}
                variant="success"
              />
            ) : null}
          </FjdStack>
        </div>
      )}
    </FjdLoadingOverlay>
  );
}

export default Summary;
