import {
  FjdStack,
  FjdFileInput,
  FjdCard,
  FjdFlexSpacer,
  FjdButtonSelectGroup
} from 'fjd-react-components';
import { ChangeEvent, Dispatch } from 'react';
import {
  ActionType,
  FileUploadAction
} from '../../../../models/UploadFiles/FileUploadAction';
import { UploadFiles } from '../../../../models/UploadFiles/UploadFiles';
import UploadsList from '../../../shared/UploadsList';

interface DecideApplicationDigitalProps {
  readonly isApplicationExaminedAndChecked: boolean;
  readonly setIsApplicationPositiveDecided: (
    isPositiveDecided: boolean | undefined
  ) => void;
  readonly isApplicationPositiveDecided: boolean | undefined;
  readonly fileDispatch: Dispatch<FileUploadAction>;
  readonly filesUploadstate: UploadFiles;
}

export default function DecideApplicationDigital(
  props: DecideApplicationDigitalProps
) {
  const filesUploadstate: UploadFiles = props.filesUploadstate;
  return (
    <span>
      <FjdStack orientation={'vertical'} spacing="m">
        <div
          style={{
            color: props.isApplicationExaminedAndChecked ? '#333333' : '#999999'
          }}
          id={'upload_heading'}
        >
          Um den Antrag zu bescheiden laden Sie bitte einen Bescheid hoch und
          geben Sie an, ob der Bescheid positiv oder negativ ist
        </div>
        <div
          style={{
            color: props.isApplicationExaminedAndChecked
              ? '#333333'
              : '#999999',
            fontWeight: 700,
            fontSize: '0.97rem'
          }}
          id={'bescheid_heading'}
        >
          Bescheidupload
        </div>
        {(props.isApplicationExaminedAndChecked &&
          filesUploadstate.additionalFiles.length > 0) ||
        filesUploadstate.bescheidUpload ? (
          <UploadsList
            applicationIsPositiveDecided={props.isApplicationPositiveDecided}
            setApplicationIsPositiveDecided={
              props.setIsApplicationPositiveDecided
            }
            uploadFiles={filesUploadstate}
            dispatch={props.fileDispatch}
            hasUploadButtonEnabled={true}
            uploadButtonTextAtLeastOneFileUploaded={
              'Weiteres Dokument hinzufügen'
            }
            showPreview={true}
            hasBescheidSelect={true}
            hasIconsRightAligned={true}
          />
        ) : (
          <FjdCard backgroundColor="#fafafa">
            <FjdStack orientation="horizontal">
              <FjdFileInput
                appearance={'default'}
                size={'m'}
                disabled={
                  props.isApplicationExaminedAndChecked === undefined ||
                  props.isApplicationExaminedAndChecked === false
                }
                icon={'export'}
                id="application_upload_button"
                label="Bescheid hochladen"
                name="application_upload_button"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  props.fileDispatch({
                    type: ActionType.ADD_BESCHEID,
                    files: event.target.files ?? []
                  });
                }}
              />
              <FjdFlexSpacer />
              <FjdButtonSelectGroup
                id="btn-select-group-upload-bescheid-digital"
                deselectableOptions={true}
                backgroundColor={'white'}
                disabled={
                  props.isApplicationExaminedAndChecked === undefined ||
                  props.isApplicationExaminedAndChecked === false
                }
                onClick={(event) => {
                  if (props.setIsApplicationPositiveDecided) {
                    if (event.currentTarget.hasAttribute('checked')) {
                      props.setIsApplicationPositiveDecided(undefined);
                    } else {
                      props.setIsApplicationPositiveDecided(
                        event.currentTarget.value === 'positive'
                      );
                    }
                  }
                }}
                options={[
                  {
                    text: '✓ Positiv',
                    value: 'positive',
                    selected: props.isApplicationPositiveDecided ?? false,
                    color: 'success'
                  },
                  {
                    text: '✗ Negativ',
                    value: 'negative',
                    selected:
                      props.isApplicationPositiveDecided !== undefined &&
                      !props.isApplicationPositiveDecided
                        ? true
                        : false,
                    color: 'error'
                  }
                ]}
              />
            </FjdStack>
          </FjdCard>
        )}
      </FjdStack>
    </span>
  );
}
