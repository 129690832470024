import {
  FjdLoadingOverlay,
  FjdStack,
  FjdVirtualizedTable,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { formatByte } from '../../../../utils/antragsuebersicht';
import styles from '../../SchreibtischBody/Dokumente/dokumente.module.css';
import { useAttachmentsDokumenteTab } from '../../../../hooks/useAttachmentsDokumenteTab';
import { useContext, useEffect } from 'react';
import { caseDetailsDataContext } from '../../../../context/Context';
import BescheidstatusBadge from '../../../shared/BescheidstatusBadge/BescheidstatusBadge';
import { Bescheidstatus } from '../../../../models/Status/Bescheidstatus';
import { ErrorComponentBehoerdenServer } from '../../../shared/Error/ErrorComponent';
import { BEHError } from '../../../../utils/Error/ErrorCode';
import AntragBadge from '../../../shared/AntragBadge/AntragBadge';

interface AntragsDokumentenTabelleProps {
  readonly setError?: (error: BEHError) => void;
}

function AntragsDokumentenTabelle(
  props: AntragsDokumentenTabelleProps
): JSX.Element {
  const caseDetails = useContext(caseDetailsDataContext);
  const initialSubmissionId: string =
    caseDetails.data.initialSubmission.submissionId;

  const { attachments, isValidating, error } = useAttachmentsDokumenteTab({
    caseId: caseDetails.data.caseId
  });
  const parentSetHasErrorFct: ((hasError: BEHError) => void) | undefined =
    props.setError;

  const documents =
    attachments && !isValidating
      ? attachments.map((document) => {
          return { ...document, isBescheid: document.isBescheid };
        })
      : [];

  useEffect(() => {
    if (error && parentSetHasErrorFct) {
      parentSetHasErrorFct(error);
    }
  }, [error, parentSetHasErrorFct]);

  if (error && parentSetHasErrorFct) {
    return <></>;
  } else if (error) {
    return <ErrorComponentBehoerdenServer errorCode={error?.traceId} />;
  }

  return (
    <FjdLoadingOverlay loading={isValidating}>
      <FjdStack spacing="s">
        <FjdVirtualizedTable
          disableVirtualization
          header={
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCol>Name</FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol maxWidth="6rem">
                Typ
              </FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol maxWidth="6rem">
                Größe
              </FjdVirtualizedTableCol>
            </FjdVirtualizedTableRow>
          }
        >
          {documents.length === 0 && (
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCell>
                {'Keine Dokumente gefunden.'}
              </FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          )}

          {documents.map((document) => (
            <FjdVirtualizedTableRow key={`document${document.id}`}>
              <FjdVirtualizedTableCell>
                <FjdStack
                  orientation="horizontal"
                  spacing="xl"
                  className={styles.container}
                >
                  <div style={{ whiteSpace: 'initial' }}>
                    {document.description + document.filetype}
                  </div>
                  {document.isBescheid &&
                    (caseDetails.data.bescheidstatus ===
                      Bescheidstatus.POSITIV ||
                      caseDetails.data.bescheidstatus ===
                        Bescheidstatus.NEGATIV) && (
                      <BescheidstatusBadge
                        bescheidstatus={caseDetails?.data.bescheidstatus}
                        fjdBadgeSize={'xs'}
                        badgeLabel={'Bescheid'}
                      />
                    )}
                  {document.submissionId === initialSubmissionId && (
                    <AntragBadge data-testid="antragBadge" />
                  )}
                </FjdStack>
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell maxWidth="6rem">
                {document.filetype.toUpperCase()}
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell maxWidth="6rem">
                {formatByte(`${document.size}`)}
              </FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          ))}
        </FjdVirtualizedTable>
      </FjdStack>
    </FjdLoadingOverlay>
  );
}

export default AntragsDokumentenTabelle;
