import {
  FjdAlert,
  FjdHeading,
  FjdLoadingOverlay,
  FjdModal,
  FjdSpacer,
  FjdStack
} from 'fjd-react-components';
import { useState } from 'react';
import AntragsDokumentenTabelle from './AntragsDokumentenTabelle';
import { useUpdateCase } from '../../../../hooks/useUpdateCase';
import { FormatUtil } from '../../../../utils/formatUtil';
import { CaseDetailsModel } from '../../../../models/Case/CaseDetailsModel';
import { ErrorComponentBehoerdenServer } from '../../../shared/Error/ErrorComponent';
import { FjdModalProps } from 'fjd-react-components/build/components/Modal/Modal';
import { BEHError } from '../../../../utils/Error/ErrorCode';

interface AntragAbschliessenModalProps {
  readonly onClose: () => void;
  readonly caseId: string;
  readonly auftragsnummer: string;
  readonly version: string;
  readonly isOpen: boolean;
}

export function AntragAbschliessenModal({
  onClose,
  caseId,
  auftragsnummer,
  version,
  isOpen
}: AntragAbschliessenModalProps) {
  const [error, setError] = useState<BEHError | undefined>(undefined);
  const { downloadSummary, updateCase, loadingUpdateCase, loadingSummary } =
    useUpdateCase();
  const BEARBEITUNGSSTAND_KEY = 'bearbeitungsstand';

  const modalPropertiesBase: Partial<FjdModalProps> = {
    appElement: document.querySelector('.fjd-base-layout') as HTMLElement,
    heading: 'Antrag herunterladen',
    id: 'antrag-abschliessen-modal',
    onClose: onClose,
    closeOnBackdropClick: false,
    open: isOpen
  };

  const modalPropertiesError: Partial<FjdModalProps> = {
    secondaryButtonDisabled: true,
    primaryButtonLabel: 'Schließen',
    onPrimaryButtonClick: () => onClose()
  };

  const modalPropertiesNoError: Partial<FjdModalProps> = {
    primaryButtonDisabled: loadingUpdateCase || loadingSummary,
    secondaryButtonDisabled: loadingUpdateCase || loadingSummary,
    onPrimaryButtonClick: async () => {
      let updatedFields = new Map<string, string | null>();
      updatedFields.set('caseVersion', version);
      updatedFields.set(BEARBEITUNGSSTAND_KEY, 'Abgeschlossen');
      await updateCase(caseId, updatedFields)
        .then((caseDetailsModel: CaseDetailsModel | undefined) => {
          if (caseDetailsModel) {
            const timestamp: string = FormatUtil.fileDateTimeFormatted();
            downloadSummary(
              caseId,
              `${auftragsnummer}_${timestamp}_Download.zip`
            );
          }
        })
        .finally(() => onClose());
    },
    primaryButtonLabel: 'Antrag abschließen und herunterladen (ZIP)',
    secondaryButtonLabel: 'Abbrechen',
    onSecondaryButtonClick: () => onClose()
  };

  const modalProperties: FjdModalProps = error
    ? ({ ...modalPropertiesBase, ...modalPropertiesError } as FjdModalProps)
    : ({ ...modalPropertiesBase, ...modalPropertiesNoError } as FjdModalProps);

  return (
    <FjdModal {...modalProperties}>
      <FjdLoadingOverlay loading={loadingUpdateCase || loadingSummary}>
        {error ? (
          <ErrorComponentBehoerdenServer errorCode={error.traceId} />
        ) : (
          <FjdStack orientation="vertical">
            <FjdAlert
              title={
                'Abgeschlossene Anträge werden nach 30 Tagen aus dem Behörden-Client gelöscht, auch wenn sie noch nicht heruntergeladen wurden.'
              }
              appearance={'muted'}
              closable={false}
              intent={'warning'}
            />
            <FjdSpacer dimensions={{ width: 0, height: 1 }} />
            <FjdHeading
              level={3}
              text={
                'Laden Sie den Antrag vollständig herunter, um ihn vorschriftsmäßig abzulegen'
              }
            />
            <div>Der Download beinhaltet folgende Dokumente:</div>
            <div style={{ marginLeft: '2rem', marginRight: '2rem' }}>
              <AntragsDokumentenTabelle setError={setError} />
            </div>
          </FjdStack>
        )}
      </FjdLoadingOverlay>
    </FjdModal>
  );
}

export default AntragAbschliessenModal;
