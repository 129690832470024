import { ReactNode, useState } from 'react';
import { BEHError } from '../../utils/Error/ErrorCode';
import { errorContext } from '../Context';

interface PropsChildren {
  readonly children: ReactNode | Array<ReactNode>;
}
export const initialBehError: BEHError = new BEHError(
  '',
  '',
  undefined,
  '',
  undefined
);
const ErrorProvider = ({ children }: PropsChildren) => {
  const [behError, setBehError] = useState<BEHError>(initialBehError);

  const value = { behError: behError, setBehError: setBehError };

  const { Provider } = errorContext;
  return <Provider value={value}>{children}</Provider>;
};
export default ErrorProvider;
