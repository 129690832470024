import FooterDto from '../models/Footer/FooterDto';
import FooterListDto from '../models/Footer/FooterListDto';
import useSWR from 'swr';
import { getFooterApiServerUrl } from '../utils/mandant';
import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { useApi } from './useApi';

export function useFooter() {
  const apiUrl = getFooterApiServerUrl();
  const { makeRequest } = useApi();

  const {
    data: footers,
    error,
    isValidating,
    mutate
  } = useSWR<Array<FooterDto>>(['/api/v1/footers', apiUrl], {
    suspense: false
  });

  const postFooterLinks = useCallback(
    async (footerListDto: FooterListDto) => {
      return makeRequest<AxiosResponse>(
        `/api/v1/footers`,
        'POST',
        apiUrl,
        undefined,
        undefined,
        footerListDto
      );
    },
    [makeRequest, apiUrl]
  );

  return { footers, error, isValidating, mutate, postFooterLinks };
}
