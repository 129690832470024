export function equals<T>(first: T, second: T): boolean {
  type ObjectKey = keyof typeof first;
  let equalsValue: boolean = true;

  if (!first && !second) {
    return true;
  }

  if (!first || !second) {
    return false;
  }

  Object.keys(first).forEach((key: string) => {
    const propertyFirst = first[key as ObjectKey];
    const propertySecond = second[key as ObjectKey];

    if (propertyFirst instanceof Object) {
      equalsValue =
        equalsValue && equals(propertyFirst, second[key as ObjectKey]);
    } else if (Array.isArray(propertyFirst) && Array.isArray(propertySecond)) {
      for (let i = 0; i < propertyFirst.length; i++) {
        equalsValue =
          equalsValue && equals(propertyFirst[i], propertySecond[i]);
      }
      return equalsValue;
    } else {
      equalsValue = equalsValue && propertyFirst === propertySecond;
    }
  });

  return equalsValue;
}

export const compareDateTime: (date1: Date, date2: Date) => boolean = (
  date1: Date,
  date2: Date
): boolean => {
  return date1.getTime() === date2.getTime();
};
