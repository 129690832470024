import { ChangeEvent, Dispatch, useState } from 'react';
import {
  FjdStack,
  FjdCheckbox,
  FjdFileInput,
  FjdTooltip,
  FjdIcon,
  FjdButtonSelectGroup
} from 'fjd-react-components';
import UploadsList from '../../../shared/UploadsList';
import {
  FileUploadAction,
  ActionType
} from '../../../../models/UploadFiles/FileUploadAction';
import { UploadFiles } from '../../../../models/UploadFiles/UploadFiles';

interface DecideApplicationPostalProps {
  readonly isApplicationExaminedAndChecked: boolean;
  readonly setApplicationIsPositiveDecided: (
    isPositiveDecided: boolean | undefined
  ) => void;
  readonly fileDispatch: Dispatch<FileUploadAction>;
  readonly filesUploadstate: UploadFiles;
  readonly applicationIsPositiveDecided: boolean | undefined;
  readonly isApplicationFinished: boolean;
  readonly setApplicationIsFinished: (applicationIsFinished: boolean) => void;
}

export default function DecideApplicationPostal(
  props: DecideApplicationPostalProps
) {
  const filesUploadstate: UploadFiles = props.filesUploadstate;

  const [sendPostallyConfirmed, setSendPostallyConfirmed] = useState<boolean>(
    (filesUploadstate.bescheidUpload !== undefined ||
      filesUploadstate.additionalFiles.length > 0) &&
      props.isApplicationExaminedAndChecked
  );

  const isDisabledAndUnsetForSendDigitallyCheck: () => boolean = () => {
    if (props.isApplicationExaminedAndChecked) {
      return false;
    } else {
      if (sendPostallyConfirmed) {
        setSendPostallyConfirmed(false);
      }
      return true;
    }
  };

  const isDisabledAndUnsetForApplictionIsFinishedCheck: () => boolean = () => {
    if (sendPostallyConfirmed) {
      return false;
    } else {
      if (props.isApplicationFinished) {
        props.setApplicationIsFinished(false);
      }
      return true;
    }
  };

  return (
    <FjdStack orientation={'vertical'} spacing="3xl">
      <FjdStack orientation={'vertical'} spacing="s">
        <div
          style={{
            color: props.isApplicationExaminedAndChecked
              ? '#333333'
              : '#999999',
            fontWeight: 700,
            fontSize: '0.97rem'
          }}
          id={'send_postally_confirmed_heading'}
        >
          Bitte bestätigen Sie, dass der Bescheid postalisch zugestellt wird
        </div>
        <FjdStack orientation={'vertical'} spacing={'xl'}>
          <FjdStack orientation={'horizontal'} spacing={'s'}>
            <FjdCheckbox
              id={'send_postally_confirmed_checkbox'}
              label={''}
              onChange={() => {
                if (sendPostallyConfirmed) {
                  props.fileDispatch({
                    type: ActionType.REMOVE_ALL_FILES,
                    onConfirmation: () => {
                      setSendPostallyConfirmed(!sendPostallyConfirmed);
                      props.setApplicationIsPositiveDecided(undefined);
                    }
                  });
                } else {
                  setSendPostallyConfirmed(!sendPostallyConfirmed);
                  props.setApplicationIsPositiveDecided(undefined);
                }
              }}
              disabled={isDisabledAndUnsetForSendDigitallyCheck()}
              checked={
                props.isApplicationExaminedAndChecked && sendPostallyConfirmed
              }
            />
            <div
              style={{
                color: props.isApplicationExaminedAndChecked
                  ? '#333333'
                  : '#999999'
              }}
              id={'send_postally_confirmed_text'}
            >
              Der Bescheid wird postalisch zugestellt
            </div>
          </FjdStack>
        </FjdStack>
      </FjdStack>

      <FjdStack orientation="vertical">
        <div
          style={{
            color: sendPostallyConfirmed ? '#333333' : '#999999',
            fontWeight: 700,
            fontSize: '0.97rem'
          }}
          id={'send_postally_bescheid_heading'}
        >
          Geben Sie bitte an, ob der Bescheid positiv oder negativ ist
        </div>

        <FjdButtonSelectGroup
          id="btn-select-group-bescheid-postal"
          disabled={!sendPostallyConfirmed}
          deselectableOptions={true}
          onClick={(event) => {
            if (props.setApplicationIsPositiveDecided) {
              if (event.currentTarget.hasAttribute('checked')) {
                props.setApplicationIsPositiveDecided(undefined);
              } else {
                props.setApplicationIsPositiveDecided(
                  event.currentTarget.value === 'positive'
                );
              }
            }
          }}
          options={[
            {
              text: '✓ Positiv',
              value: 'positive',
              color: 'success'
            },
            {
              text: '✗ Negativ',
              value: 'negative',
              color: 'error'
            }
          ]}
        />
      </FjdStack>

      <FjdStack orientation={'vertical'} spacing={'s'}>
        <div>
          <FjdStack orientation={'horizontal'} spacing={'xs'}>
            <span
              style={{
                color: sendPostallyConfirmed ? '#333333' : '#999999',
                fontWeight: 700,
                fontSize: '0.97rem'
              }}
              id={'upload_heading'}
            >
              Bescheidupload{' '}
            </span>
            <span
              style={{
                color: sendPostallyConfirmed ? '#333333' : '#999999',
                fontSize: '0.97rem'
              }}
            >
              (optional)
            </span>
            <FjdTooltip
              placement="auto"
              tooltip={
                <div>
                  Sie können optional den Bescheid hochladen, um diesen im
                  System zu hinterlegen.
                </div>
              }
            >
              <FjdIcon appearance="info" glyph="information-filled" />
            </FjdTooltip>
          </FjdStack>
        </div>
        {!filesUploadstate.bescheidUpload ? (
          <FjdFileInput
            appearance={'default'}
            size={'m'}
            disabled={!sendPostallyConfirmed}
            icon={'export'}
            id="application_upload_button"
            label="Bescheid hochladen"
            name="application_upload_button"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              props.fileDispatch({
                type: ActionType.ADD_BESCHEID,
                files: event.target.files ?? []
              });
            }}
          />
        ) : (
          <UploadsList
            applicationIsPositiveDecided={props.applicationIsPositiveDecided}
            setApplicationIsPositiveDecided={
              props.setApplicationIsPositiveDecided
            }
            uploadFiles={filesUploadstate}
            hasBescheidSelect={false}
            dispatch={props.fileDispatch}
            hasUploadButtonEnabled={true}
            uploadButtonTextAtLeastOneFileUploaded={
              'Weiteres Dokument hinzufügen'
            }
            showPreview={true}
            hasIconsRightAligned={true}
          />
        )}
      </FjdStack>
      <FjdStack orientation={'vertical'} spacing={'s'}>
        <div>
          <FjdStack orientation={'horizontal'} spacing={'xs'}>
            <span
              style={{
                color: sendPostallyConfirmed ? '#333333' : '#999999',
                fontWeight: 700,
                fontSize: '0.97rem'
              }}
              id={'finish_application_heading'}
            >
              Antrag abschließen?{' '}
            </span>
            <span
              style={{
                color: sendPostallyConfirmed ? '#333333' : '#999999',
                fontSize: '0.97rem'
              }}
            >
              (optional)
            </span>
            <FjdTooltip
              placement="auto"
              tooltip={
                <div>
                  Nach dem Abschließen des Antrags wird dieser nach Ablauf von
                  30 Tagen aus dem Behörden-Client gelöscht.
                </div>
              }
            >
              <FjdIcon appearance="info" glyph="information-filled" />
            </FjdTooltip>
          </FjdStack>
        </div>
        <FjdStack orientation={'horizontal'} spacing={'s'}>
          <FjdCheckbox
            id={'finish_application_checkbox'}
            label={''}
            onChange={() => {
              props.setApplicationIsFinished(!props.isApplicationFinished);
            }}
            disabled={isDisabledAndUnsetForApplictionIsFinishedCheck()}
            checked={props.isApplicationFinished}
          />
          <div
            style={{
              color: sendPostallyConfirmed ? '#333333' : '#999999'
            }}
          >
            Den Antrag direkt als abgeschlossen kennzeichnen (Downloadoption im
            nächsten Schritt)
          </div>
        </FjdStack>
      </FjdStack>
    </FjdStack>
  );
}
