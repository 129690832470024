import moment from 'moment';

export namespace FormatUtil {
  export const fileDateTimeFormat: string = 'YYYYMMDD-HHmmss';

  export function fileDateTimeFormatted() {
    return formatDateTime(new Date(), fileDateTimeFormat);
  }

  export function formatDateTime(date: Date, formatString: string) {
    return moment(date).format(formatString);
  }
}
