import { FjdAlert } from 'fjd-react-components';
import {
  ERROR_CASE_CLIENT,
  fjdAlertErrorBackendMsg,
  getErrorMsgClient
} from '../../../utils/Error/ErrorCode';

interface ErrorComponentBackendProps {
  readonly errorCode?: string;
}

export function ErrorComponentBehoerdenServer(
  props: ErrorComponentBackendProps
): JSX.Element {
  const errorcodeMsg = props.errorCode
    ? ` (${props.errorCode})`
    : ' (Server nicht erreichbar oder fehlerhaft)';

  return (
    <FjdAlert
      intent="error"
      appearance={'muted'}
      title={fjdAlertErrorBackendMsg.concat(errorcodeMsg)}
      closable={false}
    />
  );
}

interface ErrorComponentClientProps {
  readonly errorCase: ERROR_CASE_CLIENT;
}
export function ErrorComponentClient(
  props: ErrorComponentClientProps
): JSX.Element {
  return (
    <FjdAlert
      intent="error"
      appearance={'muted'}
      title={getErrorMsgClient(props.errorCase)}
      closable={false}
    />
  );
}
