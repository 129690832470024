import {
  FjdLoadingOverlay,
  FjdStack,
  FjdVirtualizedTable,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { AntragObj } from '../../../models/Antrag/AntragsModel';
import {
  antragsuebersichtTableDefaultColumns,
  AntragsuebersichtTableHeader
} from './AntragsuebersichtTableHeader';
import { useTable } from '../../../hooks/useTable';
import { AntragsuebersichtTableCaseRows } from './AntragsuebersichtRows';
import { usePaginatedSwr } from '../../../hooks/usePaginatedSwr';
import { buildLikeQuery } from '../../../utils/antragsuebersicht';
import { BEHError } from '../../../utils/Error/ErrorCode';
import { useEffect } from 'react';
import AntragsuebersichtTableFooter from './AntragsuebersichtTableFooter/AntragsuebersichtTableFooter';

interface AntragsuebersichtTableProps {
  readonly bescheidstatus: string;
  readonly bearbeitungsstand: string;
  readonly bearbeiter: string;
  readonly input: string;
  readonly searchAntraege: string | undefined;
  readonly clearFilter: () => void;
  readonly setIsValidating: (value: boolean) => void;
}

export default function AntragsuebersichtTable(
  props: AntragsuebersichtTableProps
) {
  const table = useTable(antragsuebersichtTableDefaultColumns);
  const {
    data: antraege,
    isValidating,
    pagination,
    error
  } = usePaginatedSwr<AntragObj>({
    key: '/api/v1/cases',
    sortByOrder: table.sortByOrder,
    filter: [
      {
        property: 'bearbeitungsstand',
        operator: 'eq',
        value: props.bearbeitungsstand
      },
      {
        property: 'bescheidstatus',
        operator: 'eq',
        value: props.bescheidstatus
      },
      {
        property: 'userEntity.userId',
        operator: 'eq',
        value: props.bearbeiter
      },
      {
        property: 'name$auftragsnummer',
        operator: 'like',
        value: buildLikeQuery(props.searchAntraege)
      }
    ],
    sortByProperty: table.sortByProperty,
    initPageSize: 10
  });

  useEffect(() => {
    const behError = error as BEHError;
    if (behError) {
      props.setIsValidating(false);
    }
  }, [error, props]);

  const activeFilters: boolean =
    props.bearbeitungsstand ||
    props.bescheidstatus ||
    props.bearbeiter ||
    props.searchAntraege
      ? true
      : false;

  return antraege ? (
    <FjdLoadingOverlay loading={isValidating}>
      <FjdStack id={'stack'} spacing="s">
        <FjdVirtualizedTable
          disableVirtualization
          header={<AntragsuebersichtTableHeader table={table} />}
        >
          <AntragsuebersichtTableCaseRows
            antraege={antraege}
            table={table}
            clearFilter={props.clearFilter}
            hasActiveFilters={activeFilters}
          />

          <AntragsuebersichtTableFooter
            openSelection={table.openSelection}
            resetSelection={table.resetSelection}
            sectionLength={table.selection.length}
          />
        </FjdVirtualizedTable>
        {antraege.length > 0 && (
          <FjdVirtualizedTableRow withTopSeparator>
            <FjdVirtualizedTableCell>
              {pagination.PaginationComponent}
            </FjdVirtualizedTableCell>
          </FjdVirtualizedTableRow>
        )}
      </FjdStack>
    </FjdLoadingOverlay>
  ) : (
    <p> Es sind keine Daten vorhanden</p>
  );
}
