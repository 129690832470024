import {
  FjdDescriptionList,
  FjdDescriptionListItem,
  FjdModal
} from 'fjd-react-components';
import { PaymentInformation } from './PaymentInformation';

interface PaymentModalModalProps {
  readonly onClose: () => void;
  readonly paymentInformation: PaymentInformation | null;
  readonly isOpen: boolean;
}

export function PaymentModal({
  onClose,
  paymentInformation,
  isOpen
}: PaymentModalModalProps) {
  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={'Zahlungsinformationen'}
      id="payment-modal"
      open={isOpen}
      onClose={onClose}
      primaryButtonDisabled
      secondaryButtonDisabled
      testId="payment-modal"
    >
      <FjdDescriptionList>
        {paymentInformation?.values.map((item, index) => (
          <FjdDescriptionListItem
            key={index}
            description={item.value}
            term={item.label}
            useParagraphs
          ></FjdDescriptionListItem>
        ))}
      </FjdDescriptionList>
    </FjdModal>
  );
}

export default PaymentModal;
