import {
  FileDropField,
  Icon,
  Tooltip
} from '@efast_public/fjd-component-library';
import {
  FjdCollapsibleCard,
  FjdStack,
  FjdTruncatedText
} from 'fjd-react-components';
import {
  Destination,
  OrganizationalUnit
} from '../../../../../hooks/useDestinations';
import KeyUploadState from '../KeyUploadState/KeyUploadState';
import './OrgUnitCollapsibleCard.css';
import { ReactElement } from 'react';

interface OrgUnitCollapsibleCardProps {
  readonly orgUnit: OrganizationalUnit;
  readonly isDisabled: boolean;
  readonly labelNextCard: string | undefined;
  readonly isExpanded: boolean;
  readonly openNextOrgUnit: () => void;
  readonly onAdd: (files: Array<File>, destination: Destination) => void;
}

function OrgUnitCollapsibleCard(
  props: OrgUnitCollapsibleCardProps
): ReactElement {
  const decryptionKeyAvailable: boolean =
    props.orgUnit.destinationDtos.filter(
      (destination) => destination.privateDecryptionKey !== null
    ).length > 0;

  const signingKeyAvailable: boolean =
    props.orgUnit.destinationDtos.filter(
      (destination) => destination.privateSigningKey !== null
    ).length > 0;

  const allKeysUploaded: boolean =
    decryptionKeyAvailable && signingKeyAvailable;

  const onAdd = (files: Array<File>) => {
    if (props.orgUnit.destinationDtos.length <= 0) {
      return;
    }

    props.onAdd(files, props.orgUnit.destinationDtos[0]);
  };
  const updateMode = props.orgUnit?.ouId != null;
  return (
    <div data-testid={props.orgUnit.name}>
      <FjdCollapsibleCard
        heading={
          <div>
            <Tooltip tooltip={props.orgUnit.name}>
              <FjdStack orientation={'horizontal'}>
                {allKeysUploaded ? (
                  <Icon
                    data-testid={`iconSuccess${props.orgUnit.name}`}
                    name="checkmark-filled"
                    variant="success"
                  />
                ) : (
                  <Icon
                    name="circle-dash"
                    data-testid={`icon${props.orgUnit.name}`}
                  />
                )}
                <span className="orgUnitName">
                  <FjdTruncatedText
                    text={props.orgUnit.name}
                  ></FjdTruncatedText>
                </span>

                <span>
                  {
                    props.orgUnit.destinationDtos[0]?.publicServiceTypeDto
                      ?.length
                  }{' '}
                  Verwaltungsleistungen
                </span>
              </FjdStack>
            </Tooltip>
          </div>
        }
        iconCollapsed="chevron-down"
        iconExpanded="chevron-up"
      >
        <div className="uploadContainer">
          <KeyUploadState
            hasUpdateMode={updateMode}
            hasDecryptionKey={decryptionKeyAvailable}
            hasSigningKey={signingKeyAvailable}
            additionalKey={props.orgUnit.name}
          />
          <FileDropField
            data-testid={`upload${props.orgUnit.name}`}
            className="uploadField"
            label="Neue Dateien auswählen"
            dropHelperText="oder in dieses Feld ziehen"
            onAdd={onAdd}
          />
        </div>
      </FjdCollapsibleCard>
    </div>
  );
}

export default OrgUnitCollapsibleCard;
