export enum Bescheidstatus {
  POSITIV = 'POSITIV',
  NEGATIV = 'NEGATIV',
  NOCHNICHTBESCHIEDEN = 'NOCHNICHTBESCHIEDEN',
  UNKNOWN = 'UNKNOWN'
}

export function bescheidstatusToString(value: Bescheidstatus) {
  switch (value) {
    case Bescheidstatus.POSITIV:
      return 'positiv beschieden';
    case Bescheidstatus.NEGATIV:
      return 'negativ beschieden';
    case Bescheidstatus.NOCHNICHTBESCHIEDEN:
      return 'noch nicht beschieden';
    case Bescheidstatus.UNKNOWN:
      return 'Unbekannt';
    default:
      return 'Unbekannt';
  }
}
