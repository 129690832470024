import { FjdSection, FjdStack } from 'fjd-react-components';
import { LabelValue } from '../../../../../models/Case/CaseDetailsModel';
import styles from './Fachdaten.module.css';

interface FachdatenLabelValueProps {
  readonly labelValue: LabelValue | undefined;
  readonly showInSection?: boolean;
  readonly id?: string;
}

export function FachdatenLabelValue({
  labelValue,
  showInSection,
  id
}: FachdatenLabelValueProps) {
  return showInSection ? (
    <FjdSection key={Math.random()} showSeparator={true}>
      <FjdStack
        spacing={'xs'}
        id={'section_header_antragsdaten_0'}
        key={Math.random()}
      >
        <span></span>
        <div id={id} key={Math.random()}>
          <div id="label" className={styles.headerStyle}>
            {`${labelValue?.label}:`}
          </div>
          {labelValue?.label === '' ||
          labelValue?.label === ' ' ||
          labelValue?.label === undefined ||
          labelValue?.label === null ? (
            <label id="value" className={styles.keineAngabenStyle}>
              - Keine Angabe -
            </label>
          ) : labelValue?.value === '- Keine Angabe -' ? (
            <label id="value" className={styles.keineAngabenStyle}>
              {labelValue?.value}
            </label>
          ) : (
            <label id="value" className={styles.valueStyle}>
              {labelValue?.value}
            </label>
          )}
        </div>
      </FjdStack>
    </FjdSection>
  ) : (
    <div id={id} key={Math.random()}>
      <div id="label" className={styles.headerStyle}>
        {`${labelValue?.label}:`}
      </div>
      {labelValue?.label === '' ||
      labelValue?.label === ' ' ||
      labelValue?.label === undefined ||
      labelValue?.label === null ? (
        <label id="value" className={styles.keineAngabenStyle}>
          - Keine Angabe -
        </label>
      ) : labelValue?.value === '- Keine Angabe -' ? (
        <label id="value" className={styles.keineAngabenStyle}>
          {labelValue?.value}
        </label>
      ) : (
        <label id="value" className={styles.valueStyle}>
          {labelValue?.value}
        </label>
      )}
    </div>
  );
}

export default FachdatenLabelValue;
