import { FjdFlexSpacer, FjdStack } from 'fjd-react-components';
import classes from '../../Schreibtisch.module.css';
import { useContext } from 'react';
import { caseDetailsDataContext } from '../../../../context/Context';
import { CaseDetailsModel } from '../../../../models/Case/CaseDetailsModel';
import { mutate } from 'swr';
import ZuweisungComponent from '../../../shared/Zuweisung/ZuweisungComponent';

const ZuweisungHeader = () => {
  const { data, isValidating } = useContext(caseDetailsDataContext);

  const reloadData = (caseDateils: CaseDetailsModel) => {
    mutate([
      `/api/v1/cases/${data.caseId}/submissions`,
      'application/vnd.fjd.beh.message+json'
    ]);
    mutate(
      `/api/v1/cases/${data.caseId}`,
      {
        ...caseDateils,
        bearbeitungsstand: caseDateils.bearbeitungsstand,
        bearbeiterDto: caseDateils.bearbeiterDto ?? null
      },
      true
    );
  };

  return (
    <FjdStack orientation={'vertical'}>
      <FjdFlexSpacer />
      <FjdStack className={classes.zuweisung} orientation="horizontal">
        <ZuweisungComponent
          antrag={data}
          reloadData={reloadData}
          isValidating={isValidating}
          buttonSize="m"
          isSchreibtisch={true}
        ></ZuweisungComponent>
      </FjdStack>
    </FjdStack>
  );
};
export default ZuweisungHeader;
