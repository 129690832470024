import {
  FjdButton,
  FjdCard,
  FjdCheckbox,
  FjdFormControl,
  FjdHeading,
  FjdLoadingOverlay,
  FjdSectionHeader,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import { useState } from 'react';
import {
  KeycloakAssignedGroups,
  KeycloakGroup,
  useInvite,
  UserRequestDto
} from '../../../../hooks/useInvite';
import { useReadUserInviteConfig } from '../../../../hooks/useReadUserInviteConfig';
import { ErrorComponentBehoerdenServer } from '../../../shared/Error/ErrorComponent';
import GroupRoleRow from './GroupRoleRow/GroupRoleRow';
import './UsersAndRoles.css';
import { getMandant } from '../../../../utils/mandant';

const SYSTEM_GROUP_CONSTANT: string = 'Administration';

export default function UsersAndRoles() {
  const [email, setEmail] = useState('');
  const { invite, loading } = useInvite();
  const {
    inviteConfig,
    error: useReadUserInviteConfigError,
    isValidating
  } = useReadUserInviteConfig();
  const [selectedKeycloakGroups, setSelectedKeycloakGroups] = useState<
    Array<KeycloakAssignedGroups>
  >([]);
  const assignableGroups: Array<KeycloakAssignedGroups> = [];
  const convertedMap: Map<string, Array<KeycloakGroup>> = new Map(
    Object.entries(inviteConfig ? inviteConfig.groupRoles : {})
  );

  convertedMap.forEach((value: Array<KeycloakGroup>, key: string) => {
    assignableGroups.push({ groupName: key, subGroups: value });
  });

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function areSelectedRolesValid() {
    const hasSelectedAdminRole: boolean = selectedKeycloakGroups.some(
      (k) => k.groupName === SYSTEM_GROUP_CONSTANT
    );
    const hasNotSelectedSubGroupName: boolean = selectedKeycloakGroups.some(
      (k) =>
        k.groupName &&
        k.groupName !== SYSTEM_GROUP_CONSTANT &&
        k.subGroups.length === 0
    );
    if (hasNotSelectedSubGroupName) {
      return false;
    }
    if (hasSelectedAdminRole) {
      return true;
    }
    return selectedKeycloakGroups.length > 0;
  }

  const isInputValid = () => {
    if (email === '') {
      return false;
    }

    if (!isValidEmail(email)) {
      return false;
    }
    return areSelectedRolesValid();
  };

  const onInviteRequest = async (
    emailInput: string,
    groups: Array<KeycloakAssignedGroups>
  ) => {
    await invite({
      email: emailInput,
      groups: groups,
      redirectUri: `${window.location.origin}/${getMandant()}`
    } as UserRequestDto);
  };

  const checkedRadio = (groupName: string, groupIds: Array<string>) => {
    const assign = selectedKeycloakGroups.find(
      (value) => value.groupName === groupName
    );

    if (!assign) {
      return false;
    }

    if (assign.subGroups.length !== groupIds.length) {
      return false;
    }

    let correct = true;

    for (const subgroup of assign.subGroups) {
      let innerCorrect = false;

      if (groupIds.some((id) => id === subgroup.id)) {
        innerCorrect = true;
      }

      correct = correct && innerCorrect;
    }
    return correct;
  };

  const checkRadioDisabled = (groupName: string) => {
    return (
      selectedKeycloakGroups.find((value) => value.groupName === groupName) ===
      undefined
    );
  };

  const onRadioChanged = (
    checked: boolean,
    groupName: string,
    subgroups: Array<KeycloakGroup>
  ) => {
    if (checked) {
      setSelectedKeycloakGroups((prev) =>
        prev.map((value) =>
          value.groupName === groupName
            ? ({
                groupName: value.groupName,
                subGroups: subgroups
              } as KeycloakAssignedGroups)
            : value
        )
      );
    }
  };

  const onSystemRoleChange = (checked: boolean, groupName: string) => {
    setSelectedKeycloakGroups((prev) => {
      if (checked) {
        return [
          ...prev,
          {
            groupName: groupName,
            subGroups: []
          } as KeycloakAssignedGroups
        ];
      } else {
        return prev.filter((value) => value.groupName !== groupName);
      }
    });
  };

  const onChangeCheckbox = (checked: boolean, groupName: string) => {
    if (checked) {
      setSelectedKeycloakGroups((prev) => [
        ...prev,
        { groupName: groupName, subGroups: [] } as KeycloakAssignedGroups
      ]);
    } else {
      setSelectedKeycloakGroups((prev) =>
        prev.filter((value) => value.groupName !== groupName)
      );
    }
  };
  return (
    <div style={{ width: '50rem' }}>
      <FjdStack spacing="l">
        <FjdSectionHeader
          align="center"
          heading={
            <FjdHeading
              id={'einstellungen'}
              level={2}
              text={'Benutzer:in einladen und Rollen vergeben'}
            />
          }
        />
        <div>
          Hier können Sie weitere Mitarbeitende zum Behörden-Client einladen.
          Fügen Sie eine E-Mail-Adresse ein und wählen Sie die gewünschten
          Rollen aus.
        </div>
        <FjdCard>
          <FjdStack spacing="xl">
            <FjdLoadingOverlay loading={isValidating || loading}>
              {useReadUserInviteConfigError ? (
                <ErrorComponentBehoerdenServer
                  errorCode={useReadUserInviteConfigError?.traceId}
                />
              ) : (
                <>
                  <FjdTextInput
                    id="emailToRegister"
                    type="text"
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                    placeholder="example@abc.com"
                    disabled={loading}
                    pattern={/\S+@\S+\.\S+/.source}
                  />
                  <div className={'emailModal'}>
                    <div style={{ width: '70%' }}>
                      <FjdFormControl
                        inputId="group"
                        label="Rollen nach Gruppen"
                      >
                        <FjdStack
                          id="groupRowStack"
                          className="stackLeft"
                          orientation="vertical"
                        >
                          {assignableGroups.map(
                            (value: KeycloakAssignedGroups) => {
                              return (
                                <GroupRoleRow
                                  key={`${value.groupName}row`}
                                  keycloakAssignedGroups={value}
                                  onChangeGroupCheckbox={onChangeCheckbox}
                                  onRoleRadioChanged={onRadioChanged}
                                  isRoleRadioDisabled={checkRadioDisabled}
                                  isRoleRadioChecked={checkedRadio}
                                />
                              );
                            }
                          )}
                        </FjdStack>
                      </FjdFormControl>
                    </div>
                    <div style={{ width: '30%' }}>
                      <FjdFormControl inputId="role" label="Systemrollen">
                        <FjdStack id="systemGroupStack" orientation="vertical">
                          {inviteConfig?.systemRoles.map(
                            (value: KeycloakGroup) => (
                              <FjdCheckbox
                                label={value.name}
                                id={`rolle_${value.name}`}
                                key={`key_rolle_${value.name}`}
                                onChange={(event) =>
                                  onSystemRoleChange(
                                    event.target.checked,
                                    value.name
                                  )
                                }
                              ></FjdCheckbox>
                            )
                          )}
                        </FjdStack>
                      </FjdFormControl>
                    </div>
                  </div>
                </>
              )}
            </FjdLoadingOverlay>

            {!useReadUserInviteConfigError && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FjdButton
                  id="submitButton"
                  disabled={!isInputValid() || loading}
                  label="Einladung versenden"
                  onClick={() => onInviteRequest(email, selectedKeycloakGroups)}
                ></FjdButton>
              </div>
            )}
          </FjdStack>
        </FjdCard>
      </FjdStack>
    </div>
  );
}
