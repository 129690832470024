import { FjdNavigationBar, FjdNavigationBarItem } from 'fjd-react-components';
import { useMatch } from 'react-router-dom';

import { RouteKey, routes } from '../../../utils/router';
import { RouterLink } from '../../../utils/Router/RouterLink';
import useTabs from '../../../hooks/useTabs';
import { MouseEventHandler, useContext } from 'react';
import { errorContext } from '../../../context/Context';

export function MainNavigation() {
  const casesPageMatch = useMatch(routes[RouteKey.ANTRAEGE].path);
  const schreibtischPageMatch = useMatch(
    `${routes[RouteKey.SCHREIBTISCH].path}*`
  );
  const { activeTab } = useTabs();
  const activeTabId =
    activeTab && activeTab?.id !== 'empty' ? activeTab?.id : '';
  const { behError } = useContext(errorContext);
  const hasError = behError.status !== undefined;
  const schreibtischTab = (
    href: string,
    onClick: MouseEventHandler<HTMLAnchorElement>
  ) => {
    return !hasError ? (
      <FjdNavigationBarItem
        href={href}
        disabled={hasError}
        active={!!schreibtischPageMatch}
        icon="edit"
        label={'Schreibtisch'}
        onClick={onClick}
      />
    ) : (
      <></>
    );
  };
  return (
    <FjdNavigationBar>
      <RouterLink
        component={({ href, onClick }) => (
          <FjdNavigationBarItem
            href={href}
            active={!!casesPageMatch}
            icon="table-split"
            label={'Antragsübersicht'}
            onClick={onClick}
          />
        )}
        to={`/antraege`}
      />

      <RouterLink
        component={({ href, onClick }) => schreibtischTab(href, onClick)}
        to={`/schreibtisch/${activeTabId}`}
      />
    </FjdNavigationBar>
  );
}
