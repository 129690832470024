import { FjdStack } from 'fjd-react-components';
import IConfigAssistantStep from '../IConfigAssistantStep';
import { Checkbox } from '@efast_public/fjd-component-library';
import './CheckList.css';
import { ReactElement, useEffect, useState } from 'react';
interface ChecklistProps extends IConfigAssistantStep {}
interface Checkboxes {
  fitConnect: boolean;
  keys: boolean;
}
function CheckList(props: ChecklistProps): ReactElement {
  const [checkBoxes, setCheckBoxes] = useState<Checkboxes>({
    fitConnect: false,
    keys: false
  });

  const { setButtonLabel } = props;

  useEffect(() => setButtonLabel('Weiter'), [setButtonLabel]);

  const adjustProgress = (value: boolean) => {
    if (value) {
      props.addProgress(5);
    } else {
      props.subtractProgress(5);
    }
  };

  const changeValue = (field: string, newValue: boolean) => {
    setCheckBoxes((value: Checkboxes) => {
      return {
        ...value,
        [field]: newValue
      };
    });

    adjustProgress(newValue);
  };

  useEffect(() => {
    if (checkBoxes.fitConnect && checkBoxes.keys) {
      props.nextStepAvailable(true);
    } else {
      props.nextStepAvailable(false);
    }
  }, [checkBoxes, props]);

  useEffect(() => {
    setButtonLabel('Einrichtung starten');
  }, [setButtonLabel]);

  return (
    <div>
      <div className="contentHeading">
        Checkliste zur Einrichtung (Schritt 1 von 7)
      </div>
      <div className="descriptionText">
        Haben Sie die folgenden Informationen & Dateien zur Hand?
      </div>
      <FjdStack className="checkBoxes" spacing="xs" orientation="vertical">
        <Checkbox
          checked={checkBoxes.fitConnect}
          onChange={(event) => {
            changeValue('fitConnect', event.currentTarget.checked);
          }}
          id={''}
          label="FIT-Connect Client-ID & Client-Secret"
        />
        <Checkbox
          checked={checkBoxes.keys}
          onChange={(event) => {
            changeValue('keys', event.currentTarget.checked);
          }}
          id={''}
          label="Private Keys für jeden FIT-Connect Zustellpunkt"
        />
      </FjdStack>
      <div className="subText">
        Optionale Einstellungen (auch später im Behörden-Client noch
        konfigurierbar)
        <ul className="noBullets">
          <li> → Datenmodelle</li>
          <li> → Footerlinks</li>
        </ul>
      </div>
    </div>
  );
}

export default CheckList;
