import React from 'react';
import { initialCaseDetailsData } from './CaseDetailsDataContext/CaseDetailsDataContext';
import { BEHError } from '../utils/Error/ErrorCode';
import { initialBehError } from './ErrorContext/ErrorContext';

export const caseDetailsDataContext = React.createContext({
  data: initialCaseDetailsData,
  refresh: () => {},
  isValidating: false
});

export const errorContext = React.createContext({
  behError: initialBehError,
  setBehError: (error: BEHError) => {}
});
