export function extractId(file: string, type: string): string | null {
  if (type === 'application/json') {
    return extractJson(file);
  } else if (type === 'text/xml') {
    return extractXml(file);
  } else {
    return null;
  }
}

function extractXml(xml: string): string | null {
  const xmlDocument = new DOMParser().parseFromString(xml, 'text/xml');
  if (xmlDocument.getElementsByTagName('xdf:id').length > 0) {
    return xmlDocument.getElementsByTagName('xdf:id')[0].textContent;
  }

  return null;
}

function extractJson(json: string): string | null {
  const jsonObject = JSON.parse(json);
  const idString: string = jsonObject['$id'];

  if (idString !== undefined) {
    const idSplit = idString.split('_');
    return idSplit[0].replace('https://schema.fitko.de/fim/', '');
  }

  return null;
}
