import { AntragObj } from '../models/Antrag/AntragsModel';
import { getCasePath } from '../utils/router';
import { useNavigate } from 'react-router-dom';
import { CaseDetailsModel } from '../models/Case/CaseDetailsModel';

export function useElementNavigation() {
  const navigate = useNavigate();

  const navigateToElement = (
    element: CaseDetailsModel | AntragObj,
    replace?: boolean,
    state?: any
  ) => {
    if (element) {
      navigate(getCasePath(element.caseId), { replace, state });
    }
  };

  return { navigateToElement };
}
