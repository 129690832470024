import {
  FileDropField,
  Section,
  Stack,
  FileList,
  Select,
  Alert,
  List
} from '@efast_public/fjd-component-library';
import { ReactElement, useState } from 'react';
import { SelectOption } from '../../../../utils/antragsuebersicht';
import './DataModelUpload.css';
import useDataModel, {
  ImportDatamodelDto
} from '../../../../hooks/useDataModel';
import { fileToString } from '../../../../utils/fileToString';
import useAlerts from '../../../../hooks/useAlerts';
import { extractId } from './extractId';
import { FjdHeading } from 'fjd-react-components';
import DataModelUploadWrapper from './DataModelUploadWrapper';

interface DatamodelUploadModalProps {
  readonly isOpen: boolean;
  readonly setOpenModal: (value: boolean) => void;
  readonly datamodels: Array<UploadStateDatamodel>;
  readonly isComponent?: boolean;
}

interface UploadStateDatamodel {
  identifier: string;
  isAvailable: boolean;
}

interface SelectedIdentifier {
  label: string | null;
  hasIdentifierInFile: boolean;
  hasError: boolean;
}

function DatamodelUpload(props: DatamodelUploadModalProps): ReactElement {
  const { putDatamodels, putError, mutate } = useDataModel();

  const { alert } = useAlerts();

  const [uploadedFiles, setUploadedFiles] = useState(
    new Map<File, SelectedIdentifier>()
  );
  const [loading, setLoading] = useState<boolean>(false);

  const datamodelsMissing = props.datamodels.some((v) => !v.isAvailable);

  const addNewItem = async (key: File, value: string | null) => {
    if (
      key.type !== 'application/json' &&
      key.type !== 'application/xml' &&
      key.type !== 'text/xml'
    ) {
      alert(
        'error',
        'Ungültiges Dateiformat. Es sind nur Dateien im Format XML oder JSON zulässig.',
        10000,
        true
      );
      return;
    }

    try {
      const fileString = await fileToString(key);
      const identifier: string | null = extractId(fileString, key.type);
      if (identifier !== null) {
        if (
          props.datamodels.some(
            (v: UploadStateDatamodel) => v.identifier === identifier
          )
        ) {
          setUploadedFiles(
            (prevMap) =>
              new Map(
                prevMap.set(key, {
                  label: identifier,
                  hasIdentifierInFile: true,
                  hasError: false
                })
              )
          );
          return;
        } else {
          setUploadedFiles(
            (prevMap) =>
              new Map(
                prevMap.set(key, {
                  label: null,
                  hasIdentifierInFile: true,
                  hasError: true
                })
              )
          );
          return;
        }
      }

      setUploadedFiles(
        (prevMap) =>
          new Map(
            prevMap.set(key, {
              label: value,
              hasIdentifierInFile: false,
              hasError: false
            })
          )
      );
    } catch {
      alert(
        'error',
        'Beim Hochladen ist leider ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
        10000
      );
    }
  };

  const removeItem = (key: File) => {
    setUploadedFiles((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.delete(key);
      return newMap;
    });
  };
  const getMimeType = (mimeType: string) => {
    if (mimeType === 'text/xml') {
      return 'application/xml';
    }

    return mimeType;
  };
  const uploadDatamodels: () => void = async () => {
    setLoading(true);
    let importModels: Array<ImportDatamodelDto> = [];
    try {
      const entries = Array.from(uploadedFiles.entries());
      importModels = await Promise.all(
        entries.map(async ([file, verwaltungsleistung]) => {
          const fileString: string = await fileToString(file);
          return {
            identifier: verwaltungsleistung?.label,
            importModel: fileString,
            mimeType: getMimeType(file.type),
            hasIdentifierInFile: verwaltungsleistung.hasIdentifierInFile
          } as ImportDatamodelDto;
        })
      );
    } catch {
      setLoading(false);
      alert(
        'error',
        'Beim Lesen der Datei(en) ist leider ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
        10000
      );
      return;
    }
    putDatamodels(
      importModels.filter(
        (value: ImportDatamodelDto) => value.identifier !== null
      )
    )
      .then(() => {
        mutate();
        onClose();
        alert(
          'success',
          'Ein oder mehrere Datenmodell(e) wurden erfolgreich hochgeladen.',
          10000
        );
      })
      .catch(() => {
        alert(
          'error',
          'Es ist ein Fehler aufgetreten. (Server nicht erreichbar oder fehlerhaft)',
          10000
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClose = () => {
    props.setOpenModal(false);
    setUploadedFiles(new Map());
  };

  const hasNullValuesWithoutMatchingError: boolean = Array.from(
    uploadedFiles.values()
  ).some((value: SelectedIdentifier | null) => {
    if (value === null) {
      return false;
    }

    return value.label === null && !value.hasError;
  });

  const hasAlertDatamodelNotAllowed: boolean = Array.from(
    uploadedFiles.values()
  ).some((value: SelectedIdentifier) => value.hasError);

  const modalContent: ReactElement = datamodelsMissing ? (
    <Section>
      <div className="contentHeading">
        Zu folgenden Verwaltungsleistungen konnte jeweils kein Datenmodell
        importiert werden
      </div>
      <List listType="bullet">
        {props.datamodels
          .filter((v) => !v.isAvailable)
          .map((datamodel) => (
            <List.ListItem key={datamodel.identifier}>
              {datamodel.identifier}
            </List.ListItem>
          ))}
      </List>
      <div className="descriptionText">
        Bitte laden Sie die fehlenden Datenmodelle hoch (erlaubte Formate:
        JSON,XML). <br />
        Sobald Sie eine oder mehrere Dateien ausgewählt haben, können Sie diese
        der jeweils zugehörigen Verwaltungsleistung zuordnen.
      </div>
    </Section>
  ) : (
    <Section>
      {props.isComponent ? (
        <>
          <FjdHeading
            id={'datamodels'}
            level={2}
            text={
              'Laden Sie Datenmodelle für einzelne Verwaltungsleistungen hoch'
            }
          />
          <br />
        </>
      ) : (
        <div className="contentHeading">
          Laden Sie Datenmodelle für einzelne Verwaltungsleistungen hoch
        </div>
      )}
      <div className="descriptionText">
        Sobald Sie eine oder mehrere Dateien ausgewählt haben, können Sie diese
        der jeweils zugehörigen Verwaltungsleistung zuordnen. Erlaubte Formate:
        JSON, XML.
      </div>
    </Section>
  );

  return (
    <DataModelUploadWrapper
      onClose={onClose}
      onSave={uploadDatamodels}
      isLoading={loading}
      isOpen={props.isOpen}
      isComponent={props.isComponent ?? false}
      isPrimaryButtonDisabled={
        hasNullValuesWithoutMatchingError ||
        hasAlertDatamodelNotAllowed ||
        uploadedFiles.size === 0
      }
    >
      <Stack direction="column" spacing="xxl" alignItems="stretch">
        {modalContent}
        <FileDropField
          disabled={loading}
          label="Datenmodelle hochladen"
          data-testid={'dataModel_file_drop_field'}
          dropHelperText="oder in dieses Feld ziehen"
          onAdd={(files) => files.map((file) => addNewItem(file, null))}
        />
        <FileList>
          {uploadedFiles &&
            Array.from(uploadedFiles.keys()).map((file, index) => (
              <Stack
                direction="row"
                spacing="l"
                key={`fileStack_${index}`}
                style={{ width: '100%', justifyContent: 'space-between' }}
              >
                {uploadedFiles.get(file)?.hasError ? (
                  <FileList.FileItemUpload
                    className="datamodel-file-list-item"
                    file={{
                      error: 'Unzulässiges Datenmodell',
                      name: file.name,
                      lastModified: file.lastModified,
                      size: file.size
                    }}
                    onDelete={() => removeItem(file)}
                  />
                ) : (
                  <FileList.FileItem
                    className="datamodel-file-list-item"
                    file={file}
                    onDelete={() => removeItem(file)}
                  />
                )}

                <Stack
                  direction="column"
                  justifyContent="center"
                  style={{ minWidth: '18rem' }}
                >
                  <div>Zugehörige Verwaltungsleistung</div>
                  <Select
                    disabled={uploadedFiles.get(file)?.hasIdentifierInFile}
                    selectedOptions={[
                      {
                        label: uploadedFiles.get(file)?.label ?? '',
                        value: uploadedFiles.get(file)?.label ?? ''
                      }
                    ]}
                    data-testid={`dataModel-select-${file.name}`}
                    options={props.datamodels
                      .filter(
                        (item, index) =>
                          props.datamodels.indexOf(item) === index
                      )
                      .map(
                        (datamodel) =>
                          ({
                            label: datamodel.identifier,
                            value: datamodel.identifier,
                            disabled: Array.from(uploadedFiles.values())
                              .map((v) => v?.label)
                              .includes(datamodel.identifier)
                          }) as SelectOption
                      )}
                    translations={{
                      deleteText: 'Löschen'
                    }}
                    selectType="single"
                    onChange={(selectedItems) =>
                      selectedItems.length > 0
                        ? addNewItem(file, selectedItems[0].value)
                        : addNewItem(file, null)
                    }
                  />
                </Stack>
              </Stack>
            ))}
        </FileList>
      </Stack>

      <Stack
        direction="column"
        spacing="xs"
        alignItems="stretch"
        style={{
          width: '100%',
          paddingTop: '1.75rem',
          paddingBottom: '0.2rem'
        }}
      >
        {uploadedFiles.size > 0 && !putError && (
          <Alert
            data-testid="datamodel-alert-success"
            message="Erfolgreich hochgeladen!"
            variant="success"
          />
        )}
        {uploadedFiles.size > 0 && hasNullValuesWithoutMatchingError && (
          <Alert
            data-testid="datamodel-alert-warning"
            message="Sie haben noch nicht zu jedem Datenmodell eine zugehörige Verwaltungsleistung ausgewählt."
            variant="warning"
          />
        )}
        {putError && (
          <Alert
            data-testid="datamodel-alert-error"
            message="Es ist ein Fehler aufgetreten. Bitten wenden Sie sich an Ihre:n Administrator:in."
            variant="error"
          />
        )}
        {hasAlertDatamodelNotAllowed && (
          <Alert
            data-testid="datamodel-alert-error-match"
            message="Das vorliegende Datenmodell konnte nicht zu einer Verwaltungsleistung dieses Zustellpunkts zugeordnet werden. Bitte überprüfen Sie die ID des Datenmodells und in FIT-Connect die Verwaltungsleistungen."
            variant="error"
          />
        )}
      </Stack>
    </DataModelUploadWrapper>
  );
}

export default DatamodelUpload;
