import {
  FjdButton,
  FjdIcon,
  FjdStack,
  FjdTooltip,
  FjdSpinner
} from 'fjd-react-components';
import {
  FjdButtonAppearance,
  FjdButtonSize
} from 'fjd-react-components/build/components/Button/Button';
import { useEffect, useState } from 'react';
import { useProfile } from '../../../hooks/useProfile';
import { AntragObj } from '../../../models/Antrag/AntragsModel';
import { CaseDetailsModel } from '../../../models/Case/CaseDetailsModel';
import useAssignment from '../../../hooks/useAssignment';
import { ErrorComponentBehoerdenServer } from '../Error/ErrorComponent';
import { Bearbeitungsstand } from '../../../models/Status/Bearbeitungsstand';

interface ZuweisungSachbearbeiterProps {
  readonly antragObj: AntragObj | CaseDetailsModel;
  readonly reloadData: (caseDetails: CaseDetailsModel) => void;
  readonly isSchreibtisch: boolean;
  readonly isValidating?: boolean;
  readonly buttonSize?: FjdButtonSize;
}

function ZuweisungSachbearbeiter(
  props: ZuweisungSachbearbeiterProps
): JSX.Element {
  const { profile, error } = useProfile();
  const [zuweisungUpdating, setZuweisungUpdating] = useState<boolean>(false);
  const { assignCase, revokeCase } = useAssignment({
    reloadData: props.reloadData,
    antragObj: props.antragObj,
    setZuweisungUpdating: setZuweisungUpdating,
    isValidating: props.isValidating
  });
  const angemeldeterUserIstBearbeiter =
    props.antragObj.bearbeiterDto?.id === profile?.id;
  const caseAlreadyEdited =
    props.antragObj.firstInteractionTimestamp !== null &&
    props.antragObj.firstInteractionTimestamp !== undefined;
  const id = props.isSchreibtisch
    ? `zuweisungsButton_${props.antragObj.caseId}`
    : `bearbeiterCol_${props.antragObj.caseId}`;
  const idContainer = props.isSchreibtisch
    ? `zuweisungsButtonContainer_${props.antragObj.caseId}`
    : `bearbeiterColContainer_${props.antragObj.caseId}`;

  const buttonSize = props.buttonSize ?? 's';
  const currentBearbeiter: string | undefined =
    props.antragObj.bearbeiterDto?.fullName;
  const appearance: FjdButtonAppearance = props.isSchreibtisch
    ? 'outline'
    : 'default';

  useEffect(() => {
    if (props.isValidating !== undefined) {
      setZuweisungUpdating(props.isValidating);
    }
  }, [props.isValidating]);

  if (zuweisungUpdating) {
    return <FjdSpinner />;
  }

  const revokeAssignment = async () => {
    await revokeCase(props.antragObj.version);
  };

  const assignCaseToBearbeiter = async () => {
    const id = profile?.id ? profile.id : '';
    await assignCase(id, props.antragObj.version);
  };

  // noch keinem zugewiesen
  if (
    !currentBearbeiter &&
    props.antragObj.bearbeitungsstand !== Bearbeitungsstand.ABGESCHLOSSEN
  ) {
    return (
      <FjdButton
        id={id}
        onClick={assignCaseToBearbeiter}
        appearance={appearance}
        iconLeft={'user-avatar-filled-alt'}
        size={buttonSize}
        label={'Mir zuweisen'}
      />
    );
  }
  // mir zugewiesen aber noch nicht bearbeitet
  if (
    angemeldeterUserIstBearbeiter &&
    !caseAlreadyEdited &&
    currentBearbeiter
  ) {
    return (
      <FjdButton
        onClick={revokeAssignment}
        id={id}
        appearance={'outline'}
        iconRight={'close-outline'}
        size={buttonSize}
        label={currentBearbeiter}
      />
    );
  }
  // mir zugewiesen aber schon bearbeitet
  if (angemeldeterUserIstBearbeiter && caseAlreadyEdited && currentBearbeiter) {
    return (
      <FjdStack orientation={'horizontal'} id={idContainer}>
        <FjdButton
          id={id}
          disabled={true}
          appearance={'outline'}
          size={buttonSize}
          label={currentBearbeiter}
        />
        <FjdTooltip
          tooltip={
            'Dieser Antrag ist bereits zu weit bearbeitet, um die Zuweisung zurückzunehmen. Für Änderungen kontaktieren Sie bitte Ihre:n Vorgesetzte:n.'
          }
        >
          <FjdIcon glyph="information-outline" appearance={'info'} />
        </FjdTooltip>
      </FjdStack>
    );
  }

  if (error) {
    return <ErrorComponentBehoerdenServer />;
  }

  // assigned to another user
  return props.isSchreibtisch ? (
    <FjdStack orientation={'horizontal'} id={idContainer}>
      <div id={id}>
        <b>Bearbeiter:in: </b>&nbsp; {currentBearbeiter}
      </div>
    </FjdStack>
  ) : (
    <span id={id}>{currentBearbeiter ?? 'Keine Bearbeiter:in'}</span>
  );
}

export default ZuweisungSachbearbeiter;
