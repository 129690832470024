import classes from '../../../Schreibtisch.module.css';
import React, { useEffect, useState } from 'react';
import {
  FjdButton,
  FjdCollapsibleCard,
  FjdSpacer,
  FjdStack
} from 'fjd-react-components';
import VermerkModal from './VermerkModal';
import { useVermerk } from '../../../../../hooks/useVermerk';
import { VermerkDto } from '../../../../../models/Vermerk/VermerkDto';
import { CaseDetailsModel } from '../../../../../models/Case/CaseDetailsModel';
import { Bearbeitungsstand } from '../../../../../models/Status/Bearbeitungsstand';
import VermerkVerlauf from './VermerkVerlauf';
import DeleteVermerkConfirmationModal from '../../../../shared/DeleteVermerkConfirmationModal';

export interface VermerkProps {
  readonly caseDetails: CaseDetailsModel;
  readonly onStateChanged: () => void;
}

const VermerkData = ({ caseDetails, onStateChanged }: VermerkProps) => {
  const { createVermerk, updateVermerk, deleteVermerk, loading } = useVermerk();
  const [openVermerkModal, setOpenVermerkModal] = useState(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [vermerkBeEdited, setVermerkBeEdited] = useState<VermerkDto>();
  const [vermerkBeDeleted, setVermerkBeDeleted] = useState<VermerkDto>();
  const [vermerk, setVermerk] = useState<Array<VermerkDto> | undefined>(
    undefined
  );
  const [openCard, setOpenCard] = useState(false);
  const bereitsAbgeschlossen =
    caseDetails.bearbeitungsstand === Bearbeitungsstand.ABGESCHLOSSEN;

  const vermerkDetails: Array<VermerkDto> | undefined | null =
    caseDetails.vermerkDto;

  const vermerkAvailable =
    caseDetails.vermerkDto && caseDetails.vermerkDto.length > 0;

  const caseId = caseDetails.caseId;

  const hasVermerk = vermerk && vermerk?.length > 0;

  useEffect(() => {
    if (vermerkAvailable) {
      setVermerk(vermerkDetails ? vermerkDetails : undefined);
      setOpenCard(true);
    } else {
      setVermerk(undefined);
    }
  }, [vermerkAvailable, vermerkDetails]);

  function handleChildClick(e: React.MouseEvent) {
    setCreateMode(true);
    e.stopPropagation();
    setOpenVermerkModal(true);
  }

  function onEditVermerk(vermerkData: VermerkDto) {
    setCreateMode(false);
    setVermerkBeEdited(vermerkData);
    setOpenVermerkModal(true);
  }

  function onDeleteVermerk(vermerkData: VermerkDto) {
    setVermerkBeDeleted(vermerkData);
    setOpenDeleteConfirmationModal(true);
  }

  return (
    <FjdStack orientation="vertical">
      <FjdCollapsibleCard
        iconExpanded="chevron-up"
        iconCollapsed="chevron-down"
        headingColor="background-alternate"
        expanded={openCard}
        onToggle={(expanded) => {
          setOpenCard(expanded);
        }}
        heading={
          <FjdStack orientation="horizontal">
            <h2 style={{ marginTop: '3px', marginLeft: '10px' }}>Vermerk</h2>
            <h2
              id={'vermerk_length'}
              style={{
                marginLeft: '-5px',
                marginTop: '3px',
                fontWeight: 'normal'
              }}
            >
              ({hasVermerk ? vermerk?.length : '0'})
            </h2>
          </FjdStack>
        }
        rightSectionHeading={
          <FjdButton
            disabled={bereitsAbgeschlossen}
            id="add-vermerk"
            appearance="primary-link-invert"
            iconLeft="add-outline"
            label="Vermerk hinzufügen"
            size="s"
            onClick={(e) => handleChildClick(e)}
          />
        }
      >
        <div
          style={{ marginLeft: '10px', marginRight: '10px' }}
          className={classes.collapsibleCardBody}
        >
          {hasVermerk ? (
            vermerkDetails?.map((vermerkData) => {
              return (
                <FjdStack orientation="vertical" key={Math.random()}>
                  <VermerkVerlauf
                    vermerk={vermerkData}
                    caseId={caseId}
                    hasBereitsAbgeschlossen={bereitsAbgeschlossen}
                    onEditVermerk={onEditVermerk}
                    onDeleteVermerk={onDeleteVermerk}
                    lastVermerk={vermerkDetails?.at(-1)}
                  />
                  <FjdSpacer size={'s'} />
                </FjdStack>
              );
            })
          ) : (
            <div>Es liegt kein Vermerk vor.</div>
          )}
        </div>
      </FjdCollapsibleCard>
      <VermerkModal
        onClose={() => setOpenVermerkModal(false)}
        onSave={(vermerk) => {
          return createMode
            ? createVermerk(vermerk, caseId)
            : updateVermerk(vermerk, caseId);
        }}
        onStateChanged={onStateChanged}
        disableInput={loading}
        vermerk={createMode ? undefined : vermerkBeEdited}
        isOpen={openVermerkModal}
      ></VermerkModal>
      <DeleteVermerkConfirmationModal
        onClose={() => setOpenDeleteConfirmationModal(false)}
        onDelete={(vermerk: VermerkDto) =>
          deleteVermerk(vermerk, caseId).finally(() => {
            setOpenDeleteConfirmationModal(false);
            onStateChanged();
          })
        }
        vermerk={vermerkBeDeleted ?? ({} as VermerkDto)}
        isOpen={openDeleteConfirmationModal}
      />
    </FjdStack>
  );
};
export default VermerkData;
