import { FjdButton, FjdPagination, FjdStack } from 'fjd-react-components';
import { Except } from 'type-fest';
import { PaginationStyle, usePagination } from '../../hooks/usePagination';

interface PaginationProps {
  readonly elementLabel?: string;
  readonly pagination: Except<
    ReturnType<typeof usePagination>,
    'PaginationComponent'
  >;
  readonly paginationStyle?: PaginationStyle;
}

export function Pagination({
  pagination,
  paginationStyle = 'default'
}: PaginationProps) {
  return paginationStyle === 'default' ? (
    <FjdPagination
      currentPage={pagination.currentPage}
      onNextPage={pagination.nextPage}
      onPrevPage={pagination.prevPage}
      onSetPage={pagination.setPage}
      pageSize={pagination.pageSize}
      rowCount={pagination.totalItems}
      pageSizeOptions={[10, 25, 50, 100]}
      onPageSizeOptionClicked={(pageSize: number) => {
        pagination.setPageSize(pageSize);
        pagination.setPage(0);
      }}
    />
  ) : (
    <FjdStack orientation="horizontal" spacing="s">
      <FjdButton
        appearance="secondary"
        disabled={pagination.currentPage <= 0}
        hideLabel
        iconLeft="arrow-left"
        label={'Vorherige Seite'}
        onClick={pagination.prevPage}
        size="s"
      />
      <FjdButton
        appearance="secondary"
        disabled={pagination.currentPage + 1 >= pagination.pages}
        hideLabel
        iconLeft="arrow-right"
        label={'Nächste Seite'}
        onClick={pagination.nextPage}
        size="s"
      />
    </FjdStack>
  );
}
