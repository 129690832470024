import { FjdButton } from 'fjd-react-components';
import { useRef } from 'react';
import useElementOnScreen from '../../../../hooks/useElementOnScreen';
import './AntragsuebersichtTableFooter.css';

interface AntragsuebersichtTableFooterProps {
  readonly openSelection: () => void;
  readonly resetSelection: () => void;
  readonly sectionLength: number;
}

export default function AntragsuebersichtTableFooter({
  openSelection,
  resetSelection,
  sectionLength
}: AntragsuebersichtTableFooterProps) {
  const ref = useRef<HTMLDivElement>(null);
  const footerIsOnScreen = useElementOnScreen(ref);

  const renderFooterOverlay = !footerIsOnScreen && sectionLength !== 0;

  const FooterContent = (): JSX.Element => (
    <>
      <FjdButton
        appearance="primary-link"
        iconLeft="new-tab"
        label={`Auswahl auf Schreibtisch öffnen (${sectionLength})`}
        id="antraege-oeffnen"
        testId={'antraege-oeffnen'}
        onClick={openSelection}
        size="s"
      />
      <FjdButton
        appearance="primary-link"
        iconLeft="reset"
        id="antraege-abwaehlen"
        testId={'antraege-abwaehlen'}
        label={'Auswahl aufheben'}
        onClick={resetSelection}
        size="s"
      />
    </>
  );

  return (
    <>
      <div ref={ref} id="tableFooter">
        {sectionLength !== 0 && <FooterContent />}
      </div>

      <div
        id="tableFooterSticky"
        className={renderFooterOverlay ? 'sticky stickyVisible' : 'sticky'}
      >
        {renderFooterOverlay && <FooterContent />}
      </div>
    </>
  );
}
