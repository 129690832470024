import React from 'react';
import { FjdStack } from 'fjd-react-components';
import { Link } from 'react-router-dom';
import { RouteKey, routes } from '../../utils/router';

const EmptySchreibtischNoTabs = () => {
  return (
    <React.Fragment>
      <FjdStack orientation="horizontal" spacing="xs">
        <div>
          Bitte öffnen Sie einen oder mehrere Anträge über die Antragsübersicht.
        </div>
        <Link id={'link-antragsuebersicht'} to={routes[RouteKey.ANTRAEGE].path}>
          Zur Antragsübersicht
        </Link>
      </FjdStack>
    </React.Fragment>
  );
};

export default EmptySchreibtischNoTabs;
