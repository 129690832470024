import { FjdButton, FjdSpinner, FjdStack } from 'fjd-react-components';
import React, { useContext, useState } from 'react';
import classes from '../../Schreibtisch.module.css';
import {
  caseDetailsMapper,
  extractAttachmentRequestData,
  FachdatenNode
} from '../../../../models/Case/CaseDetailsModel';
import Ausweisdaten from './Ausweisdaten/Ausweisdaten';
import Metadaten from './Metadaten/Metadaten';
import AttachmentPreviewModal from '../../../shared/AttachmentPreviewModal';
import VermerkData from './Vermerk/VermerkData';
import FachdatenNodeComponent from './Fachdaten/FachdatenNode';
import { caseDetailsDataContext } from '../../../../context/Context';
import { initialCaseDetailsData } from '../../../../context/CaseDetailsDataContext/CaseDetailsDataContext';

const Antragsdetails = () => {
  const caseDetails = useContext(caseDetailsDataContext);
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);

  const submissionData =
    caseDetails && caseDetails?.data?.caseId !== ''
      ? caseDetailsMapper(caseDetails?.data)
      : null;

  const fachdaten = submissionData?.fachdaten ?? new FachdatenNode(undefined);

  const attachmentRequestData = extractAttachmentRequestData(
    caseDetails?.data?.initialSubmission
  );

  if (!caseDetails || !caseDetails?.data?.initialSubmission) {
    return <p> Es sind keine Daten vorhanden.</p>;
  }

  let fachdatenView = submissionData ? (
    <FachdatenNodeComponent node={fachdaten} depthLevel={0} />
  ) : (
    <p> Es sind keine Daten vorhanden.</p>
  );

  return (
    <React.Fragment>
      <AttachmentPreviewModal
        onClose={() => setOpenAttachmentModal(false)}
        attachmentId={attachmentRequestData?.attachmentId}
        submissionId={attachmentRequestData?.submissionId}
        caseId={caseDetails?.data?.caseId}
        isOpen={openAttachmentModal && attachmentRequestData !== null}
      />
      {caseDetails !== undefined &&
      Object.entries(caseDetails.data).toString() !==
        Object.entries(initialCaseDetailsData).toString() ? (
        <div className={classes.horizontal}>
          <div className={classes.leftbox}>
            <div>
              <h2 style={{ marginBottom: '10px' }}>Antragsdaten</h2>
              <br></br>
              <div className={classes.formularansicht}>
                {attachmentRequestData && (
                  <FjdButton
                    appearance={'primary-link'}
                    label={'Formularansicht'}
                    iconLeft={'view'}
                    key={Math.random()}
                    onClick={() => setOpenAttachmentModal(true)}
                    id={'formularansicht_display'}
                  />
                )}
                {!attachmentRequestData && (
                  <div className={classes.noFormularAnsicht}></div>
                )}
              </div>
            </div>
            {fachdatenView}
          </div>
          <div className={classes.rightbox}>
            <div className={classes.sticky}>
              <div className={classes.scroll}>
                <FjdStack orientation="vertical">
                  <VermerkData
                    caseDetails={caseDetails.data}
                    onStateChanged={() => caseDetails.refresh()}
                  />
                  <Metadaten />
                  <Ausweisdaten />
                </FjdStack>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <FjdSpinner size="s" />
      )}
    </React.Fragment>
  );
};

export default Antragsdetails;
