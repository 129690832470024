import './AntragBadge.css';
import { FjdBadge } from 'fjd-react-components';

interface AntragBadgeProps {
  readonly 'data-testid'?: string;
}

export default function AntragBadge(props: AntragBadgeProps) {
  return (
    <FjdBadge
      data-testid={props['data-testid']}
      appearance={'outline'}
      label={'Antrag'}
      size="xs"
    />
  );
}
