import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';

import { RouteKey, routes } from '../utils/router';

function useIdByLocation() {
  const casePageMatch = useMatch(routes[RouteKey.SCHREIBTISCH_CASE]);

  const id = useMemo(() => {
    if (casePageMatch) {
      return casePageMatch.params.caseId;
    }

    return '';
  }, [casePageMatch]);

  return id;
}

export default useIdByLocation;
