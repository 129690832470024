import { FjdBaseLayout } from 'fjd-react-components';
import { Footer } from '../../shared/Footer/Footer';
import { Header } from '../../shared/Header/Header';
import Schreibtisch from '../Schreibtisch';
import { MainNavigation } from '../../shared/MainNavigation/MainNavigation';
import { SchreibtischTabbar } from '../SchreibtischTabbar/SchreibtischTabbar';

import CaseDetailsDataProvider from '../../../context/CaseDetailsDataContext/CaseDetailsDataContext';
import { useCaseCount } from '../../../hooks/useCaseCount';

export function SchreibtischLayout() {
  const { caseCount } = useCaseCount();
  return (
    <>
      <CaseDetailsDataProvider>
        <FjdBaseLayout
          footer={<Footer />}
          header={<Header />}
          mainNavigation={<MainNavigation />}
          secondaryNavigation={
            caseCount !== 0 ? <SchreibtischTabbar /> : undefined
          }
          fullWidthContent
          overflow={true}
        >
          <Schreibtisch />
        </FjdBaseLayout>
      </CaseDetailsDataProvider>
    </>
  );
}
