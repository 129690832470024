import { useCallback } from 'react';
import { useMatch } from 'react-router-dom';

import { RouteKey, routes } from '../utils/router';
import { CaseDetailsModel } from '../models/Case/CaseDetailsModel';
import useSWR, { useSWRConfig } from 'swr';
import { PathMatch } from 'react-router';

export function useCaseDetails(caseId?: string) {
  const casePageMatch: PathMatch | null = useMatch(
    routes[RouteKey.SCHREIBTISCH_CASE]
  );
  const elementUrl: string | null = caseId
    ? `/api/v1/cases/${caseId}`
    : casePageMatch
      ? `/api/v1/cases/${casePageMatch.params.caseId}`
      : null;

  const { data, error, isValidating } = useSWR<CaseDetailsModel>(elementUrl, {
    suspense: false
  });

  const { mutate } = useSWRConfig();

  const refreshCaseDetailsData = useCallback(() => {
    mutate(elementUrl, undefined, { revalidate: true });
  }, [mutate, elementUrl]);
  const paramCaseId = casePageMatch?.params?.caseId;

  return {
    data,
    paramCaseId,
    casePageMatch,
    error,
    refreshCaseDetailsData,
    isValidating
  };
}
